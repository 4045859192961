/* 피쳐 플래깅을 관리하는 파일입니다.
 * 각 기능별 마스킹 변수가 있으며, 기능이 추가될경우 가장 큰 기능의 마스킹 * 2를 해서 나타내면 됩니다.
 * 환경변수를 통해서 입력받은 Feature는 env2feature함수를 통해 기능을 가지게 됩니다.
 * 각 환경별 기능은 환경_FEATURE 변수에 or 연산을 통해 앞선 마스킹을 다 합쳐준것으로 관리가 됩니다.
 */

export const UNDEFINED_MODE = 0;
export const DEMO_MODE = 1;
export const VALIDATOR_MODE = 2;
export const VALIDATOR_READONLY_MODE = 3;

// 각 기능별 마스크
// 만약 추후 기능이 많아지면 16진수로 변경 할것 0x1 0x2 0x4 0x8 0x10 ...
export const MENU_ON_MASK: number = 0b1; // 1, 메뉴 활성화
export const PREV_NEXT_ON_MASK: number = 0b10; // 2, 이전 다음 버튼 활성화
export const FILE_UPLOAD_ON_MASK: number = 0b100; // 4, 파일 업로드 활성화
export const TERMINATE_ON_MASK: number = 0b1000; // 8, 종료 버튼 활성화
export const EDIT_VISIBLE_ON_MASK: number = 0b10000; // 16, 편집 가능
export const TABLE_ABLE_MASK: number = 0b100000; // 32, 테이블 활성화
export const ADD_VALUE_ABLE_MASK: number = 0b1000000; // 64, 값 추가 가능
export const DOWNLOAD_IMAGE_MASK: number = 0b10000000; // 128, 이미지 다운로드 가능
export const DOWNLOAD_JSON_MASK: number = 0b100000000; // 256, JSON 다운로드 가능
export const CONFIDENCE_ADJUST_ON_MASK: number = 0b1000000000; // 512, 신뢰도 조정 가능
export const ONPREMISE_MODE_MASK: number = 0b10000000000; // 1024, 온프레미스 모드

// 각 환경별 기능
export const DEMO_MODE_FEATURE: number =
  MENU_ON_MASK |
  PREV_NEXT_ON_MASK |
  FILE_UPLOAD_ON_MASK |
  TERMINATE_ON_MASK |
  CONFIDENCE_ADJUST_ON_MASK |
  DOWNLOAD_JSON_MASK;
export const ONPREMISE_DEMO_FEATURE: number =
  MENU_ON_MASK |
  PREV_NEXT_ON_MASK |
  FILE_UPLOAD_ON_MASK |
  TERMINATE_ON_MASK |
  CONFIDENCE_ADJUST_ON_MASK |
  DOWNLOAD_JSON_MASK |
  ONPREMISE_MODE_MASK;
export const VALIDATOR_MODE_FEATURE: number =
  EDIT_VISIBLE_ON_MASK |
  TABLE_ABLE_MASK |
  ADD_VALUE_ABLE_MASK |
  CONFIDENCE_ADJUST_ON_MASK |
  DOWNLOAD_IMAGE_MASK;
export const VALIDATOR_READONLY_MODE_FEATURE: number =
  TERMINATE_ON_MASK | DOWNLOAD_IMAGE_MASK | CONFIDENCE_ADJUST_ON_MASK;

//환경 to 기능 함수
const env2feature = (envvar: string | undefined) => {
  switch (envvar?.toUpperCase()) {
    case "DEMO":
      return DEMO_MODE_FEATURE;
    case "ONPREMISE_DEMO":
      return ONPREMISE_DEMO_FEATURE;
    case "VALIDATOR":
      return VALIDATOR_MODE_FEATURE;
    case "VALIDATOR_RO":
      return VALIDATOR_READONLY_MODE_FEATURE;
    default:
      return UNDEFINED_MODE;
  }
};

// 고객사 전용 feature flagging
// TODO: deprecated. 추후 삭제 예정
export const CUSTOMER_UNKNOWN = 0;
export const CUSTOMER_HMM = 1;
const env2customer = (envvar: string | undefined) => {
  switch (envvar?.toUpperCase()) {
    case "HMM":
      return CUSTOMER_HMM;
    default:
      return CUSTOMER_UNKNOWN;
  }
};

export const FEATURE = env2feature(process.env.REACT_APP_FEATURE_FLAG);
export const CUSTOMER = env2customer(process.env.REACT_APP_FEATURE_CUSTOMER);
