import type { SVGProps } from "react";

export function NoImage(props: SVGProps<SVGSVGElement>) {
  return (
    <svg width="42" height="42" viewBox="0 0 58 58" fill="none" opacity="0.5" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d="M2.90039 15.225C2.90039 11.6213 5.82173 8.69995 9.42539 8.69995H48.5754C52.179 8.69995 55.1004 11.6213 55.1004 15.225V42.7749C55.1004 46.3786 52.179 49.2999 48.5754 49.2999H9.42539C5.82173 49.2999 2.90039 46.3786 2.90039 42.7749V15.225ZM7.25039 32.0759V42.7749C7.25039 43.9762 8.22417 44.95 9.42539 44.95H48.5754C49.7766 44.95 50.7504 43.9762 50.7504 42.7749V34.9759L44.3133 28.5388C43.464 27.6894 42.0868 27.6894 41.2374 28.5388L35.7013 34.0749L37.0633 35.437C37.9127 36.2864 37.9127 37.6635 37.0633 38.5129C36.214 39.3623 34.8368 39.3623 33.9874 38.5129L18.9383 23.4638C18.089 22.6144 16.7118 22.6144 15.8624 23.4638L7.25039 32.0759ZM34.8004 20.3C34.8004 21.9016 33.502 23.2 31.9004 23.2C30.2988 23.2 29.0004 21.9016 29.0004 20.3C29.0004 18.6983 30.2988 17.4 31.9004 17.4C33.502 17.4 34.8004 18.6983 34.8004 20.3Z" fill="#9295A0" />
    </svg>

  );
}
