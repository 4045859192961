import { useEffect, useState } from "react";
import { Scrollbars } from "react-custom-scrollbars-2";
import DetailFooter from "../components/Header/DetailFooter";
import DetailHeader from "../components/Header/DetailHeader";
import RollbackToast from "../components/Toast/RollbackToast";
import { ChangedFileProvider } from "../hooks/useChangedFile";
import { useDemoResult } from "../hooks/useDemoResult";
import ParsingResultLA from "../molecules/ParsingResultLA";
import ParsingResultOCR from "../molecules/ParsingResultOCR";
import ParsingResultTable from "../molecules/ParsingResultTable";
import { ImageOacJson } from "../types/demo";
import { ImageExtractorOacJson } from "../types/extractorOacResponse";
import { Group, Line } from "../types/extractorResult";
import { ImageLAOacJson } from "../types/laOacResponse";
import { ImageOCROacJson } from "../types/ocrOacResponse";
import { classnames } from "../utils/classnames";
import { endpointStore } from "../utils/mobx/EndpointStore";
import { resultInteractionStore } from "../utils/mobx/ResultInteractionStore";

interface DemoDetailViewProps {
  resetValidator: () => void;
  infering?: boolean;
  file: ImageOacJson;
  workToolHandler: (workToolOnoff: boolean) => void;
  workToolOn: boolean;
  tenantId: string;
  modalOn: boolean;
  setInfering: (infering: boolean) => void;
  isLogin: boolean;
}

export function ValidatorDetailView({
  resetValidator,
  infering = false,
  file,
  workToolHandler,
  workToolOn,
  modalOn,
  tenantId,
  setInfering,
  isLogin,
}: DemoDetailViewProps): JSX.Element {
  const [dragging, setDragging] = useState(false);
  const [lineGroups, setLineGroups] = useState<Line[][]>([]);
  const [sortedGroups, setGroups] = useState<Group[]>([]);
  const { endpointType } = endpointStore;
  const { result, hover, select } = useDemoResult();
  const [isEmpty, setIsEmpty] = useState(false);

  const { setSelectedBoxIds, setFocusIndex: setIndex } = resultInteractionStore;

  useEffect(() => {
    if (endpointType === "extractor") {
      const extractorResult = file as ImageExtractorOacJson;
      if (extractorResult?.fields.length > 0) {
        setIsEmpty(true);
      }
    } else if (endpointType === "ocr") {
      const ocrResult = file as ImageOCROacJson;
      if (ocrResult?.text.length > 0) {
        setIsEmpty(true);
      }
    } else if (endpointType === "layout-analysis") {
      const laResult = file as ImageLAOacJson;
      if (laResult?.elements.length > 0) {
        setIsEmpty(true);
      }
    }
  }, [file]);

  useEffect(() => {
    const onkeydown = function (e: KeyboardEvent) {
      if (e.code === "Space") {
        setDragging(true);
      }
      if (e.code === "Escape") {
        setSelectedBoxIds([]);
        setIndex(-1);
      }
    };

    const onkeyup = function (e: KeyboardEvent) {
      if (e.code === "Space") {
        setDragging(false);
      }
    };

    window.addEventListener("keydown", onkeydown);
    window.addEventListener("keyup", onkeyup);

    return () => {
      window.removeEventListener("keydown", onkeydown);
      window.removeEventListener("keyup", onkeyup);
    };
  }, []);

  return (
    <>
      <div
        className={classnames({
          "flex-1 overflow-y-hidden flex flex-col bg-gray-25 items-center border-r-[1px] border-gray-100":
            true,
        })}
      >
        {/* 결과창 헤더 */}

        <ChangedFileProvider
          value={{
            groupChangedResult: sortedGroups,
            lineChangedResult: lineGroups,
            setGroupChangedResult: setGroups,
            setLineChangedResult: setLineGroups,
          }}
        >
          <div className="relative w-full">
            <DetailHeader
              workToolHandler={workToolHandler}
              workToolOn={workToolOn}
              tenantId={tenantId}
              setInfering={setInfering}
              infering={infering}
              modalOn={modalOn}
              isLogin={isLogin}
            />
          </div>

          {/* 결과창 표부분 */}

          {file && isEmpty ? (
            endpointType.toLowerCase() === "extractor" ? (
              <section className="flex-1 w-full h-full pl-4">
                <Scrollbars>
                  <ParsingResultTable disabled={dragging} />
                </Scrollbars>
              </section>
            ) : endpointType.toLowerCase() === "ocr" ? (
              <section className="flex-1 w-full h-full pl-4">
                <Scrollbars>
                  <ParsingResultOCR disabled={dragging} />
                </Scrollbars>
              </section>
            ) : endpointType.toLowerCase() === "layout-analysis" ? (
              <section className="flex-1 w-full h-full pl-4">
                <Scrollbars>
                  <ParsingResultLA disabled={dragging} />
                </Scrollbars>
              </section>
            ) : (
              <section className="flex-1 w-full h-full flex items-center justify-center">
                <p>Invalid Endpoint Type</p>
              </section>
            )
          ) : (
            <section className="flex-1 w-full h-full flex items-center justify-center text-[#6B7280] text-[14px]">
              <p>No text was read from the image</p>
            </section>
          )}

          {isEmpty && (
            <div className="relative w-full">
              <DetailFooter
                resetValidator={resetValidator}
                workToolHandler={workToolHandler}
                workToolOn={workToolOn}
                tenantId={tenantId}
                setInfering={setInfering}
                infering={infering}
                modalOn={modalOn}
              />
            </div>
          )}
        </ChangedFileProvider>
        <RollbackToast />
      </div>
    </>
  );
}
