import type { SVGProps } from "react";

export function Alert(props: SVGProps<SVGSVGElement>) {
  return (
    <svg width="44" height="44" viewBox="0 0 44 44" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M0 22C0 9.84974 9.84974 0 22 0C34.1503 0 44 9.84974 44 22C44 34.1503 34.1503 44 22 44C9.84974 44 0 34.1503 0 22Z" fill="#FEECED" />
      <path d="M22.0003 19.0001V22.7501M12.697 26.1257C11.8316 27.6257 12.9142 29.5001 14.6459 29.5001H29.3546C31.0863 29.5001 32.1689 27.6257 31.3035 26.1257L23.9492 13.3782C23.0833 11.8774 20.9172 11.8774 20.0513 13.3782L12.697 26.1257ZM22.0003 25.7501H22.0078V25.7576H22.0003V25.7501Z" stroke="#D7373F" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
}
