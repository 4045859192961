import { observer } from "mobx-react-lite";
import { useEffect, useRef, useState } from "react";
import { Icon, Icons } from "../components/Icon";
import { ConfidenceToolbar } from "../molecules/ConfidenceToolbar";
import { classnames } from "../utils/classnames";
import { parsingTableStore } from "../utils/mobx/ParsingTableStore";
interface ConfidenceProps {
  upperRef: React.RefObject<HTMLDivElement>;
  setQuestionHover: (q: boolean) => void;
}

/* ConfidenceScore의 기준을 바꿀수 있는 JSX를 반환하는 함수입니다.
 */
const ConfidenceSetting = ({ upperRef, setQuestionHover }: ConfidenceProps) => {
  const {
    confidenceThreshold,
    confidenceOn: on,
    setConfidenceOn: setOn,
  } = parsingTableStore;
  const ref = useRef<HTMLDivElement>(null);

  /* 영역 이외의 부분을 클릭할 시 비활성하를 시키는 useEffect입니다.
   * upperRef는 제외하는데 upperRef를 클릭시 비활성화를 되어있는것이 이미 되어있어 이부분을 제외안하면 작동을 안하기 때문입니다.
   */
  useEffect(() => {
    const listener = (e: MouseEvent) => {
      if (
        !ref.current ||
        !on ||
        ref.current.contains(e.target as Node) ||
        (upperRef.current && upperRef.current.contains(e.target as Node))
      )
        return;
      setOn(false);
      e.stopPropagation();
      e.preventDefault();
    };
    document.addEventListener("mousedown", listener);
    return () => {
      document.removeEventListener("mousedown", listener);
    };
  }, [on, ref, setOn]);

  /* confidenceThreshold 값을 내림해주는 함수.
   */
  useEffect(() => {
    confidenceTextSetter(Math.floor(confidenceThreshold * 100));
  }, [confidenceThreshold]);

  const [confidenceText, setConfidenceText] = useState<string>("Normal");
  const confidenceTextSetter = (confidence: number) => {
    switch (true) {
      case confidence >= 95:
        setConfidenceText("Very strict");
        break;
      case confidence >= 90:
        setConfidenceText("Strict");
        break;
      case confidence >= 60:
        setConfidenceText("Normal");
        break;
      case confidence >= 1:
        setConfidenceText("Lenient");
        break;
      default:
        setConfidenceText("N/A");
        break;
    }
  };

  return (
    <div
      className="px-4 pt-5 pb-4 overflow-hidden text-left transition-all transform bg-white border border-grey-200 rounded-lg drop-shadow-md sm:my-8 w-[304px] h-[168px] sm:max-w-lg sm:p-6"
      ref={ref}
    >
      <div className="flex flex-col items-center justify-between h-full">
        <div className="flex flex-row items-start justify-start w-full text-[14px] gap-2 mx-auto rounded-full --heading-sm ">
          <span>Confidence score</span>
          <span
            onMouseEnter={e => {
              setQuestionHover(true);
            }}
            onMouseLeave={e => {
              setQuestionHover(false);
            }}
          >
            <Icon source={Icons.QuestionMarkCircle} />
          </span>
        </div>
        <div className="flex flex-row gap-3 mt-3 text-center">
          <div className="w-[256px]">
            <ConfidenceToolbar />
          </div>
        </div>
        <div className="flex flex-row items-center justify-center w-full px-10 align-middle">
          <div className="flex w-[131px] h-9 py-4 text-center justify-center items-center rounded-lg gap-1 align-middle bg-grey-25 text-[#F7493C] --body-base">
            <span className="items-center w-[30px] text-center  --body-lm">
              {Math.floor(confidenceThreshold * 100)}
            </span>
            <svg
              width="2"
              height="24"
              viewBox="0 0 2 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <rect x="0.5" width="1" height="24" fill="#EDEDED" />
            </svg>

            <span
              className={classnames({
                "text-black items-center text-center w-20  --body-lm": true,
              })}
            >
              {confidenceText}
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default observer(ConfidenceSetting);
