import * as Toast from "@radix-ui/react-toast";
import { observer } from "mobx-react";
import { useEffect, useRef } from "react";
import { parsingTableStore } from "../../utils/mobx/ParsingTableStore";
import { resultToastStore } from "../../utils/mobx/ResultToastStore";
import { Icon, Icons } from "../Icon";

const RollbackToast = () => {
  const { undo } = parsingTableStore;
  const { message, toastOpen: open, setToastOpen: setOpen } = resultToastStore;
  const toastRef = useRef<HTMLLIElement>(null);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        toastRef.current &&
        !toastRef.current.contains(event.target as Node)
      ) {
        setOpen(false);
      }
    };
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.key) {
        setOpen(false);
      }
    };

    document.addEventListener("keydown", handleKeyDown);
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("keydown", handleKeyDown);
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [open, setOpen]);

  return (
    <Toast.Provider swipeDirection="right">
      <Toast.Root
        className="bg-white w-min h-[76px] rounded-lg shadow-[hsl(206_22%_7%_/_35%)_0px_10px_38px_-10px,_hsl(206_22%_7%_/_20%)_0px_10px_20px_-15px] p-6 flex gap-3 items-center data-[state=open]:animate-slideIn data-[state=closed]:animate-hide data-[swipe=move]:translate-x-[var(--radix-toast-swipe-move-x)] data-[swipe=cancel]:translate-x-0 data-[swipe=cancel]:transition-[transform_200ms_ease-out] data-[swipe=end]:animate-swipeOut"
        open={open}
        onOpenChange={setOpen}
        ref={toastRef}
        duration={10000}
      >
        <Toast.Description asChild>
          <span className="flex flex-row items-center gap-4 --body-sm">
            <Icon source={Icons.InformationCircle} />
            <span className="whitespace-nowrap">{message}</span>
          </span>
        </Toast.Description>
        <Toast.Action className="[grid-area:_action]" asChild altText="undo">
          <button
            className="inline-flex items-center justify-center px-2 py-1 border border-gray-100 rounded-lg --body-lm whitespace-nowrap"
            onClick={e => {
              undo();
              setOpen(false);
            }}
          >
            Undo
          </button>
        </Toast.Action>
      </Toast.Root>
      <Toast.Viewport className="[--viewport-padding:_25px] fixed bottom-0 left-0 flex flex-col p-[var(--viewport-padding)] gap-[10px] w-[390px] max-w-[100vw] m-0 list-none z-[2147483647] outline-none" />
    </Toast.Provider>
  );
};

export default observer(RollbackToast);
