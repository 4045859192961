// python string의 strip메소드와 비슷한 역할을 하는 함수.
export const Strip = (word: string, characters: string = " ") => {
  return RStrip(LStrip(word, characters), characters);
};

// python string의 lstrip메소드와 비슷한 역할을 하는 함수.
export const LStrip = (word: string, characters: string = " ") => {
  let newWord = word;
  while (newWord.length > characters.length) {
    if (newWord.slice(0, characters.length) === characters) {
      newWord = newWord.slice(characters.length, newWord.length);
    } else {
      break;
    }
  }
  return newWord;
};

// python string의 rstrip메소드와 비슷한 역할을 하는 함수.
export const RStrip = (word: string, characters: string = " ") => {
  let newWord = word;
  while (newWord.length > characters.length) {
    if (
      newWord.slice(newWord.length - characters.length, newWord.length) ===
      characters
    ) {
      newWord = newWord.slice(0, newWord.length - characters.length);
    } else {
      break;
    }
  }
  return newWord;
};

export const ModifyModelName = (
  modelName: string,
  modelStatus: string | undefined,
) => {
  let newName = modelName;
  if (modelStatus === "private") return newName;
  newName = newName.replace(/-extraction-\d+\.\d+\.\d+$/, "");
  newName = newName.replace("kr-", "");

  newName = newName.replace(/-/g, " ");
  newName = newName.replace(/\b\w/g, char => char.toUpperCase());

  return newName;
};

export const Capitalize = (word: string | undefined) => {
  if (word === undefined) return word;
  let newWord = word.charAt(0).toUpperCase() + word.slice(1);
  return newWord;
};
