import { Outlet } from "react-router-dom";
export function Layout() {
  return (
    <div className="w-screen h-screen">
      <div className="flex h-full">
        <div className="flex flex-col w-full overflow-y-scroll --bg-neutral">
          <Outlet />
        </div>
      </div>
    </div>
  );
}
