/* eslint-disable react/button-has-type */
import type { FC, ReactElement } from "react";
import React from "react";
import { classnames } from "../../utils/classnames";
import type { ButtonProps } from "./Button.types";
import { ButtonSize, ButtonStyle } from "./Button.types";

export const Button: FC<ButtonProps> = ({
  text = "",
  disabled = false,
  type = "button",
  size = ButtonSize.M,
  style = ButtonStyle.Primary,
  leftContent,
  rightContent,
  name,
  value,
  onClick = () => { },
  widthFull = false,
  className = "",
  leftBorderType = "rounded",
  rightBorderType = "rounded",


}): ReactElement => {
  const handleClick = (event: React.MouseEvent) => !disabled && onClick(event);

  return (
    <button
      disabled={disabled}
      type={type}
      className={classnames({
        "select-none flex justify-center items-center space-x-2 border-gray-100 bg-white --body-base":
          true,
        "w-full": widthFull,
        "rounded-r-lg": rightBorderType === "rounded",
        "rounded-l-lg": leftBorderType === "rounded",
        [getCSSPadding(text)]: true,
        [getCSSFromSize(size)]: true,
        [getCSSFromStyle(style)]: true,
        [className]: true,
      })}
      onClick={handleClick}
      name={name}
      value={value}
    >
      {leftContent}

      {text && <span>{text}</span>}

      {rightContent}
    </button>
  );
};

function getCSSPadding(text: React.ReactNode) {
  return text ? `px-2 py-2` : `py-2`;
}

function getCSSFromSize(size: ButtonSize) {
  switch (size) {
    case ButtonSize.L: {
      return `h-12 min-w-12`;
    }
    case ButtonSize.M: {
      return `h-9 min-w-9`;
    }
    case ButtonSize.S: {
      return `h-8 min-w-8`;
    }
    case ButtonSize.XS: {
      return `h-6 min-w-6`;
    }
    default: {
      return "";
    }
  }
}

function getCSSFromStyle(style: ButtonStyle) {
  switch (style) {
    case ButtonStyle.Primary: {
      return `
        bg-indigo-700 text-white
        hover:bg-indigo-800
        active:bg-indigo-800 active:ring-0 active:ring-offset-0
        focus:bg-indigo-700 focus:ring-1 focus:ring-white focus:ring-offset-2 focus:ring-offset-indigo-700 focus:ring-inset
        disabled:bg-gray-100 disabled:text-gray-300
      `;
    }
    case ButtonStyle.Secondary: {
      return `
        text-gray-800 border border-gray-100 bg-black/5
        hover:bg-gray-50 hover:text-gray-800
        active:bg-gray-100 active:text-gary-800 active:ring-0 active:ring-offset-0
        focus:bg-transparent focus:text-gray-800 focus:ring-2 focus:ring-blue-400 focus:ring-offset-1 focus:ring-inset
        text-gray-800 border border-gray-100 bg-black/5
        hover:bg-gray-50 hover:text-gray-800
        active:bg-gray-100 active:text-gary-800 active:ring-0 active:ring-offset-0
        focus:bg-transparent focus:text-gray-800 focus:ring-2 focus:ring-blue-400 focus:ring-offset-1 focus:ring-inset
        disabled:bg-gray-100 disabled:text-gray-300 disabled:border-gray-100
      `;
    }
    case ButtonStyle.Tertiary: {
      return `
        bg-transparent text-gray-700 
        hover:bg-gray-100
        active:bg-blue-400 active:text-white active:ring-0 active:ring-offset-0 active:border-blue-400
        focus:bg-transparent focus:ring-2 focus:ring-blue-400 focus:ring-offset-1 focus:ring-inset
        bg-transparent text-gray-700 
        hover:bg-gray-100
        active:bg-blue-400 active:text-white active:ring-0 active:ring-offset-0 active:border-blue-400
        focus:bg-transparent focus:ring-2 focus:ring-blue-400 focus:ring-offset-1 focus:ring-inset
        disabled:bg-transparent disabled:text-gray-200 disabled:border-gray-200
      `;
    }
    case ButtonStyle.Danger: {
      return `
        bg-red-500 text-white
        hover:bg-red-600
        active:bg-red-600 active:ring-0 active:ring-offset-0
        focus:bg-red-500 focus:ring-1 focus:ring-white focus:ring-offset-2 focus:ring-offset-red-500 focus:ring-inset
        disabled:bg-gray-100 disabled:text-gray-300
      `;
    }
    case ButtonStyle.Ghost: {
      return `
        bg-transparent text-gray-700 border border-transparent
        hover:bg-gray-50
        active:bg-gray-100 active:ring-0 active:border-transparent
        focus:bg-transparent focus:ring-0 focus:ring-gray-300 focus:border-gray-300
        disabled:bg-transparent disabled:text-gray-300
      `;
    }
    case ButtonStyle.GhostSecondary: {
      return `
        bg-transparent text-gray-500 border border-transparent
        hover:bg-gray-50
        active:bg-gray-100 active:ring-0 active:border-transparent
        focus:bg-transparent focus:ring-0 focus:ring-gray-100 focus:border-gray-300
        disabled:bg-transparent disabled:text-gray-200
      `;
    }
    case ButtonStyle.GhostDanger: {
      return `
        bg-transparent text-red-500 border border-transparent
        hover:bg-gray-50
        active:bg-gray-100 active:ring-0 active:border-transparent
        focus:bg-transparent focus:ring-0 focus:ring-red-700 focus:border-gray-300
        disabled:bg-transparent disabled:text-red-300
      `;
    }
    case ButtonStyle.Dashed: {
      return `
        bg-transparent text-gray-400 border border-dashed border-gray-200
        hover:bg-gray-25
        active:bg-gray-50 active:ring-0
        focus:ring-0 focus:bg-transparent
        disabled:bg-transparent disabled:text-gray-200
      `;
    }
    case ButtonStyle.Basic: {
      return `
        bg-white text-gray-700 border border-gray-100
        hover:bg-gray-50
        active:bg-gray-50
        disabled:bg-gray-50 disabled:text-gray-300
      `;
    }
    case ButtonStyle.BasicDark: {
      return `
        bg-black/90 text-gray-50 border border-gray-500
        hover:bg-gray-600 hover:border-gray-500
        active:bg-gray-600 active:border-gray-500
        disabled:bg-gray-700 disabled:text-gray-500
      `;
    }
    case ButtonStyle.Trivial: {
      return `
        bg-transparent text-gray-500
        hover:bg-gray-50
        active:bg-gray-50
        disabled:bg-gray-50 disabled:text-gray-300
      `;
    }
    default: {
      return ``;
    }
  }
}
