import { Outlet } from "react-router-dom";
import ErrorBoundary from "./ErrorBoundary";
import "./styles/tailwind.input.css";

function App() {
  return (
    // html, head, meta, link, body 태그는 index.html에 있음
    <div className="flex flex-col w-screen h-screen overflow-hidden">
      <ErrorBoundary>
        <main className="font-html">
          <Outlet />
        </main>
      </ErrorBoundary>
    </div>
  );
}

export default App;
