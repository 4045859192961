import { useContext, useEffect, useRef, useState } from "react";
import ReactModal from "react-modal";
import { DocumentArrowUp } from "../../components/Icon/generated/DocumentArrowUp";
import {
  ValidatorFileContext,
  ValidatorModelsContext,
} from "../../hooks/useFile";
import { fileUpload, validateFile } from "../../hooks/useImageUploader";
import { classnames } from "../../utils/classnames";
import { Capitalize, ModifyModelName } from "../../utils/stringUtil";
import { Button, ButtonStyle } from "../Button";
import { Icon, Icons } from "../Icon";
import ErrorModal from "./ErrorModal";

interface ModalComponent {
  isOpen: boolean;
  setIsOpen: (open: boolean) => void;
  tenantId: string;
}

const customModalStyles: ReactModal.Styles = {
  overlay: {
    backgroundColor: " rgba(0, 0, 0, 0.8)",
    width: "100%",
    height: "100vh",
    zIndex: "10",
    position: "fixed",
    top: "0",
    left: "0",
  },
  content: {
    width: "auto",
    minWidth: "866px",
    maxWidth: "967px",
    height: "auto",
    minHeight: "500px",
    maxHeight: "680px",
    zIndex: "150",
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    borderRadius: "8px",
    boxShadow: "2px 2px 2px rgba(0, 0, 0, 0.25)",
    backgroundColor: "white",
    justifyContent: "center",
    overflow: "auto",
  },
};

export default function DemoSelectModal({
  isOpen,
  setIsOpen,
  tenantId,
}: ModalComponent) {
  const [checked, setChecked] = useState<number>(0);
  const { models, selected, setSelected } = useContext(ValidatorModelsContext);
  const {
    files,
    setSelected: setFileSelected,
    setFiles,
    setImageFiles,
    setInfering,
  } = useContext(ValidatorFileContext);
  const [errorOn, setErrorOn] = useState<boolean>(false);
  const imageUploadFileRef = useRef<HTMLInputElement | null>(null);
  const ref = useRef<HTMLDivElement>(null);
  const [isDropping, setIsDroppinrg] = useState<boolean>(false);
  const [errorType, setErrorType] = useState<number>(1);
  const [modelIdx, setModelIndex] = useState<number>(0);
  const [modalHeight, setModalHeight] = useState(0); // 초기 모달 높이
  const [closeFunction, setCloseFunction] = useState<() => void>(() => {});
  const [reloadFunction, setReloadFunction] = useState<() => void>(() => {});
  const [hoveredModel, setHoveredModel] = useState<number | null>(null);

  useEffect(() => {
    // 윈도우 창 크기가 변경될 때마다 모달 높이를 업데이트
    const handleResize = () => {
      const height = document.getElementById("React-Modal")?.clientHeight;
      const contentHeight = 265 + (height ? height : 500) - 500;
      setModalHeight(contentHeight);
    };
    handleResize(); // 처음 렌더링 시 모달 높이를 설정
    window.addEventListener("resize", handleResize);
    window.addEventListener("mouseover", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
      window.removeEventListener("mouseover", handleResize);
    };
  }, []);

  useEffect(() => {
    setModelIndex(selected);
    setChecked(selected);
  }, [isOpen, selected]);

  // 드래그 중인 아이템이 영역 위에 있을 때 실행되는 함수
  const onDragOver = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    setIsDroppinrg(true);
  };

  // 드래그 영역 이탈시
  const onDragLeave = (e: React.DragEvent<HTMLDivElement>) => {
    setIsDroppinrg(false);
  };

  // drop시 실행되는 함수

  const handleDrop = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    const file = event.dataTransfer.files[0];
    setIsDroppinrg(false);
    // 파일 유효성 검즘.
    if (!validateFile(file, setErrorType)) {
      setErrorOn(true);
      event.dataTransfer.clearData();
      setCloseFunction(() => () => {
        document.getElementById("file-upload")?.click();
        setErrorOn(false);
      });
      setReloadFunction(() => () => {
        setErrorOn(false);
      });

      return;
    }

    fileUpload(
      [event.dataTransfer.files[0]],
      setErrorType,
      () => {},
      tenantId,
      setIsOpen,
      setErrorOn,
      setInfering,
      setImageFiles,
      models,
      modelIdx,
      files,
      setFileSelected,
      setFiles,
      setCloseFunction,
      setReloadFunction,
      errorType,
      null as unknown as React.ChangeEvent<HTMLInputElement>,
      false,
    );
  };

  useEffect(() => {
    const listener = (e: MouseEvent) => {
      if (errorOn || !ref.current || ref.current.contains(e.target as Node))
        return;
      setIsOpen(false);
    };
    document.addEventListener("mousedown", listener);
    return () => {
      document.removeEventListener("mousedown", listener);
    };
  }, [ref, errorOn, setIsOpen]);
  return (
    <>
      <ErrorModal
        open={errorOn}
        setOpen={setErrorOn}
        errorType={errorType}
        closeFunction={closeFunction}
        reloadFunction={reloadFunction}
      />
      <ReactModal
        isOpen={isOpen}
        style={customModalStyles}
        id="React-Modal"
        ariaHideApp={false}
      >
        <div className="z-10 flex flex-col justify-between" ref={ref}>
          <div className="flex flex-col justify-between">
            <div className="p-3">
              <div className="text-gray-700 --heading-lg">Upload file</div>
              <div className="mt-1 text-gray-700 --body-sm">
                Select a model and upload a new file.
              </div>
            </div>
            <div className="flex flex-col mt-2">
              <div className="flex justify-start h-full gap-5 m-3">
                <div className="flex-col justify-start flex-1 w-1/2">
                  <div className="--body-lm leading-[22px] text-gray-700">
                    Model
                  </div>
                  <div
                    className={`flex p-4 mt-2 border overflow-y-scroll border-gray-100 rounded-lg bg-gray-25`}
                    style={{ height: modalHeight }}
                  >
                    <fieldset className="w-full">
                      <div>
                        <div className="w-[350px] mx-2 mt-2 divide-y divide-gray-100">
                          {models.map((model, idx) => (
                            <div
                              onClick={() => {
                                setModelIndex(idx);
                                setChecked(idx);
                              }}
                              onMouseOver={() => {
                                setHoveredModel(idx);
                              }}
                              onMouseOut={() => {
                                setHoveredModel(null);
                              }}
                              className={classnames({
                                "text-gray-500 --body-sm": idx !== checked,
                                "text-gray-900 --heading-ss": idx === checked,
                              })}
                              key={idx}
                            >
                              <div
                                key={idx}
                                className="relative flex items-center w-full cursor-pointer"
                              >
                                <div className="flex-1 w-full min-w-0 leading-[18px] py-1">
                                  <label
                                    htmlFor={`model-${idx}`}
                                    className="select-none leading-7 cursor-pointer"
                                  >
                                    {ModifyModelName(model.name, model.status)}
                                  </label>
                                  {model.status?.toLowerCase() != "public" ? (
                                    <span
                                      className={classnames({
                                        "inline-flex ml-3 items-center text-[12px] font-normal":
                                          true,
                                        "text-[#1D4ED8] ":
                                          model.status?.toLowerCase() ===
                                          "beta",
                                        "text-[#22C55E] ":
                                          model.status?.toLowerCase() != "beta",
                                      })}
                                    >
                                      {Capitalize(model.status)}
                                    </span>
                                  ) : (
                                    <></>
                                  )}
                                </div>

                                {
                                  <div
                                    className={`flex items-center h-6 my-1 ${checked === idx ? "" : "invisible"}`}
                                  >
                                    <Icon source={Icons.Check} />
                                  </div>
                                }
                              </div>
                            </div>
                          ))}
                        </div>
                      </div>
                    </fieldset>
                  </div>
                </div>
                <div className="flex-col flex-1 w-1/2 h-full">
                  <div className="leading-5 text-gray-700 --body-lm">File</div>
                  <div
                    className={classnames({
                      "flex flex-col justify-center px-6 mt-2 border border-dashed rounded-lg cursor-pointer":
                        true,
                      "border-gray-900/20": !isDropping,
                      "border-blue-400 ring ring-blue-50 bg-gray-50":
                        isDropping,
                    })}
                    onDragOver={onDragOver}
                    onDragLeave={onDragLeave}
                    onDrop={handleDrop}
                    onClick={() => {
                      imageUploadFileRef.current?.click();
                    }}
                    onMouseEnter={onDragOver}
                    onMouseLeave={onDragLeave}
                    style={{ height: modalHeight }}
                  >
                    <input
                      id="file-upload"
                      name="image"
                      type="file"
                      accept=".jpg,.jpeg,.png,.bmp,.pdf,.tif,.tiff,.heic,.heif"
                      onChange={async (
                        e: React.ChangeEvent<HTMLInputElement>,
                      ) => {
                        const _file = e.target.files;
                        const t = async () =>
                          await fileUpload(
                            [_file![0]],
                            setErrorType,
                            () => {},
                            tenantId,
                            setIsOpen,
                            setErrorOn,
                            setInfering,
                            setImageFiles,
                            models,
                            modelIdx,
                            files,
                            setFileSelected,
                            setFiles,
                            setCloseFunction,
                            setReloadFunction,
                            errorType,
                            e,
                            false,
                          );
                        t()
                          .then(() => {
                            setSelected(modelIdx);
                          })
                          .catch(() => {});
                      }}
                      ref={imageUploadFileRef}
                      style={{ display: "none" }}
                    />
                    <div className="flex flex-col items-center justify-center text-center">
                      <DocumentArrowUp
                        className="w-full mx-auto"
                        aria-hidden="true"
                      />
                      <div className="justify-center mt-4 --body-sm leading-[22px] text-gray-600">
                        <label>
                          <span className="font-medium text-blue-400 rounded-md">
                            Select a file directly
                          </span>

                          <span className="text-gray-700 --body-sm">
                            &nbsp;or drop it here
                          </span>
                        </label>
                      </div>
                      <p className="leading-5 text-gray-600 --body-xs">
                        JPEG, PNG, BMP, PDF, TIFF, HEIC
                      </p>
                      <p className="leading-5 text-gray-600 --body-xs">
                        Up to 50MB
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="flex justify-end flex-1 mx-2 mt-5 mb-1 cursor-pointer">
            <Button
              onClick={() => {
                setIsOpen(false);
              }}
              text="Cancel"
              style={ButtonStyle.Basic}
              className="--body-base"
            />
          </div>
        </div>
      </ReactModal>
    </>
  );
}
