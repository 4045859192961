import _ from "lodash";
import { action, computed, makeObservable, observable } from "mobx";
import { WARNING_CONFIDENCE_THRESHOLD } from "../../constants/threshold";
import * as extractorOacResponse from "../../types/extractorOacResponse";
import {
  Group,
  Line,
  parsingResultTableVariable,
} from "../../types/extractorResult";
import { Ontology } from "../../types/ontology";

let dummyId = -1;
const dummyProperty = {
  key: "",
  value: "",
  refinedValue: "",
  type: "content",
  confidence: 0,
  boundingBoxes: [],
};

enum HistoryOrder {
  //na toggle
  nt,
  //add Line
  al,
  //delete Line
  dl,
  //add Group
  ag,
  //delete Group
  dg,
  //change Input Value
  cv,
}

// 토글 전환시 저장 되어야아할 NA가 On되어 있는 상태에서 na인 친구들을 저장하는 자료구조입니다.
interface ToggleHistoryData {
  lineGroupsIndexes: number[];
  groupIndexes: number[];
  visiblerKeys: string[];
}

/*
 history 스택에 저장되는 인터페이스이다. 
*/
interface History {
  // 수행한 명령이다. Na 토글링시 "nt", 라인 추가시 "al", 라인 삭제시 "dl", 그룹 추가시 "ag", 그룹 삭제시 "dg", 값 변경시 "Cv"
  order: HistoryOrder;
  // 데이터 넣을 위치
  idx: number;
  // 삭제시 기존에 있던 데이터를 넣는곳 .
  data: Line | Group | null | string | ToggleHistoryData;
  // 오류 수정을 위한 오리지널 밸류를 넣는 곳.
  orgValue: string | string[];
}

class PasringTableStore {
  parsingTableVariableList: parsingResultTableVariable[] = [];

  lineKeys: String[] = [];
  lineGroups: Line[] = [];
  originalLineGroups: Line[] = [];
  filteredLineGroups: Line[] = [];
  tdRef: React.MutableRefObject<HTMLTableCellElement[]> | null = null;
  inputRefs: React.MutableRefObject<
    (HTMLInputElement | HTMLTextAreaElement)[]
  > | null = null;
  groupKeys: string[] = [];
  sortedGroups: Group[] = [];
  filteredSortedGroups: Group[] = [];
  orgSortedGroups: Group[] = [];
  values: string[] = [];
  orgValues: string[] = [];
  filteredIndex2orgIndex: number[] = [];
  // 새롭게 추가되는 친구들의 ID값 을 부여해주는 기준이 되는 변수. 우측 비박스와 마음 편하게 안겹치게 음수로 점점 작아지게 구현. (겹치면 클릭시 이동되는 연산 및 화면에 표시되는 정보 이상해짐)
  lastId: number = -100;

  largestId: number = 0;
  orgLargestId: number = 0;

  // undo에 필요한 History가 저장되는 변수이다.
  historyStack: History[] = [];
  // redo에 필요한 History가 저장되는 변수이다.
  redoStack: History[] = [];
  toggleOn: boolean = false;
  confidenceThreshold: number = WARNING_CONFIDENCE_THRESHOLD;
  confidenceOn: boolean = false;
  lineModalOn: boolean = false;
  toastOpen: boolean = false;
  visibleGroupKeys: string[] = [];
  ontologies: Ontology[] = [];
  constructor() {
    makeObservable(this, {
      parsingTableVariableList: observable,
      tdRef: observable,
      inputRefs: observable,
      lineKeys: observable,
      lineGroups: observable,
      filteredLineGroups: observable,
      filteredSortedGroups: observable,
      originalLineGroups: observable,
      groupKeys: observable,
      sortedGroups: observable,
      orgSortedGroups: observable,
      values: observable,
      orgValues: observable,
      filteredIndex2orgIndex: observable,
      confidenceThreshold: observable,
      confidenceOn: observable,
      toastOpen: observable,
      lineModalOn: observable,
      visibleGroupKeys: observable,
      toggleOn: observable,
      ontologies: observable,
      orgLargestId: observable,
      setontologies: action,
      setToastOpen: action,
      setLineModalOn: action,
      changeExtractorFile: action,
      updateGroupValue: action,
      updateLineValue: action,
      updateValue: action,
      getChanged: computed,
      setTdRef: action,
      setInputRef: action,
      naController: action,
      inputValueUpdate: action,
      addLineGroups: action,
      delLineGroups: action,
      delSortedGroups: action,
      addSortedGroups: action,
      redo: action,
      undo: action,
      setConfidenceThreshold: action,
      addLineByKey: action,
    });
    this.changeExtractorFile = this.changeExtractorFile.bind(this);
    this.updateGroupValue = this.updateGroupValue.bind(this); // <- Add this
    this.updateLineValue = this.updateLineValue.bind(this); // <- Add this
    this.updateValue = this.updateValue.bind(this); // <- Add this
    this.isChanged = this.isChanged.bind(this); // <- Add this
    this.setTdRef = this.setTdRef.bind(this);
    this.setInputRef = this.setInputRef.bind(this);
    this.naController = this.naController.bind(this);
    this.tdClick = this.tdClick.bind(this);
    this.tdFocus = this.tdFocus.bind(this);
    this.inputClick = this.inputClick.bind(this);
    this.inputFocus = this.inputFocus.bind(this);
    this.inputValueUpdate = this.inputValueUpdate.bind(this);
    this.addLineGroups = this.addLineGroups.bind(this);
    this.delLineGroups = this.delLineGroups.bind(this);
    this.delSortedGroups = this.delSortedGroups.bind(this);
    this.addSortedGroups = this.addSortedGroups.bind(this);
    this.undo = this.undo.bind(this);
    this.redo = this.redo.bind(this);
    this.setConfidenceThreshold = this.setConfidenceThreshold.bind(this);
    this.setConfidenceOn = this.setConfidenceOn.bind(this);
    this.addLineByKey = this.addLineByKey.bind(this);
    this.setToastOpen = this.setToastOpen.bind(this);
    this.setLineModalOn = this.setLineModalOn.bind(this);
    this.setontologies = this.setontologies.bind(this);
  }
  setontologies(ontologies: Ontology[]) {
    this.ontologies = ontologies;
  }

  setLineModalOn(on: boolean) {
    this.lineModalOn = on;
  }
  setToastOpen(open: boolean) {
    this.toastOpen = open;
  }
  addLineByKey(key: string) {
    let idx = -1;
    let flag = false;
    let boxId = 1;
    for (let i = 0; i < this.filteredLineGroups.length; i++) {
      if (this.filteredLineGroups[i].key === key) {
        idx = i + 1;
        flag = true;
      } else if (flag) {
        break;
      }
    }
    let flag1 = true;
    if (idx === -1) {
      for (let i = 0; i < this.lineGroups.length; i++) {
        if (this.filteredIndex2orgIndex.includes(i)) {
          idx += 1;
        }
        if (this.lineGroups[i].key === key) {
          idx += 1;
          flag1 = false;
          this.addLineGroups(
            idx,
            {
              id: this.lineGroups[i].id,
              confidence: null,
              key: this.lineGroups[i].key,
              type: this.lineGroups[i].type,
              value: "",
              refinedValue: "",
              boundingBoxes: [],
            },
            "",
            true,
          );
          boxId = this.lineGroups[i].id;
          break;
        }
      }
      if (flag1) {
        this.addLineGroups(
          ++idx,
          {
            id: ++this.largestId,
            confidence: null,
            key: key,
            type: "content",
            value: "",
            refinedValue: "",
            boundingBoxes: [],
          },
          "",
          true,
        );
        boxId = this.largestId;
      }
    } else {
      this.addLineGroups(
        idx,
        {
          id: this.filteredLineGroups[idx - 1].id,
          confidence: null,
          key: this.filteredLineGroups[idx - 1].key,
          type: this.filteredLineGroups[idx - 1].type,
          value: "",
          refinedValue: "",
        },
        "",
        true,
      );
      boxId = this.lineGroups[idx - 1].id;
    }
    return [idx, boxId];
  }

  setConfidenceOn(on: boolean) {
    this.confidenceOn = on;
  }

  setConfidenceThreshold(confidenceThreshold: number) {
    this.confidenceThreshold = confidenceThreshold;
  }

  undo() {
    if (this.historyStack.length > 0) {
      const history = this.historyStack.pop()!;
      switch (history.order) {
        case HistoryOrder.nt: {
          this.naController(history.data as ToggleHistoryData, false);
          break;
        }
        case HistoryOrder.ag: {
          this.delSortedGroups(history.idx, false);
          break;
        }
        case HistoryOrder.dg: {
          this.addSortedGroups(
            history.idx,
            history.data as Group,
            history.orgValue as string[],
            false,
          );
          break;
        }
        case HistoryOrder.cv: {
          this.inputValueUpdate(history.idx, history.data as string, false);
          break;
        }
        case HistoryOrder.al: {
          this.delLineGroups(history.idx, false);
          break;
        }
        case HistoryOrder.dl: {
          this.addLineGroups(
            history.idx,
            history.data as Line,
            history.orgValue as string,
            false,
          );
          break;
        }
      }
    }
  }

  // redo는 undo를 historystack과 Redostack을 바꿔서 Undo 연산을 하는것과 같다.
  redo() {
    const t = this.redoStack;
    this.redoStack = this.historyStack;
    this.historyStack = t;
    this.undo();
    const t2 = this.redoStack;
    this.redoStack = this.historyStack;
    this.historyStack = t2;
  }

  /* 기존의 토글 껏다 켰다 연산을 history를 단순 명령어로 똑같이 재현하기 위해서는
  toggleOn이 켜져 있음에도(Na가 지워진 상태) 새롭게 추가되거나 수정등의 이유로 na인 상태를 기록하는 것이 필요하다. 
  해당 변수들이 기록되고, 기억된 채로 넘어오는 변수가 toggleHistoryData이다. 
  record가 off인 상태는 undo, redo연산중인 상태이므로 저장을 반대 STACK에다가 한다. ㅜ
  */
  naController(
    toggleHistoryData: ToggleHistoryData = {
      groupIndexes: [],
      lineGroupsIndexes: [],
      visiblerKeys: [],
    },
    record: boolean = true,
  ) {
    const naOn = !this.toggleOn;
    const filteredLineGroups: Line[] = [];
    const filteredSortedGroups: Group[] = [];
    const filteredIndex2orgIndex: number[] = [];
    if (naOn) {
      // Na를 지워야하는 상태입니다. 값이 빈 값들을 제외하고 FIltered변수에 넣어줍니다. 단, toggleHistoryData에 들어있는 값은 제외합니다.
      this.lineGroups.forEach((lineGroup, idx) => {
        if (
          this.values[idx] ||
          toggleHistoryData.lineGroupsIndexes.includes(idx)
        ) {
          filteredLineGroups.push(lineGroup);
          filteredIndex2orgIndex.push(idx);
        }
      });
      //grouping 영역의 경우 column이 na로 제외 될 수 있다. 제외되는 컬럼을 미리 찾자.
      const invisibleColKeys = this.groupKeys.filter(
        (key, idx) =>
          !toggleHistoryData.visiblerKeys.includes(key) &&
          this.sortedGroups.filter(
            (group, groupIdx) =>
              this.values[
                this.lineGroups.length + this.groupKeys.length * groupIdx + idx
              ] === "",
          ).length === this.sortedGroups.length,
      );
      this.visibleGroupKeys = this.groupKeys.filter(
        key => !invisibleColKeys.includes(key),
      );
      this.sortedGroups.forEach((group, groupIdx) => {
        if (
          group.properties.filter(
            (property, idx) =>
              this.values[
                this.lineGroups.length + this.groupKeys.length * groupIdx + idx
              ],
          ).length > 0 ||
          toggleHistoryData.groupIndexes.includes(groupIdx)
        ) {
          filteredSortedGroups.push(group);
          group.properties.forEach((property, idx) => {
            if (!invisibleColKeys.includes(property.key)) {
              filteredIndex2orgIndex.push(
                this.lineGroups.length + this.groupKeys.length * groupIdx + idx,
              );
            }
          });
        }
      });

      toggleHistoryData.groupIndexes = [];
      toggleHistoryData.lineGroupsIndexes = [];
      toggleHistoryData.visiblerKeys = [];
    } else {
      // 반대로 전부 FIltered변수에 넣어줍니다. 단, 표시되고 있으면서 toggleHistoryData에 현재 NA인 값들은 넣어줍니다.
      // Grouping 영역에서 현재 표시되고 있으면서 Na인 친구들은 별도로 저장해줍니다.
      const invisibleColKeys = this.groupKeys.filter(
        (key, idx) =>
          this.sortedGroups.filter(
            (group, groupIdx) =>
              this.values[
                this.lineGroups.length + this.groupKeys.length * groupIdx + idx
              ] === "",
          ).length === this.sortedGroups.length,
      );
      toggleHistoryData.visiblerKeys = this.groupKeys.filter(
        key =>
          invisibleColKeys.includes(key) && this.visibleGroupKeys.includes(key),
      );
      this.visibleGroupKeys = this.groupKeys;
      this.lineGroups.forEach((lineGroup, idx) => {
        if (!this.values[idx] && this.filteredIndex2orgIndex.includes(idx)) {
          toggleHistoryData.lineGroupsIndexes.push(idx);
        }
        filteredLineGroups.push(lineGroup);
        filteredIndex2orgIndex.push(idx);
      });
      this.sortedGroups.forEach((group, groupIdx) => {
        if (
          group.properties.filter(
            (property, idx) =>
              this.values[
                this.lineGroups.length + this.groupKeys.length * groupIdx + idx
              ],
          ).length === 0 &&
          filteredIndex2orgIndex.includes(
            this.lineGroups.length + this.groupKeys.length * groupIdx,
          )
        ) {
          toggleHistoryData.groupIndexes.push(groupIdx);
        }
        filteredSortedGroups.push(group);
        group.properties.forEach((proeprty, idx) => {
          filteredIndex2orgIndex.push(
            this.lineGroups.length + this.groupKeys.length * groupIdx + idx,
          );
        });
      });
    }
    let stack = this.historyStack;

    //naController에 Data, OrgVal에는 na까 꺼질때 na로 있던 친구들을 넣어준다. 이친구들은 undo할때 살려준다.
    if (record) {
      this.redoStack = [];
    } else {
      stack = this.redoStack;
    }

    stack.push({
      idx: -1,
      data: toggleHistoryData,
      order: HistoryOrder.nt,
      orgValue: "",
    });
    this.filteredLineGroups = filteredLineGroups;
    this.filteredSortedGroups = filteredSortedGroups;
    this.filteredIndex2orgIndex = filteredIndex2orgIndex;
    this.toggleOn = naOn;
  }
  tdClick(idx: number) {
    this.tdRef?.current[idx]?.click();
  }
  tdFocus(idx: number) {
    this.tdRef?.current[idx]?.focus();
  }
  inputClick(idx: number) {
    this.inputRefs?.current[idx]?.click();
  }
  inputFocus(idx: number) {
    this.inputRefs?.current[idx]?.focus();
  }
  inputValueUpdate(idx: number, value: string, record: boolean = true) {
    if (idx < this.lineGroups.length) {
      this.updateLineValue(value, idx, record);
    } else {
      this.updateGroupValue(value, idx, record);
    }
    this.values[idx] = value;
  }

  setTdRef(t: React.MutableRefObject<HTMLTableCellElement[]>) {
    this.tdRef = t;
  }
  setInputRef(
    t: React.MutableRefObject<(HTMLInputElement | HTMLTextAreaElement)[]>,
  ) {
    this.inputRefs = t;
  }

  // 파일 변경시 변수등을 처리해주는 함수.
  changeExtractorFile(
    result: extractorOacResponse.ExtractorInferenceResult,
    selected: number,
  ) {
    this.parsingTableVariableList = [];
    if (!result) return;
    if (this.parsingTableVariableList.length === selected) {
      const _lines = (
        result as extractorOacResponse.ExtractorInferenceResult
      ).filter(field => field.type !== "group") as Line[];
      const _groups =
        ((result as extractorOacResponse.ExtractorInferenceResult).filter(
          field => field.type === "group",
        ) as Group[]) ?? [];
      const _contentGroups =
        _groups.filter(
          group =>
            group.properties.filter(property => property.type === "content")
              .length,
        ) ?? [];

      const _groupKeys: string[] = [];
      if (this.ontologies?.length > 0) {
        this.ontologies
          .filter(ontology => ontology.group_type === 0)
          ?.forEach(ontology => {
            if (ontology.key.includes(".content")) {
              _groupKeys.push(
                "group_0/" + ontology.key.replace(".content", ""),
              );
            }
          });
      } else {
        Array.from(
          new Set(
            _contentGroups.flatMap(group =>
              group.properties.map(property => property.key),
            ),
          ),
        )
          .sort((a, b) => {
            if (a.includes("name")) return -1;
            if (b.includes("name")) return 1;
            return 0;
          })
          .forEach(key => {
            _groupKeys.push(key);
          });
      }
      const _orgSortedGroups = _contentGroups.map(group => {
        const sortedProperties = _groupKeys.map(key => {
          const matchedProperty = group.properties.find(
            property => property.key === key,
          );
          if (matchedProperty) return matchedProperty;
          return {
            ...dummyProperty,
            id: dummyId--,
            key,
            value: "",
            refinedValue: "",
          };
        });
        return { ...group, properties: sortedProperties };
      });
      const _lineKeys = Array.from(new Set(_lines.map(line => line.key)));
      const lineGroupCopy: Line[] = _.cloneDeep(
        _lineKeys
          ?.flatMap(key =>
            _lines.filter(line => line.key === key).flatMap(t => t),
          )
          .filter(t => t.type === "content"),
      );

      // || 으로 이어진 부분이 있으면 파싱해준다.
      const _lineGroups: Line[] = [];
      lineGroupCopy.forEach(line => {
        const valueParsings = (line.refinedValue || line.value).split(" || ");
        valueParsings.forEach(v => {
          const _line: Line = _.cloneDeep(line);
          if (_line.refinedValue) {
            _line.refinedValue = v;
          } else {
            _line.value = v;
          }
          _lineGroups.push(_line);
        });
      });

      this.parsingTableVariableList.push({
        lineKeys: _lineKeys,
        lineGroups: _lineGroups,
        originalLineGroups: _.cloneDeep(_lineGroups),
        groupKeys: _groupKeys,
        sortedGroups: _.cloneDeep(_orgSortedGroups),
        orgSortedGroups: _orgSortedGroups,
      });
    }
    const ptv = this.parsingTableVariableList[selected];
    this.lineKeys = ptv.lineKeys;
    this.lineGroups = ptv.lineGroups;
    this.originalLineGroups = ptv.originalLineGroups;
    this.groupKeys = ptv.groupKeys;
    this.sortedGroups = ptv.sortedGroups;
    this.orgSortedGroups = ptv.orgSortedGroups;

    this.values = [];
    this.lineGroups.forEach(lineGroup =>
      this.values.push(lineGroup.refinedValue || lineGroup.value),
    );
    this.sortedGroups.forEach(sortedGroup => [
      sortedGroup.properties.forEach(property =>
        this.values.push(property.refinedValue || property.value),
      ),
    ]);

    this.orgValues = [];

    this.originalLineGroups.forEach(lineGroup => {
      this.orgValues.push(lineGroup.refinedValue || lineGroup.value);
    });
    this.orgSortedGroups.forEach(sortedGroup => [
      sortedGroup.properties.forEach(property =>
        this.orgValues.push(property.refinedValue || property.value),
      ),
    ]);

    //largestId 업데이트
    this.lineGroups.forEach(
      lineGroup => (this.largestId = Math.max(lineGroup.id, this.largestId)),
    );
    this.sortedGroups.forEach(
      sortedGroup =>
        (this.largestId = Math.max(sortedGroup.id, this.largestId)),
    );
    this.orgLargestId = this.largestId;

    this.naController();
    this.naController();
    this.historyStack = [];
    this.redoStack = [];
  }

  isChanged(targetIdx: number) {
    const idx = this.filteredIndex2orgIndex[targetIdx];
    return this.values[idx] !== this.orgValues[idx];
  }

  updateGroupValue(value: string, idx: number, record: boolean = true) {
    const t = idx - this.lineGroups.length;
    const groupIdx = Math.floor(t / this.groupKeys.length);
    const propertyIdx = t % this.groupKeys.length;
    const property = this.sortedGroups[groupIdx].properties[propertyIdx];

    let stack = this.historyStack;
    // history 스택에 기록. 업데이트하기 이전에 있던 값을 기록한다.
    if (record) {
      this.redoStack = [];
    } else {
      // undo할경우 롤백 스택에 기록해준다.
      stack = this.redoStack;
    }
    stack.push({
      order: HistoryOrder.cv,
      idx: idx,
      data: property.refinedValue || property.value,
      orgValue: this.orgValues[idx],
    });

    if (property.refinedValue) {
      property.refinedValue = value;
    } else {
      property.value = value;
    }
  }

  updateLineValue(value: string, lineIdx: number, record: boolean = true) {
    let stack = this.historyStack;
    // history 스택에 기록. 업데이트하기 이전에 있던 값을 기록한다.
    if (record) {
      this.redoStack = [];
    } else {
      stack = this.redoStack;
    }
    stack.push({
      order: HistoryOrder.cv,
      idx: lineIdx,
      data:
        this.lineGroups[lineIdx].refinedValue || this.lineGroups[lineIdx].value,
      orgValue: this.orgValues[lineIdx],
    });

    // refinedValue, value 모두 업데이트
    this.lineGroups[lineIdx].refinedValue = value;
    this.lineGroups[lineIdx].value = value;
  }

  updateValue(value: string, idx: number) {
    this.values[this.filteredIndex2orgIndex[idx]] = value;
  }

  get getChanged() {
    let t = 0;
    this.values.forEach((value, idx) => {
      if (this.orgValues[idx] !== value) t += 1;
    });
    return t;
  }

  delLineGroups(idx: number, record: boolean = true) {
    const orgIdx: number = this.filteredIndex2orgIndex[idx];

    let stack = this.historyStack;
    // history 스택에 기록.
    if (record) {
      this.redoStack = [];
    } else {
      stack = this.redoStack;
    }
    stack.push({
      order: HistoryOrder.dl,
      idx: idx,
      data: _.cloneDeep(this.lineGroups[orgIdx]),
      orgValue: this.orgValues[orgIdx],
    });

    // 값이 삭제가 되었으므로 삭제된 값 이후로 한칸씩 댕긴다.

    this.filteredLineGroups = [
      ...this.filteredLineGroups.slice(0, idx),
      ...this.filteredLineGroups.slice(idx + 1),
    ];

    this.lineGroups = [
      ...this.lineGroups.slice(0, orgIdx),
      ...this.lineGroups.slice(orgIdx + 1),
    ];

    this.filteredIndex2orgIndex = [
      ...this.filteredIndex2orgIndex.slice(0, idx),
      ...this.filteredIndex2orgIndex.slice(idx + 1).map(n => n - 1),
    ];

    this.values = [
      ...this.values.slice(0, orgIdx),
      ...this.values.slice(orgIdx + 1),
    ];

    this.orgValues = [
      ...this.orgValues.slice(0, orgIdx),
      ...this.orgValues.slice(orgIdx + 1),
    ];
  }

  addLineGroups(
    idx: number,
    addLine: Line = {
      id: -1,
      confidence: null,
      key: "",
      type: "",
      value: "",
      refinedValue: "",
      boundingBoxes: [],
    },
    orgVal = "",
    record: boolean = true,
  ) {
    const orgIdx: number = this.filteredIndex2orgIndex[idx];
    // default로 정할수 없는 key, type 등을 런타임에서 정해준다.
    if (addLine.id === -1) {
      addLine.id = this.filteredLineGroups[idx - 1].id;
      addLine.type = this.filteredLineGroups[idx - 1].type;
      addLine.key = this.filteredLineGroups[idx - 1].key;
    }
    let stack = this.historyStack;
    // history 스택에 기록.
    if (record) {
      this.redoStack = [];
    } else {
      stack = this.redoStack;
    }
    stack.push({
      order: HistoryOrder.al,
      idx: idx,
      data: addLine,
      orgValue: orgVal,
    });
    // 값이 추가가 되었으므로 추가된 값 이후로 한칸씩 밀린다.
    this.filteredLineGroups = [
      ...this.filteredLineGroups.slice(0, idx),
      addLine,
      ...this.filteredLineGroups.slice(idx),
    ];

    this.lineGroups = [
      ...this.lineGroups.slice(0, orgIdx),
      addLine,
      ...this.lineGroups.slice(orgIdx),
    ];

    this.filteredIndex2orgIndex = [
      ...this.filteredIndex2orgIndex.slice(0, idx),
      orgIdx,
      ...this.filteredIndex2orgIndex.slice(idx).map(n => n + 1),
    ];

    // todo: addLine이 항상 맨처음 상태이다. inputValueUpdate를 통해 업데이트한 값이 반영이 안된다. 왜그럴까? ㅇㅅㅇ..
    this.values = [
      ...this.values.slice(0, orgIdx),
      addLine.refinedValue || addLine.value,
      ...this.values.slice(orgIdx),
    ];

    this.orgValues = [
      ...this.orgValues.slice(0, orgIdx),
      orgVal,
      ...this.orgValues.slice(orgIdx),
    ];
  }
  //하단부 그루핑 영역을 삭제한다.
  delSortedGroups(idx: number, record: boolean = true) {
    const groupLen: number = this.visibleGroupKeys.length;
    const lineGroupLen: number = this.filteredLineGroups.length;
    const orgIdx: number =
      this.filteredIndex2orgIndex[lineGroupLen + groupLen * idx];
    const orgGroupIdx: number = Math.floor(
      (orgIdx - lineGroupLen) / groupLen + 0.0001,
    );

    let stack = this.historyStack;
    // history 스택에 기록.
    if (record) {
      this.redoStack = [];
    } else {
      stack = this.redoStack;
    }
    stack.push({
      order: HistoryOrder.dg,
      idx: idx,
      data: _.cloneDeep(this.sortedGroups[orgGroupIdx]),
      orgValue: [...this.orgValues.slice(orgIdx, orgIdx + groupLen)],
    });

    // 값이 삭제가 되었으므로 삭제된 값 이후로 한칸씩 댕긴다.
    this.filteredSortedGroups = [
      ...this.filteredSortedGroups.slice(0, idx),
      ...this.filteredSortedGroups.slice(idx + 1),
    ];

    this.sortedGroups = [
      ...this.sortedGroups.slice(0, orgGroupIdx),
      ...this.sortedGroups.slice(orgGroupIdx + 1),
    ];

    this.filteredIndex2orgIndex = [
      ...this.filteredIndex2orgIndex.slice(
        0,
        this.filteredLineGroups.length + this.groupKeys.length * idx,
      ),
      ...this.filteredIndex2orgIndex
        .slice(
          this.filteredLineGroups.length + this.groupKeys.length * (idx + 1),
        )
        .map(n => n - groupLen),
    ];

    this.values = [
      ...this.values.slice(0, orgIdx),
      ...this.values.slice(orgIdx + groupLen),
    ];

    this.orgValues = [
      ...this.orgValues.slice(0, orgIdx),
      ...this.orgValues.slice(orgIdx + groupLen),
    ];
  }

  addSortedGroups(
    idx: number,
    addGroup: Group = {
      id: -1,
      confidence: null,
      key: "",
      type: "group",
      value: "",
      properties: [],
    },
    orgValues: string[] = [],
    record: boolean = true,
  ) {
    const groupLen: number = this.visibleGroupKeys.length;
    const lineGroupLen: number = this.filteredLineGroups.length;
    // 맨뒤에 추가되는 경우에는 undefined 방지로 마지막 인덱스 +1(길이만큼)
    const orgIdx: number = Math.floor(
      ((this.filteredIndex2orgIndex[lineGroupLen + groupLen * idx] ||
        this.lineGroups.length +
          this.sortedGroups.length * this.groupKeys.length) -
        this.lineGroups.length) /
        this.groupKeys.length +
        0.0001,
    );

    // orgValues가 비어있으면 더미값을 넣어준다.
    if (orgValues.length === 0) {
      orgValues = this.groupKeys.map(k => "");
    }

    let initId = this.lastId;
    while (
      this.sortedGroups.some(group =>
        Array.from(
          { length: this.groupKeys.length + 1 },
          (_, i) =>
            group.id === initId - i ||
            group.properties.some(p => p.id === initId - i),
        ).some(Boolean),
      )
    ) {
      initId -= this.groupKeys.length + 1;
    }

    // addGroup의 properties가 비어있을 경우 더미값을 넣어준다.
    addGroup.id = initId--;
    addGroup.properties =
      addGroup.properties.length > 0
        ? addGroup.properties
        : [
            ...this.groupKeys.map(k => ({
              confidence: null,
              id: initId--,
              key: k,
              type: "content",
              value: "",
              refinedValue: "",
              boundingBoxes: [],
            })),
          ];

    let stack = this.historyStack;
    // history 스택에 기록.
    if (record) {
      this.redoStack = [];
    } else {
      stack = this.redoStack;
    }

    stack.push({
      order: HistoryOrder.ag,
      idx: idx,
      data: addGroup,
      orgValue: orgValues,
    });

    // 값이 추가가 되었으므로 삭제된 값 이후로 한칸씩 밀린다.
    this.filteredSortedGroups = [
      ...this.filteredSortedGroups.slice(0, idx),
      addGroup,
      ...this.filteredSortedGroups.slice(idx),
    ];

    this.sortedGroups = [
      ...this.sortedGroups.slice(0, orgIdx),
      addGroup,
      ...this.sortedGroups.slice(orgIdx),
    ];

    this.filteredIndex2orgIndex = [
      ...this.filteredIndex2orgIndex.slice(
        0,
        this.filteredLineGroups.length + groupLen * idx,
      ),
      ...this.groupKeys.map(
        (k, kIdx) => this.lineGroups.length + groupLen * orgIdx + kIdx,
      ),
      ...this.filteredIndex2orgIndex
        .slice(this.filteredLineGroups.length + groupLen * idx)
        .map(n => n + groupLen),
    ];

    this.values = [
      ...this.values.slice(0, orgIdx * groupLen + this.lineGroups.length),
      ...addGroup.properties.map(g => g.refinedValue || g.value),
      ...this.values.slice(orgIdx * groupLen + this.lineGroups.length),
    ];

    this.orgValues = [
      ...this.orgValues.slice(0, orgIdx * groupLen + this.lineGroups.length),
      ...orgValues,
      ...this.orgValues.slice(orgIdx * groupLen + this.lineGroups.length),
    ];
  }
}

export const parsingTableStore = new PasringTableStore();
