import { RefObject, useEffect, useState } from "react";

type MovingMode = "ready" | "movable" | "moving" | "stop";
type ToolCursor = "cursor-default" | "cursor-grab" | "cursor-grabbing";

export function useImageTool(
  canvas: RefObject<HTMLDivElement>,
  image: RefObject<SVGSVGElement>,
  onMoveImage: (offset: { x: number; y: number }) => void,
) {
  const [mouseOrigin, setMouseOrigin] = useState<{
    x: number;
    y: number;
  }>({ x: 0, y: 0 });

  const [movingMode, setMovingMode] = useState<MovingMode>("stop");
  const [toolCursor, setToolCursor] = useState<ToolCursor>("cursor-grab");

  useEffect(() => {
    const mousedown = (e: MouseEvent) => {
      if (movingMode === "stop") {
        setMovingMode("movable");
        setToolCursor("cursor-grabbing");
        const rc = image.current?.getBoundingClientRect() || {
          left: 0,
          top: 0,
        };
        const x = e.clientX - rc.left;
        const y = e.clientY - rc.top;
        setMouseOrigin({ x, y });
      }
    };
    const mouseup = (e: MouseEvent) => {
      if (movingMode === "movable" || movingMode === "moving") {
        setMovingMode("stop");
        setToolCursor("cursor-grab");
      }
    };
    const mousemove = (e: MouseEvent) => {
      if (movingMode === "movable") {
        setMovingMode("moving");
        setToolCursor("cursor-grabbing");
      } else if (movingMode === "moving") {
        const rc = image.current?.getBoundingClientRect() || {
          left: 0,
          top: 0,
        };
        const x = e.clientX - rc.left;
        const y = e.clientY - rc.top;

        onMoveImage({
          x: x - mouseOrigin.x,
          y: y - mouseOrigin.y,
        });
      }
    };

    canvas.current?.addEventListener("mousedown", mousedown);
    canvas.current?.addEventListener("mouseup", mouseup);
    canvas.current?.addEventListener("mousemove", mousemove);

    return () => {
      canvas.current?.removeEventListener("mousedown", mousedown);
      canvas.current?.removeEventListener("mouseup", mouseup);
      canvas.current?.removeEventListener("mousemove", mousemove);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [canvas.current, image.current, movingMode]);

  return {
    cursor: toolCursor,
  };
}
