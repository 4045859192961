import type { SVGProps } from "react";

export function Reset(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="36"
      height="36"
      viewBox="0 0 36 36"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M21.2803 15.7803L24.5 12.5607V15.25C24.5 15.6642 24.8358 16 25.25 16C25.6642 16 26 15.6642 26 15.25V10.75C26 10.3358 25.6642 10 25.25 10H20.75C20.3358 10 20 10.3358 20 10.75C20 11.1642 20.3358 11.5 20.75 11.5H23.4393L20.2197 14.7197C19.9268 15.0126 19.9268 15.4874 20.2197 15.7803C20.5126 16.0732 20.9874 16.0732 21.2803 15.7803Z"
        fill="#111218"
      />
      <path
        d="M10 25.25V20.75C10 20.3358 10.3358 20 10.75 20C11.1642 20 11.5 20.3358 11.5 20.75V23.4393L14.7197 20.2197C15.0126 19.9268 15.4874 19.9268 15.7803 20.2197C16.0732 20.5126 16.0732 20.9874 15.7803 21.2803L12.5607 24.5H15.25C15.6642 24.5 16 24.8358 16 25.25C16 25.6642 15.6642 26 15.25 26H10.75C10.5581 26 10.3661 25.9268 10.2197 25.7803C10.1478 25.7084 10.0935 25.6255 10.0569 25.5371C10.0202 25.4487 10 25.3517 10 25.25Z"
        fill="#111218"
      />
      <path
        d="M20.2197 21.2803L23.4393 24.5H20.75C20.3358 24.5 20 24.8358 20 25.25C20 25.6642 20.3358 26 20.75 26H25.25C25.4419 26 25.6339 25.9268 25.7803 25.7803C25.8522 25.7084 25.9065 25.6255 25.9431 25.5371C25.9798 25.4487 26 25.3517 26 25.25V20.75C26 20.3358 25.6642 20 25.25 20C24.8358 20 24.5 20.3358 24.5 20.75V23.4393L21.2803 20.2197C20.9874 19.9268 20.5126 19.9268 20.2197 20.2197C19.9268 20.5126 19.9268 20.9874 20.2197 21.2803Z"
        fill="#111218"
      />
      <path
        d="M11.5 12.5607L14.7197 15.7803C15.0126 16.0732 15.4874 16.0732 15.7803 15.7803C16.0732 15.4874 16.0732 15.0126 15.7803 14.7197L12.5607 11.5H15.25C15.6642 11.5 16 11.1642 16 10.75C16 10.3358 15.6642 10 15.25 10H10.75C10.3358 10 10 10.3358 10 10.75V15.25C10 15.6642 10.3358 16 10.75 16C11.1642 16 11.5 15.6642 11.5 15.25V12.5607Z"
        fill="#111218"
      />
    </svg>
  );
}
