import type { SVGProps } from "react";

export function RightBar(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="68"
      height="8"
      viewBox="0 0 68 8"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.5 0H64C66.2091 0 68 1.79086 68 4C68 6.20914 66.2091 8 64 8H0.5V0Z"
        fill="black"
        fill-opacity="0.5"
      />
    </svg>
  );
}
