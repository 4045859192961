import type { SVGProps } from "react";

export function DivisionBar(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="1"
      height="36"
      viewBox="0 0 1 36"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="1" height="36" fill="#808080" />
    </svg>
  );
}
