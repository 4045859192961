import type { SVGProps } from "react";

export function EyeClosed(props: SVGProps<SVGSVGElement>) {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d="M2.62426 1.77571C2.38995 1.5414 2.01005 1.5414 1.77574 1.77571C1.54142 2.01003 1.54142 2.38992 1.77574 2.62424L13.3757 14.2242C13.61 14.4586 13.9899 14.4586 14.2243 14.2242C14.4586 13.9899 14.4586 13.61 14.2243 13.3757L12.8281 11.9796C14.0051 11.0875 14.9276 9.87715 15.4675 8.47672C15.5852 8.17147 15.5853 7.83299 15.4678 7.52767C14.3136 4.52838 11.4048 2.39998 7.99887 2.39998C6.62566 2.39998 5.33325 2.74596 4.20408 3.35553L2.62426 1.77571ZM6.20155 5.353L7.07494 6.22638C7.35161 6.08176 7.66633 5.99998 8.00015 5.99998C9.10472 5.99998 10.0001 6.89541 10.0001 7.99998C10.0001 8.33379 9.91836 8.64851 9.77374 8.92518L10.6473 9.79872C10.9963 9.28612 11.2003 8.66687 11.2003 7.99998C11.2003 6.23266 9.76761 4.79998 8.0003 4.79998C7.3334 4.79998 6.71415 5.00398 6.20155 5.353Z" fill="white" />
      <path d="M8.59871 11.1441L10.6169 13.1623C9.79699 13.4459 8.91664 13.6 8.0003 13.6C4.59438 13.6 1.68558 11.4716 0.531404 8.47228C0.413913 8.16696 0.414015 7.82848 0.531688 7.52324C0.924827 6.50343 1.52086 5.58443 2.27033 4.81573L4.85616 7.40156C4.81948 7.59542 4.8003 7.79545 4.8003 7.99998C4.8003 9.76729 6.23299 11.2 8.0003 11.2C8.20482 11.2 8.40486 11.1808 8.59871 11.1441Z" fill="white" />
    </svg>
  );
}
