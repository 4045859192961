import { observer } from "mobx-react";
import { FC, useEffect, useRef, useState } from "react";
import { handleKeyPress } from "../atom/function/parsingTablekeyBoardManger";
import { Icon, Icons } from "../components/Icon";
import ErrorModal from "../components/Modal/ErrorModal";
import NonGroupingAddModal from "../components/Modal/nonGroupingAddModal";
import { EXCEED_GROUPING_ADD } from "../constants/ErrorMessage";
import { ADD_VALUE_ON, TABLE_ABLE } from "../constants/Features";
import { classnames } from "../utils/classnames";
import { imageViewStore } from "../utils/mobx/ImageViewStore";
import { parsingTableStore } from "../utils/mobx/ParsingTableStore";
import { resultInteractionStore } from "../utils/mobx/ResultInteractionStore";
import { resultToastStore } from "../utils/mobx/ResultToastStore";
import { boxChangedFocusMover } from "../utils/resultScrollHelper";

interface ParsingResultTableProps {
  disabled?: boolean;
}

const ParsingResultTable: FC<ParsingResultTableProps> = ({
  disabled = false,
}) => {
  const {
    setAdditionalFocusIndexes,
    additionalFocusIndexes,
    hoveredBoxIds,
    isGrouptHovered,
    setHoveredBoxIds,
    selectedBoxIds,
    setSelectedBoxIds,
    focusIndex: curIndex,
    setFocusIndex: setIndex,
    setFocusEnable,
    isGrouptSelected,
  } = resultInteractionStore;
  const {
    filteredIndex2orgIndex,
    filteredLineGroups: lineGroups,
    visibleGroupKeys: groupKeys,
    filteredSortedGroups: sortedGroups,
    sortedGroups: orgSortedGroups,
    values,
    orgValues,
    isChanged,
    setTdRef,
    setInputRef,
    inputValueUpdate,
    delLineGroups,
    delSortedGroups,
    addSortedGroups,
    confidenceThreshold,
    lineModalOn,
    setLineModalOn,
    orgLargestId,
  } = parsingTableStore;
  const [isRowClicked, setIsRowClicked] = useState(false);
  const { svgWidth, svgHeight } = imageViewStore;
  const { setMessage, setToastOpen } = resultToastStore;
  const { reset, setReset } = imageViewStore;

  const [errorOn, setErrorOn] = useState<boolean>(false);
  const [reloadFunction, setReloadFunction] = useState<() => void>(() => {});
  const [errorType, setErrorType] = useState<number>(1);
  const trRef = useRef<HTMLTableRowElement[]>([]);
  const tdRef = useRef<HTMLTableCellElement[]>([]);
  const inputRefs = useRef<(HTMLInputElement | HTMLTextAreaElement)[]>([]);
  const divRef = useRef<HTMLDivElement>(null);

  const [td1Width, setTd1Wdith] = useState<number>(0);
  const [td2Width, setTd2Width] = useState<number>(0);

  const [blockType, setBlockType] = useState<string>("center");

  const [hoverIndex, setHoverIndex] = useState<number>(-1);
  // const [hoverTableIndex, setHoverTableIndex] = useState<number>(-1);

  const [isTransposed, setIsTransposed] = useState<boolean>(false);
  const [isDelBtnHover, setIsDelBtnHover] = useState<boolean>(false);

  useEffect(() => {
    const initTransposed = process.env.REACT_APP_TRANSPOSED;
    if (initTransposed) {
      setIsTransposed(initTransposed.toLowerCase() === "true");
    }
  }, []);

  useEffect(() => {
    // 이미지뷰에서 박스가 클릭되었을때만 자동 스크롤
    if (isRowClicked) {
      setIsRowClicked(false);
      return;
    }

    if (selectedBoxIds.length) {
      boxChangedFocusMover(selectedBoxIds, "center");
      lineGroups.forEach((lineGroup, idx) => {
        if (selectedBoxIds.includes(String(lineGroup.id))) {
          setIndex(idx);
          return;
        }
      });
      sortedGroups.forEach((group, groupIdx) => {
        group.properties
          .filter(property => property.type === "content")
          .forEach((property, idx) => {
            if (selectedBoxIds.includes(String(property.id))) {
              setIndex(lineGroups.length + groupKeys.length * groupIdx + idx);
              return;
            }
          });
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedBoxIds]);

  function autoResize(textarea: HTMLTextAreaElement) {
    textarea.style.width = "auto";
    // 실제 너비 계산
    var width = textarea.scrollWidth;
    // 너비를 텍스트 영역에 설정
    textarea.style.width = width + "px";
  }

  // useEffect(() => {
  //   let maxTd = tdWidth;
  //   for (let i = 0; i <= lineGroups.length; i += 1) {
  //     if (trRef.current[i]) {
  //       maxTd = Math.max(
  //         maxTd,
  //         parseInt(
  //           window.getComputedStyle(tdRef.current[i]).width.replace("px", ""),
  //         ),
  //       );
  //     }
  //   }

  //   if (trRef.current[0] && tdWidth !== maxTd) {
  //     setTdWdith(maxTd + 2);
  //   }
  //   for (let i = 0; i <= sortedGroups.length * groupKeys.length; i += 1) {
  //     if (inputRefs.current[lineGroups.length + i]) {
  //       autoResize(
  //         inputRefs.current[lineGroups.length + i] as HTMLTextAreaElement,
  //       );
  //     }
  //   }
  // }, [
  //   trRef,
  //   tdRef,
  //   sortedGroups,
  //   tdWidth,
  //   lineGroups,
  //   svgWidth,
  //   svgHeight,
  //   groupKeys.length,
  // ]);

  useEffect(() => {
    const updateWidths = () => {
      let maxTd1 = 0;
      for (let i = 0; i <= lineGroups.length; i += 1) {
        if (trRef.current[i]) {
          const canvas = document.createElement("canvas");
          const context = canvas.getContext("2d");
          if (context) {
            context.font = "14px Noto Sans KR"; // 폰트 설정
            const width = context.measureText(lineGroups[i].key).width;
            maxTd1 = Math.max(maxTd1, width);
          }
        }
      }
      maxTd1 += 20;
      setTd1Wdith(maxTd1);

      let maxTd2 = 0;
      for (let i = 0; i <= values.length; i += 1) {
        if (trRef.current[i]) {
          const canvas = document.createElement("canvas");
          const context = canvas.getContext("2d");
          if (context) {
            context.font = "14px Noto Sans KR"; // 폰트 설정
            const width = context.measureText(values[i]).width;
            maxTd2 = Math.max(maxTd2, width);
          }
        }
      }
      maxTd2 += 20;

      if (divRef.current) {
        maxTd2 = Math.max(maxTd2, divRef.current.offsetWidth - maxTd1 - 40);
      }

      setTd2Width(maxTd2);
    };

    updateWidths();
    window.addEventListener("resize", updateWidths);

    return () => {
      window.removeEventListener("resize", updateWidths);
    };
  }, [divRef, td2Width, td1Width, values, lineGroups, inputRefs]);

  function getFilterdProperties(groupIdx: number) {
    return sortedGroups[groupIdx].properties
      .filter(property => property.type === "content")
      .filter(property => groupKeys.includes(property.key));
  }

  function getGroupingTable() {
    const targetArray1 = !isTransposed
      ? Array.from({ length: sortedGroups.length })
      : Array.from({ length: groupKeys.length });
    const targetArray2 = !isTransposed
      ? Array.from({ length: groupKeys.length })
      : Array.from({ length: sortedGroups.length });

    const groupingTable = [];
    for (let idx1 = 0; idx1 < targetArray1.length; idx1++) {
      const row = [];
      if (isTransposed) {
        row.push(
          <th
            key={`gh${idx1}`}
            id={`th-${idx1}`}
            scope="col"
            className={classnames({
              "w-full px-2 py-2 border-black/5 font-normal text-gray-400 break-words whitespace-nowrap text-left":
                true,
            })}
          >
            {groupKeys[idx1].split("/")[1]}
          </th>,
        );
      }
      for (let idx2 = 0; idx2 < targetArray2.length; idx2++) {
        const groupIdx = !isTransposed ? idx1 : idx2;
        const propIdx = !isTransposed ? idx2 : idx1;

        let maxWidth = 10;
        for (let _idx1 = 0; _idx1 < targetArray1.length; _idx1++) {
          const groupIdx = !isTransposed ? _idx1 : idx2;
          const propIdx = !isTransposed ? idx2 : _idx1;
          let value = getValue(groupIdx, propIdx);

          const canvas = document.createElement("canvas");
          const context = canvas.getContext("2d");
          if (context) {
            context.font = "14px Noto Sans KR"; // 폰트 설정
            const width = context.measureText(value).width;
            maxWidth = Math.max(maxWidth, width);
          }
        }

        row.push(
          <td
            key={`g${groupIdx}p${propIdx}`}
            id={`td-${lineGroups.length + groupKeys.length * groupIdx + propIdx}`}
            onClick={e => handleCellClick(e, groupIdx, propIdx)}
            onMouseEnter={() => {
              handleMouseEnter(groupIdx);
            }}
            onMouseLeave={handleMouseLeave}
            ref={el => setGroupingTdRef(el, groupIdx, propIdx)}
            className={classnames(getCellClassNames(groupIdx, propIdx))}
          >
            <div className="relative">
              <div className="flex relative">
                <input
                  style={{ width: maxWidth + 20 }}
                  ref={el => setGroupingInputRef(el, groupIdx, propIdx)}
                  id={`g input-${groupIdx * groupKeys.length + propIdx}`}
                  className={classnames(getInputClassNames(groupIdx, propIdx))}
                  value={getValue(groupIdx, propIdx)}
                  placeholder="N/A"
                  onClick={event => handleInputClick(groupIdx, propIdx)}
                  disabled={!TABLE_ABLE}
                  onKeyDown={event =>
                    handleKeyPress(
                      event,
                      lineGroups.length + groupKeys.length * groupIdx + propIdx,
                      tdRef,
                      inputRefs,
                    )
                  }
                  onChange={event =>
                    handleInputChange(event, groupIdx, propIdx)
                  }
                />
                {idx1 === 0 && isTransposed && (
                  <div
                    className={classnames({
                      "absolute top-[-15px] z-10 left-1/2 transform -translate-x-1/2":
                        true,
                      invisible: lineGroups.length + idx2 !== hoverIndex,
                    })}
                    key={"gg del transposed " + idx2}
                  >
                    <div
                      className={classnames({
                        "flex h-[28px] w-[28px] border rounded-lg border-gray-200 justify-center align-middle items-center bg-white":
                          true,
                        "opacity-100": isDelBtnHover,
                        "opacity-50": !isDelBtnHover,
                      })}
                      onMouseEnter={() => {
                        setIsDelBtnHover(true);
                      }}
                      onMouseLeave={() => {
                        setIsDelBtnHover(false);
                      }}
                      onClick={e => {
                        delSortedGroups(idx2);
                        setHoverIndex(-1);
                        setHoveredBoxIds([]);
                        setIndex(-1);
                        setMessage(
                          isTransposed ? "Column removed" : "Row removed",
                        );
                        setToastOpen(true);
                      }}
                      key={"g del transposed " + idx2}
                    >
                      <Icon className="py-1 opacity-100" source={Icons.Minus} />
                    </div>
                  </div>
                )}
              </div>
            </div>
          </td>,
        );
      }
      groupingTable.push(
        <tr
          key={`g${idx1}`}
          className="break-words w-full rounded mx-2 my-1 divide-x divide-gray-100  h-9"
        >
          {row}
        </tr>,
      );
    }
    return groupingTable;
  }

  function handleCellClick(
    e: React.MouseEvent<HTMLTableCellElement>,
    groupIdx: number,
    idx: number,
  ) {
    const group = sortedGroups[groupIdx];
    e.stopPropagation();
    const isGroupAdded = group.id < 0 || group.id > orgLargestId;
    setFocusEnable(!isGroupAdded);

    setIsRowClicked(true);
    setSelectedBoxIds([
      String(group.id),
      ...group.properties.map(property => String(property.id)),
    ]);
    setHoverIndex(-1);
    setHoveredBoxIds([]);
    setAdditionalFocusIndexes([]);
    setIndex(lineGroups.length + groupKeys.length * groupIdx + idx);
  }

  function handleMouseEnter(groupIdx: number) {
    const group = sortedGroups[groupIdx];
    if (curIndex === -1) {
      setHoverIndex(lineGroups.length + groupIdx);
      setHoveredBoxIds([
        String(group.id),
        ...group.properties.map(property => String(property.id)),
      ]);
    }
  }

  function handleMouseLeave() {
    if (curIndex === -1) {
      setHoverIndex(-1);
      setHoveredBoxIds([]);
    }
  }

  function setGroupingTdRef(
    el: HTMLTableCellElement | null,
    groupIdx: number,
    propIdx: number,
  ) {
    if (el) {
      tdRef.current[lineGroups.length + groupKeys.length * groupIdx + propIdx] =
        el;
      setTdRef(tdRef);
    }
  }

  function setGroupingInputRef(
    el: HTMLInputElement | null,
    groupIdx: number,
    propIdx: number,
  ) {
    if (el) {
      inputRefs.current[
        lineGroups.length + groupKeys.length * groupIdx + propIdx
      ] = el;
      setInputRef(inputRefs);
    }
  }

  function isGroupConfidence(
    groupIdx: number,
    propIdx: number,
    isGreaterThanThreshold: boolean,
  ) {
    const property = getFilterdProperties(groupIdx)[propIdx];

    if (property.confidence === undefined) {
      return false;
    }

    if (property.confidence === null) {
      return false;
    }

    if (isGreaterThanThreshold) {
      return property.confidence >= confidenceThreshold;
    }

    return property.confidence < confidenceThreshold;
  }

  function getCellClassNames(groupIdx: number, propIdx: number) {
    const group = sortedGroups[groupIdx];
    // const property = getFilterdProperties(groupIdx)[idx];
    return {
      "break-words w-full rounded mx-2 my-1 divide-x divide-gray-100 h-9 cursor-text":
        true,
      "px-2 py-2 text-opacity-90 whitespace-nowrap w-full overflow-visible":
        true,
      "shadow-inline":
        lineGroups.length + groupKeys.length * groupIdx + propIdx ===
          curIndex ||
        additionalFocusIndexes.includes(
          lineGroups.length + groupKeys.length * groupIdx + propIdx,
        ),
      "text-left": getFilterdProperties(groupIdx)
        [propIdx].key.split(".")
        .includes("product_name"),
      "text-black":
        isGroupConfidence(groupIdx, propIdx, true) &&
        !isChanged(lineGroups.length + groupKeys.length * groupIdx + propIdx),
      "text-green-400": isChanged(
        lineGroups.length + groupKeys.length * groupIdx + propIdx,
      ),
      "text-[#F7493C]":
        isGroupConfidence(groupIdx, propIdx, false) &&
        !isChanged(lineGroups.length + groupKeys.length * groupIdx + propIdx),
      "bg-blue-50 border-opacity-10":
        !disabled && (isGrouptHovered(group) || isGrouptSelected(group)),
      "bg-white border-transparent":
        disabled || (!isGrouptHovered(group) && !isGrouptSelected(group)),
    };
  }

  function getInputClassNames(groupIdx: number, idx: number) {
    return {
      "h-[18px] whitespace-nowrap overflow-visible": true,
      "bg-transparent resize-none focus:outline-none": true,
      "placeholder placeholder:text-green-400 placeholder:text-opacity-40":
        isChanged(lineGroups.length + groupKeys.length * groupIdx + idx),
      "placeholder placeholder:text-gray-200 placeholder:text-opacity-80":
        !isChanged(lineGroups.length + groupKeys.length * groupIdx + idx),
      "pointer-events-none": !TABLE_ABLE,
    };
  }

  function getValue(groupIdx: number, idx: number) {
    return values[
      filteredIndex2orgIndex[
        lineGroups.length + groupKeys.length * groupIdx + idx
      ]
    ];
  }

  function handleInputClick(groupIdx: number, idx: number) {
    console.log(curIndex);
    if (!TABLE_ABLE) {
      inputRefs.current[
        lineGroups.length + groupKeys.length * groupIdx + idx
      ].click();
    }
    // if (additionalFocusIndexes.length > 0) {
    //   inputRefs.current[
    //     lineGroups.length + groupKeys.length * groupIdx + idx
    //   ].blur();
    // }
  }

  function handleInputChange(
    event: React.ChangeEvent<HTMLInputElement>,
    groupIdx: number,
    idx: number,
  ) {
    if (TABLE_ABLE && !lineModalOn) {
      inputValueUpdate(
        filteredIndex2orgIndex[
          lineGroups.length + groupKeys.length * groupIdx + idx
        ],
        event.target.value,
      );
      autoResize(
        inputRefs.current[
          lineGroups.length + groupKeys.length * groupIdx + idx
        ] as HTMLTextAreaElement,
      );
    }
  }

  return (
    <>
      <ErrorModal
        open={errorOn}
        setOpen={setErrorOn}
        errorType={errorType}
        closeFunction={() => {}}
        reloadFunction={reloadFunction}
      />
      <NonGroupingAddModal isOpen={lineModalOn} setIsOpen={setLineModalOn} />
      <div>
        {lineGroups.length > 0 && (
          <>
            <div className="h-[28px] w-[calc(100%-1.5rem)] pl-1 pt-4 mt-4 flex flex-row justify-between items-center">
              <span className="w-1/2 --heading-sm">Results</span>
              {ADD_VALUE_ON ? (
                <span
                  className="text-end text-[#6B7280] cursor-pointer flex flex-row --body-sm items-center"
                  onClick={e => {
                    e.preventDefault();
                    e.stopPropagation();
                    setLineModalOn(true);
                  }}
                >
                  <Icon source={Icons.Add} />
                  <div className="ml-[4px]">Add field</div>
                </span>
              ) : (
                <></>
              )}
            </div>
            <div
              className="flex flex-col my-4 p-4 w-[calc(100%-1.5rem)] bg-white rounded-lg border-balck min-w-full overflow-auto --body-sm items-center"
              ref={divRef}
            >
              <table className="flex flex-col w-full order-collapse">
                <tbody className="flex flex-col items-start justify-start w-full rounded">
                  {lineGroups.map((lineGroup, idx) => {
                    const linecontent = values[filteredIndex2orgIndex[idx]];
                    const originContent =
                      orgValues[filteredIndex2orgIndex[idx]];
                    return lineGroup ? (
                      <tr
                        ref={el => {
                          trRef.current[idx] = el as HTMLTableRowElement;
                        }}
                        className={classnames({
                          "w-full text-left text-black text-opacity-90 break-words rounded":
                            true,
                        })}
                        id={`row-${lineGroup.id}`}
                        key={`${lineGroup.key + "idx:" + idx}`}
                        onMouseEnter={e => {
                          if (curIndex === -1) {
                            setHoveredBoxIds([String(lineGroup.id)]);
                            setHoverIndex(idx);
                          }
                        }}
                        onMouseLeave={e => {
                          if (curIndex === -1) {
                            setHoveredBoxIds([]);
                            setHoverIndex(-1);
                          }
                        }}
                        onClick={e => {
                          e.stopPropagation();
                          setIsRowClicked(true);
                          if (lineGroup.id < 0 || lineGroup.id > orgLargestId) {
                            setFocusEnable(false);
                          } else {
                            setFocusEnable(true);
                          }
                          if (curIndex !== -1 && (e.ctrlKey || e.metaKey)) {
                            if (additionalFocusIndexes.length === 0) {
                              (
                                document.activeElement as
                                  | HTMLInputElement
                                  | HTMLTextAreaElement
                              ).blur();
                            }
                            setAdditionalFocusIndexes([
                              ...additionalFocusIndexes,
                              idx,
                            ]);
                          } else {
                            // 박스가 있을때만
                            if (
                              lineGroup.boundingBoxes &&
                              lineGroup.boundingBoxes.length > 0
                            ) {
                              setSelectedBoxIds([String(lineGroup.id)]);
                              boxChangedFocusMover(
                                [String(lineGroup.id)],
                                blockType,
                                "instant",
                                idx,
                              );
                            } else {
                              setSelectedBoxIds([]);
                            }
                            setIndex(idx);
                            setHoveredBoxIds([]);
                            setHoverIndex(-1);
                            setAdditionalFocusIndexes([]);
                          }
                        }}
                      >
                        <td
                          className={classnames({
                            "whitespace-nowrap pl-2 h-9": true,
                            "w-[12rem]": td1Width === 0,
                            "bg-blue-50  border-grey-400 border-opacity-10":
                              !disabled &&
                              (hoverIndex === idx ||
                                curIndex === idx ||
                                (hoverIndex === -1 &&
                                  hoveredBoxIds.includes(
                                    String(lineGroup.id),
                                  )) ||
                                (curIndex === -1 &&
                                  selectedBoxIds.includes(
                                    String(lineGroup.id),
                                  ))),

                            "bg-white border-transparent":
                              disabled ||
                              !(
                                hoverIndex === idx ||
                                curIndex === idx ||
                                (hoverIndex === -1 &&
                                  hoveredBoxIds.includes(
                                    String(lineGroup.id),
                                  )) ||
                                (curIndex === -1 &&
                                  selectedBoxIds.includes(String(lineGroup.id)))
                              ),
                          })}
                          ref={el => {
                            tdRef.current[idx] = el as HTMLTableCellElement;
                            setTdRef(tdRef);
                          }}
                        >
                          <div className="flex flex-row items-center justify-between h-full align-middle gpa-x-1 ">
                            <div
                              className={classnames({
                                "opacity-50 text-ellipsis": true,
                                invisible:
                                  idx === 0 ||
                                  lineGroups[idx - 1].key !== lineGroup.key
                                    ? false
                                    : true,
                              })}
                              style={
                                td1Width > 0
                                  ? {
                                      width: td1Width,
                                    }
                                  : {}
                              }
                            >
                              {lineGroup.key}
                            </div>
                          </div>
                        </td>
                        <td
                          className={classnames({
                            "bg-blue-50  border-grey-400 border-opacity-10":
                              !disabled &&
                              (hoverIndex === idx ||
                                curIndex === idx ||
                                (hoverIndex === -1 &&
                                  hoveredBoxIds.includes(
                                    String(lineGroup.id),
                                  )) ||
                                (curIndex === -1 &&
                                  selectedBoxIds.includes(
                                    String(lineGroup.id),
                                  ))),

                            "bg-white border-transparent":
                              disabled ||
                              !(
                                hoverIndex === idx ||
                                curIndex === idx ||
                                (hoverIndex === -1 &&
                                  hoveredBoxIds.includes(
                                    String(lineGroup.id),
                                  )) ||
                                (curIndex === -1 &&
                                  selectedBoxIds.includes(String(lineGroup.id)))
                              ),
                            "opacity-90 h-9 ": true,
                            "w-calc[100%-12rem] ": true || td1Width === 0,
                            "whitespace-normal": true,
                            "shadow-inline":
                              idx === curIndex ||
                              additionalFocusIndexes.includes(idx),
                            "text-green-400  border-grey-400 border-opacity-10":
                              linecontent !== originContent,
                            "text-[#F7493C]":
                              lineGroup.confidence != null &&
                              lineGroup.confidence < confidenceThreshold &&
                              linecontent === originContent,
                          })}
                          id={`td-${idx}`}
                        >
                          <input
                            ref={el =>
                              (inputRefs.current[idx] = el as HTMLInputElement)
                            }
                            className={classnames({
                              "h-full whitespace-pre-line focus:outline-none px-1":
                                true,
                              "resize-none": true,
                              "bg-transparent": true,
                              "whitespace-normal": true,
                              "placeholder placeholder:text-green-400 placeholder:text-opacity-40":
                                linecontent !== originContent,
                              "placeholder placeholder:text-gray-200 placeholder:text-opacity-80":
                                linecontent === originContent,
                              "pointer-events-none": !TABLE_ABLE,
                            })}
                            style={
                              td2Width > 0
                                ? {
                                    width: td2Width,
                                  }
                                : {}
                            }
                            type="text"
                            id={"input-" + idx}
                            disabled={!TABLE_ABLE}
                            value={linecontent}
                            placeholder="N/A"
                            onClick={() => {
                              if (!TABLE_ABLE) {
                                tdRef.current[idx].click();
                              }

                              if (additionalFocusIndexes.length > 0) {
                                // 다중 셀 선택시에는 인풋박스로 포커스되면 안된다.
                                inputRefs.current[idx].blur();
                              }
                            }}
                            onKeyDown={event =>
                              handleKeyPress(event, idx, tdRef, inputRefs)
                            }
                            onChange={event => {
                              if (TABLE_ABLE && !lineModalOn) {
                                inputValueUpdate(
                                  filteredIndex2orgIndex[idx],
                                  event.target.value,
                                );

                                autoResize(
                                  inputRefs.current[idx] as HTMLTextAreaElement,
                                );
                              }
                            }}
                          ></input>
                          {linecontent !== originContent &&
                            linecontent === "" && <div className=""></div>}
                        </td>
                      </tr>
                    ) : null;
                  })}
                </tbody>
              </table>
              {ADD_VALUE_ON ? (
                <div className="absolute flex-col right-[41px]">
                  {lineGroups.map((g, idx) => (
                    <div
                      className={`flex items-center h-9 ${true || (idx === hoverIndex && (lineGroups[idx + 1]?.key === lineGroups[idx].key || (idx > 0 && lineGroups[idx - 1].key === lineGroups[idx].key))) ? "" : " z-[-10] "}`}
                      key={"l del " + idx}
                      onMouseEnter={e => {
                        if (curIndex === -1) {
                          setHoveredBoxIds([String(g.id)]);
                          setHoverIndex(idx);
                        }
                      }}
                      onMouseLeave={e => {
                        if (curIndex === -1) {
                          setHoveredBoxIds([]);
                          setHoverIndex(-1);
                        }
                      }}
                    >
                      <div
                        className={`flex h-[28px] w-[28px] border rounded rounded-lg border-gray-200 justify-center align-middle items-center bg-white ${idx === hoverIndex && (lineGroups[idx + 1]?.key === lineGroups[idx].key || (idx > 0 && lineGroups[idx - 1].key === lineGroups[idx].key)) ? "" : " z-[-10] "}`}
                        onClick={e => {
                          delLineGroups(idx);
                          setHoverIndex(-1);
                          setHoveredBoxIds([]);
                          setIndex(-1);
                          setMessage("Field removed");
                          setToastOpen(true);
                        }}
                        key={"hi" + idx}
                      >
                        <Icon
                          className="py-1 opacity-100"
                          source={Icons.Minus}
                        />
                      </div>
                    </div>
                  ))}
                </div>
              ) : (
                <></>
              )}
            </div>
          </>
        )}

        {groupKeys.length > 0 ? (
          <>
            <div className="h-[28px] w-[calc(100%-1.5rem)] pl-1 pt-4 mt-4 flex flex-row justify-between items-center">
              <span className="w-1/2 --heading-sm">Table 1</span>
              <div className="flex flex-row">
                <span
                  className="--body-sm pt-0.5 text-end text-[#6B7280] cursor-pointer flex flex-row items-center"
                  onClick={() => {
                    setIsTransposed(!isTransposed);
                    setTimeout(() => {
                      document.getElementById("th-0")?.scrollIntoView(false);
                    }, 0);
                  }}
                >
                  <Icon source={Icons.Transpose} />
                  <div className="ml-[4px]">Transpose</div>
                </span>
                {ADD_VALUE_ON ? (
                  <span
                    className="--body-sm pt-0.5 text-end text-[#6B7280] cursor-pointer pl-4 flex flex-row items-center"
                    onClick={e => {
                      if (orgSortedGroups.length < 100) {
                        addSortedGroups(
                          curIndex === -1 || curIndex < lineGroups.length
                            ? sortedGroups.length
                            : Math.ceil(
                                (curIndex + 1 - lineGroups.length) /
                                  groupKeys.length,
                              ),
                        );
                        setFocusEnable(false);
                        setIndex(
                          lineGroups.length +
                            groupKeys.length * sortedGroups.length,
                        );

                        setTimeout(() => {
                          boxChangedFocusMover(
                            [],
                            "center",
                            "instant",
                            lineGroups.length +
                              groupKeys.length * sortedGroups.length,
                          );
                        }, 0);
                      } else {
                        setErrorOn(true);
                        setErrorType(EXCEED_GROUPING_ADD);
                        setReloadFunction(() => () => {
                          setErrorOn(false);
                        });
                      }
                    }}
                  >
                    <Icon source={Icons.Add} />

                    <span className="ml-[4px]">
                      {isTransposed ? "Add column" : "Add row"}
                    </span>
                  </span>
                ) : (
                  <></>
                )}
              </div>
            </div>
            <div className="my-4 --body-sm bg-white rounded-lg w-[calc(100%-1.5rem)]">
              <div className="flex flex-auto p-[16px] overflow-x-auto align-middle scrollbar-track">
                <table className="flex-auto min-w-full border-collapse divide-y divide-gray-200 table-fixed">
                  {!isTransposed && (
                    <thead className="bg-white">
                      <tr className="divide-x divide-gray-100">
                        {groupKeys.map((key, colIdx) => {
                          // const display = (receiptKeys as Keys)[key.split("/")[1]];
                          const display = key.split("/")[1];
                          return (
                            <th
                              key={colIdx}
                              id={`th-${colIdx}`}
                              scope="col"
                              className={classnames({
                                "w-full px-2 py-2 border-black/5 font-normal text-gray-400 break-words whitespace-nowrap text-left":
                                  true,
                              })}
                            >
                              {display}
                            </th>
                          );
                        })}
                      </tr>
                    </thead>
                  )}
                  <tbody className="divide-y divide-gray-100">
                    {sortedGroups.length > 0 && getGroupingTable()}
                  </tbody>
                </table>
                <div className="absolute flex-col right-[44px]">
                  <div className="w-1 h-[34px]"></div>
                  {ADD_VALUE_ON && !isTransposed ? (
                    sortedGroups.map((group, idx) => (
                      <div
                        className={classnames({
                          "flex items-center align-middle h-9": true,
                          invisible: lineGroups.length + idx !== hoverIndex,
                        })}
                        key={"gg del " + idx}
                      >
                        <div
                          className={classnames({
                            "flex h-[28px] w-[28px] border rounded-lg border-gray-200 justify-center align-middle items-center bg-white":
                              true,
                            "opacity-100": isDelBtnHover,
                            "opacity-50": !isDelBtnHover,
                          })}
                          onMouseEnter={() => {
                            handleMouseEnter(idx);
                            setIsDelBtnHover(true);
                          }}
                          onMouseLeave={() => {
                            handleMouseLeave();
                            setIsDelBtnHover(false);
                          }}
                          onClick={e => {
                            delSortedGroups(idx);
                            setHoverIndex(-1);
                            setHoveredBoxIds([]);
                            setIndex(-1);
                            setMessage("Row removed");
                            setToastOpen(true);
                          }}
                          key={"g del " + idx}
                        >
                          <Icon
                            className="py-1 opacity-100"
                            source={Icons.Minus}
                          />
                        </div>
                      </div>
                    ))
                  ) : (
                    <></>
                  )}
                </div>
              </div>
              {ADD_VALUE_ON && sortedGroups.length === 0 ? (
                <div className="flex items-center justify-center w-full pt-4 pb-8 text-gray-300 align-middle">
                  No field
                </div>
              ) : (
                <></>
              )}
            </div>
          </>
        ) : null}
      </div>
    </>
  );
};

export default observer(ParsingResultTable);
