import { useCallback, useEffect, useState } from "react";
import { Blocker } from "../components/Backdrop/Blocker";
import { Button, ButtonStyle } from "../components/Button";
import { Dialog } from "../components/Dialog";

import { observer } from "mobx-react";
import { useSearchParams } from "react-router-dom";
import { keyBoardManage } from "../atom/function/parsingTablekeyBoardManger";
import { Icon, Icons } from "../components/Icon";
import DemoSelectModal from "../components/Modal/DemoSelectModal";
import ErrorModal from "../components/Modal/ErrorModal";
import { Toaster } from "../components/Toast/Toaster";
import { useToast } from "../components/Toast/use-toast";
import { MODEL_FETCH_FAIL } from "../constants/ErrorMessage";
import {
  DEMO_MODE_FEATURE,
  FEATURE,
  ONPREMISE_DEMO_FEATURE,
} from "../constants/FeatureFlag";
import {
  ImageFile,
  Model,
  ResultFile,
  ValidatorFileProvider,
  ValidatorModelsProvider,
} from "../hooks/useFile";
import { ValidatorDetailView } from "../organisms/ValidatorDetailView";
import ValidatorImageView from "../organisms/ValidatorImageView";
import { ImageOacJson } from "../types/demo";
import {
  ExtractorOacJsonResponse,
  ImageExtractorOacJson,
} from "../types/extractorOacResponse";
import { OCROacJsonResponse } from "../types/ocrOacResponse";
import { Ontology } from "../types/ontology";
import { classnames } from "../utils/classnames";
import { imageViewStore } from "../utils/mobx/ImageViewStore";
import { parsingTableStore } from "../utils/mobx/ParsingTableStore";
import { resultInteractionStore } from "../utils/mobx/ResultInteractionStore";
import {
  resultExtractorPreProcessor,
  resultOCRPreProcessor,
} from "../utils/resultPreProcessor";

import InitSelectModal from "../components/Modal/InitSelectModal";
import { ImageLAOacJson, LAOacJsonResponse } from "../types/laOacResponse";
import { ImageOCROacJson } from "../types/ocrOacResponse";
import { endpointStore } from "../utils/mobx/EndpointStore";
import { parsingLAStore } from "../utils/mobx/ParsingLAStore";
import { parsingOCRStore } from "../utils/mobx/ParsingOCRStore";
import { resultLAPreProcessor } from "../utils/resultPreProcessor";
import { ModifyModelName } from "../utils/stringUtil";

type ModelResponse = Model[];

function Home({ isLogin = false, newEndpointType = "extractor" }) {
  // 데모화면에 파일 업로드 시 하나의 파일은 여러 이미지로 구성될 수 있음
  const [infering, setInfering] = useState<boolean>(false);
  const [dialogVisible, setDialogVisible] = useState<boolean>(false);

  const [selectedFileIndex, setSelectedFileIndex] = useState<number>(-1);
  const [selectedFile, setSelectedFile] = useState<ResultFile | null>(null);
  const [page, setPage] = useState<number>(0);

  const [image, setImage] = useState<string>("");
  const [imageScale, setImageScale] = useState<number>(1.0);
  const [numberOfImages, setNumberOfImages] = useState<number>(0);
  const [imageFiles, setImageFiles] = useState<ImageFile[]>([]);

  const [workToolVisible, setWorkToolVisible] = useState<boolean>(false);
  const [selectedModel, setSelectedModel] = useState<number>(0);
  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const [initModalOpen, setInitModalOpen] = useState<boolean>(true);

  const [files, setFiles] = useState<ResultFile[]>([]);
  const [models, setModels] = useState<Model[]>([]);
  const [tenantId, setTenantId] = useState<string>("");

  const [errorType, setErrorType] = useState<number>(1);
  const [errorOpen, setErrorOpen] = useState<boolean>(false);

  const [closeFunction, setCloseFunction] = useState<() => void>(() => {});
  const [reloadFunction, setReloadFunction] = useState<() => void>(() => {});

  const [gotMsg, setGotMsg] = useState<boolean>(false);

  const { setResult, setHoveredBoxIds, setSelectedBoxIds, setFocusIndex } =
    resultInteractionStore;
  const { setResizeBlocker } = imageViewStore;
  const { setontologies, changeExtractorFile: changeExtractorFile } =
    parsingTableStore;

  const { endpointType, setEndpointType, setToken } = endpointStore;
  const { changeOCRFile } = parsingOCRStore;
  const { changeLAFile } = parsingLAStore;

  const { toast } = useToast();
  const [isWide, setIsWide] = useState<boolean>(true);
  const handleWindowResize = useCallback(() => {
    setIsWide(!window.matchMedia(`(max-width: 1200px)`).matches);
  }, [setIsWide]);

  setEndpointType(newEndpointType);

  useEffect(() => {
    setIsWide(!window.matchMedia(`(max-width: 1200px)`).matches);
  }, [setIsWide]);

  useEffect(() => {
    setResizeBlocker(true);
    setTimeout(() => {
      setResizeBlocker(false);
    }, 310);
  }, [setResizeBlocker, workToolVisible]);

  useEffect(() => {
    const mediaQueryList = window.matchMedia(`(max-width: 1200px)`);

    // window resize 시 모바일 여부 상태 설정
    mediaQueryList.addEventListener("change", handleWindowResize);

    return () => {
      mediaQueryList.removeEventListener("change", handleWindowResize);
    };
  }, [handleWindowResize]);

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [searchParams, _] = useSearchParams();

  // validator 시나리오에서만 home를 로드할 때 결과를 조회해서 가져온다.
  useEffect(() => {
    const requestId = searchParams.get("requestId");
    const token = searchParams.get("token");
    const userId = searchParams.get("userId");

    if (FEATURE === DEMO_MODE_FEATURE || FEATURE === ONPREMISE_DEMO_FEATURE) {
      return;
    }

    async function loadResult() {
      try {
        setInitModalOpen(false);
        setInfering(true);
        let url = `${process.env.REACT_APP_BACKEND_URL ? process.env.REACT_APP_BACKEND_URL : ""}/api/result/${requestId}`;
        if (token !== null && token !== undefined) {
          url += `?token=${token}`;
          if (userId !== null) {
            url += `&userId=${userId}`;
          }
        } else if (userId !== null) {
          url += `?userId=${userId}`;
        }

        const response = await fetch(url, {
          method: "GET",
        });
        if (200 <= response.status && response.status < 300) {
          if (endpointType.toLowerCase() === "extractor") {
            const respOac =
              (await response.json()) as unknown as ExtractorOacJsonResponse;
            resultExtractorPreProcessor(
              respOac,
              respOac.metadata.pages &&
                respOac.metadata.pages.length > 0 &&
                respOac.metadata.pages[0].height > 0,
            ).then(() => {
              let modelIndex = models.findIndex(
                model => model.name === respOac.modelVersion,
              );
              if (modelIndex === -1) {
                modelIndex = models.length;
                setModels([
                  ...models,
                  {
                    name: respOac.modelVersion,
                    type: "custom",
                    url: "",
                    serviceName: "document-ai",
                    status: "public",
                  },
                ]);
              }
              setSelectedModel(modelIndex);
              setFiles([
                {
                  title: respOac.filename,
                  result: respOac,
                  modelIndex: modelIndex,
                },
              ]);
              setSelectedFileIndex(0);
              setInfering(false);
              setontologies(respOac.ontologies as unknown as Ontology[]);
            });
          } else if (endpointType.toLowerCase() === "ocr") {
            const respOac =
              (await response.json()) as unknown as OCROacJsonResponse;
            resultOCRPreProcessor(
              respOac,
              respOac.metadata.pages && respOac.metadata.pages[0].height > 0,
            ).then(() => {
              let modelIndex = models.findIndex(
                model => model.name === respOac.modelVersion,
              );
              if (modelIndex === -1) {
                modelIndex = models.length;
                setModels([
                  ...models,
                  {
                    name: respOac.modelVersion,
                    type: "custom",
                    url: "",
                    serviceName: "document-ai",
                    status: "public",
                  },
                ]);
              }
              setSelectedModel(modelIndex);
              setFiles([
                {
                  title: respOac.filename,
                  result: respOac,
                  modelIndex: modelIndex,
                },
              ]);
              setSelectedFileIndex(0);
              setInfering(false);
            });
          } else if (endpointType.toLowerCase() === "layout-analysis") {
            const respOac =
              (await response.json()) as unknown as LAOacJsonResponse;
            resultLAPreProcessor(
              respOac,
              respOac.metadata.pages && respOac.metadata.pages[0].height > 0,
            ).then(() => {
              let modelIndex = models.findIndex(
                model => model.name === respOac.model,
              );
              if (modelIndex === -1) {
                modelIndex = models.length;
                setModels([
                  ...models,
                  {
                    name: respOac.model,
                    type: "custom",
                    url: "",
                    serviceName: "document-ai",
                    status: "public",
                  },
                ]);
              }
              setSelectedModel(modelIndex);
              setFiles([
                {
                  title: respOac.filename,
                  result: respOac,
                  modelIndex: modelIndex,
                },
              ]);
              setSelectedFileIndex(0);
              setInfering(false);
            });
          }
        } else {
          console.error("fetch error:", response.status, response.statusText);
          setErrorType(response.status);
          setReloadFunction(() => () => {
            setErrorOpen(false);
          });
          setErrorOpen(true);
          setInfering(false);
        }
      } catch (err) {
        console.error("err:", err);
        setErrorOpen(true);
        setInfering(false);
      }
    }

    loadResult();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchParams, setFiles]);

  useEffect(() => {
    let url = "";
    if (endpointType.toLowerCase() === "extractor") {
      url = `${process.env.REACT_APP_BACKEND_URL ? process.env.REACT_APP_BACKEND_URL : ""}/api/extractor`;
    } else if (endpointType.toLowerCase() === "ocr") {
      url = `${process.env.REACT_APP_BACKEND_URL ? process.env.REACT_APP_BACKEND_URL : ""}/api/ocr`;
    } else if (endpointType.toLowerCase() === "layout-analysis") {
      url = `${process.env.REACT_APP_BACKEND_URL ? process.env.REACT_APP_BACKEND_URL : ""}/api/layout-analysis`;
    }

    setModels([]);
    async function settingModels() {
      // demo 모드가 아닌 경우 모델 정보를 불러올 필요가 없다.
      if (FEATURE !== ONPREMISE_DEMO_FEATURE) return;
      try {
        const response = await fetch(url, {
          method: "GET",
        });
        if (response.status < 300 && response.status >= 200) {
          const modelsResponse = (await response.json()) as ModelResponse;
          const newModels: Model[] = [];
          modelsResponse.forEach(model => {
            newModels.push({
              name: model.name,
              type: model.type ?? "",
              url: model.url,
              serviceName: "document-ai",
              status: "public",
            });
          });
          newModels.sort((a, b) =>
            ModifyModelName(a.name, "public").localeCompare(
              ModifyModelName(b.name, "public"),
            ),
          );
          setModels(newModels);
        }
      } catch (err) {
        console.error("err:", err);
        setErrorType(MODEL_FETCH_FAIL);
        setErrorOpen(true);
        setCloseFunction(() => () => {
          setErrorOpen(false);
        });
        setReloadFunction(() => () => {
          setErrorOpen(false);
          window.location.reload();
        });
      }
    }
    settingModels();

    window.addEventListener("keydown", keyBoardManage);

    return () => {
      window.removeEventListener("keydown", keyBoardManage);
    };
  }, []);

  useEffect(() => {
    let retryCount = 0;
    const maxRetries = 100;
    const intervalId = setInterval(() => {
      if (retryCount < maxRetries && !gotMsg) {
        window.parent.postMessage("Demo Loaded", "*");
        retryCount++;
      } else {
        clearInterval(intervalId);
      }
    }, 100);

    return () => clearInterval(intervalId);
  }, [gotMsg]);

  useEffect(() => {
    // 페이지 로드 시 초기 상태 설정
    window.history.replaceState({ page: 0 }, "Init modal open", "");

    // popstate 이벤트 리스너 등록
    const handlePopState = (event: PopStateEvent) => {
      if (event.state) {
        setInitModalOpen(true);
      }
    };

    window.addEventListener("popstate", handlePopState);

    // 컴포넌트 언마운트 시 이벤트 리스너 제거
    return () => {
      window.removeEventListener("popstate", handlePopState);
    };
  }, []);

  const handleMessage = (event: MessageEvent) => {
    if (FEATURE === DEMO_MODE_FEATURE) {
      if (typeof event.data === "object") {
        const token = event.data?.token;
        const accessibleModel = event.data?.accessibleModel || [];

        if (token) {
          setToken(token);
        }
        if (accessibleModel) {
          setModels([]);
          const modelsFromConsole = accessibleModel as {
            name: string;
            path: string;
            service: string | undefined;
            modelType: string | undefined;
            status: string | undefined;
          }[];
          if (modelsFromConsole.length > 0) {
            setGotMsg(true);
            modelsFromConsole.forEach(modelFromConsole => {
              setModels(prev => [
                ...prev,
                {
                  name: modelFromConsole.name,
                  type: modelFromConsole.modelType ?? modelFromConsole.path,
                  serviceName: modelFromConsole.service,
                  url: modelFromConsole.name,
                  status: modelFromConsole.status,
                },
              ]);
            });
          }
        }
      }
    }
  };

  useEffect(() => {
    window.addEventListener("message", handleMessage);

    return () => {
      window.removeEventListener("message", handleMessage);
    };
  }, []);

  useEffect(() => {
    if (selectedFileIndex !== -1) {
      setSelectedFile(files[selectedFileIndex]);
      setPage(0);
      setImage(files[selectedFileIndex].result.imageBase64[0]);
      setNumberOfImages(files[selectedFileIndex].result.imageBase64.length);

      if (files[selectedFileIndex].result.imageBase64.length > 30) {
        setNumberOfImages(30);
        toast({
          title: "The file exceeds the page limit.",
          description: "30 pages per single file can be processed.",
        });
      }
      setSelectedModel(files[selectedFileIndex].modelIndex);
      if (endpointType.toLowerCase() === "extractor") {
        setResult(
          (files[selectedFileIndex].result as ImageExtractorOacJson)?.fields,
        );
        changeExtractorFile(
          (files[selectedFileIndex].result as ImageExtractorOacJson)?.fields,
          selectedFileIndex,
        );
      } else if (endpointType.toLowerCase() === "ocr") {
        setResult((files[selectedFileIndex].result as ImageOCROacJson)?.pages);
        changeOCRFile(
          (files[selectedFileIndex].result as ImageOCROacJson)?.pages,
          selectedFileIndex,
        );
      } else if (endpointType.toLowerCase() === "layout-analysis") {
        setResult(
          (files[selectedFileIndex].result as ImageLAOacJson)?.elements,
        );
        changeLAFile(
          (files[selectedFileIndex].result as ImageLAOacJson)?.elements,
          selectedFileIndex,
        );
      }
      setFocusIndex(-1);
    }
  }, [
    files,
    selectedFileIndex,
    selectedModel,
    setFocusIndex,
    setResult,
    toast,
    changeExtractorFile,
  ]);

  // 페이지 변경시 보이는 이미지를 바꾼다.
  useEffect(() => {
    if (selectedFileIndex !== -1) {
      setImage(files[selectedFileIndex]?.result?.imageBase64[page] ?? "");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page]);

  const resetValidator = () => {
    setSelectedFileIndex(-1);
    setSelectedFile(null);
    setPage(0);
    setImage("");
    setImageScale(1.0);
    setImageFiles([]);
    setNumberOfImages(0);
    setHoveredBoxIds([]);
    setSelectedBoxIds([]);
    setWorkToolVisible(false);
    setSelectedModel(0);
    setModalOpen(false);
    setFiles([]);
    window.open("about:blank", "_self");
    window.close();
  };

  return (
    <ValidatorFileProvider
      value={{
        files: files,
        selected: selectedFileIndex,
        imageFiles: imageFiles,
        infering: infering,
        setSelected: setSelectedFileIndex,
        setFiles: setFiles,
        setImageFiles: setImageFiles,
        setInfering: setInfering,
      }}
    >
      <ValidatorModelsProvider
        value={{
          models: models,
          selected: selectedModel,
          setSelected: setSelectedModel,
        }}
      >
        <InitSelectModal
          isOpen={initModalOpen}
          setIsOpen={setInitModalOpen}
          tenantId={tenantId}
          isLogin={isLogin}
          gotMsg={gotMsg}
        />
        <DemoSelectModal
          isOpen={modalOpen}
          setIsOpen={setModalOpen}
          tenantId={tenantId}
        />
        {/* 작업창 부분 */}
        <div className="flex flex-row w-full h-full overflow-auto bg-gray-25">
          {/* 가운데 결과창 부분 */}
          <section
            className={classnames({
              "grid flex-1 grid-cols-1 gap-3 min-w-[26rem]": true,
              "ml-[390px]": workToolVisible && isWide,
              "transition-all duration-300": isWide,
            })}
            onClick={() => {
              setSelectedBoxIds([]);
              setHoveredBoxIds([]);
              setFocusIndex(-1);
            }}
          >
            <div className="flex flex-row">
              <ValidatorDetailView
                resetValidator={resetValidator}
                infering={infering}
                file={selectedFile?.result as ImageOacJson}
                workToolHandler={setWorkToolVisible}
                workToolOn={workToolVisible}
                tenantId={tenantId}
                setInfering={setInfering}
                modalOn={modalOpen}
                isLogin={isLogin}
              />
            </div>
          </section>

          {/* 오른쪽 이미지 부분 */}
          <div className="grid flex-1 gap-2 min-w-[26rem]">
            <ValidatorImageView
              isDRSP={
                models[selectedModel]?.type.toLowerCase() === "drsp" ||
                (selectedFile?.result as ExtractorOacJsonResponse)?.documentType
                  .toLowerCase()
                  .includes("receipt")
              }
              file={selectedFile?.result as ImageOacJson}
              page={page}
              pageSelectHandler={setPage}
              image={image}
              imageScale={imageScale}
              imageScaleChangeHandler={setImageScale}
              numberOfImages={numberOfImages}
              infering={infering}
            />
          </div>
        </div>

        {infering && (
          <Blocker>
            <div className="z-30 w-full h-full">
              <button className="bg-transparent diabled">
                <div className="flex flex-row items-center justify-center gap-4 text-white">
                  <div className="flex w-5 h-5 border-4 border-gray-600 rounded-full animate-spin border-t-grey-300" />
                  <div className="flex"> Loading...</div>
                </div>
              </button>
            </div>
          </Blocker>
        )}

        <ErrorModal
          setOpen={setErrorOpen}
          open={errorOpen}
          errorType={errorType}
          closeFunction={closeFunction}
          reloadFunction={reloadFunction}
        />
        {dialogVisible && (
          <Dialog
            isOpen
            onClose={() => setDialogVisible(false)}
            shouldCloseOnExternalClick={true}
            size="xs"
          >
            <Dialog.Content>
              <div className="flex flex-col items-center text-sm text-gray-500 h-[11rem] justify-center">
                <Icon source={Icons.NoResult} />
              </div>
              <div className="flex flex-col items-center justify-center h-32 gap-4 px-6 py-2">
                <div className="text-neutral-900 text-xl font-semibold font-['Ups sans'] leading-[30px]">
                  Try again with a suitable image that:
                </div>
                <ul className="font-normal leading-snug text-gray-500 list-disc list-inside text-md">
                  <li>Includes readable text</li>
                  <li>Is formatted as png, jpeg, bmp, pdf, or tiff</li>
                  <li>Has a file size under 50MB</li>
                </ul>
              </div>
            </Dialog.Content>
            <Dialog.Footer>
              <Button
                text="OK"
                style={ButtonStyle.BasicDark}
                onClick={() => setDialogVisible(false)}
              />
            </Dialog.Footer>
          </Dialog>
        )}
        <Toaster></Toaster>
      </ValidatorModelsProvider>
    </ValidatorFileProvider>
  );
}

export default observer(Home);
