import {
  ADD_VALUE_ABLE_MASK,
  CONFIDENCE_ADJUST_ON_MASK,
  DOWNLOAD_IMAGE_MASK,
  DOWNLOAD_JSON_MASK,
  EDIT_VISIBLE_ON_MASK,
  FEATURE,
  FILE_UPLOAD_ON_MASK,
  MENU_ON_MASK,
  ONPREMISE_MODE_MASK,
  PREV_NEXT_ON_MASK,
  TABLE_ABLE_MASK,
  TERMINATE_ON_MASK,
} from "./FeatureFlag";

/* 피쳐 플래깅에 따라 관리되는 기능들을 나타내는 파일입니다.
 * 각 기능은 기능_ON_MASK 혹은 기능_ABLE_MASK 로 되어 있습니다.
 * 각 기능 별로 마스킹 변수가 있어 해당 마스크와 환경변수를 통해 입력된 Feature와의 & 연산을 통해 ON/OFF 여부를 결정하게 됩니다.
 */
export const MENU_ON: boolean = (MENU_ON_MASK & FEATURE) !== 0;
export const PREV_NEXT_ON: boolean = (PREV_NEXT_ON_MASK & FEATURE) !== 0;
export const FILE_UPLOAD_ON: boolean = (FILE_UPLOAD_ON_MASK & FEATURE) !== 0;
export const TERMINATE_ON: boolean = (TERMINATE_ON_MASK & FEATURE) !== 0;
export const EDIT_VISIBLE_ON: boolean = (EDIT_VISIBLE_ON_MASK & FEATURE) !== 0;
export const TABLE_ABLE: boolean = (TABLE_ABLE_MASK & FEATURE) !== 0;
export const ADD_VALUE_ON: boolean = (ADD_VALUE_ABLE_MASK & FEATURE) !== 0;
export const DOWNLOAD_IMAGE_ON: boolean = (DOWNLOAD_IMAGE_MASK & FEATURE) !== 0;
export const DOWNLOAD_JSON_ON: boolean = (DOWNLOAD_JSON_MASK & FEATURE) !== 0;
export const CONFIDENCE_ADJUST_ON: boolean =
  (CONFIDENCE_ADJUST_ON_MASK & FEATURE) !== 0;
export const ONPREMISE_MODE_ON: boolean = (ONPREMISE_MODE_MASK & FEATURE) !== 0;
