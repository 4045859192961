import { observable, action, makeObservable } from "mobx"

class ImageViewStore {
  boxRatio: number = 0.4;
  maxRatio: number = 2.25;
  imgWidth: number = 0;
  imgHeight: number = 0;
  svgWidth: number = 0;
  svgHeight: number = 0;
  reset: boolean = false;
  degree: number = 0;
  resizeBlocker: boolean = false;

  constructor() {
    makeObservable(this, {
      boxRatio: observable,
      maxRatio: observable,
      imgWidth: observable,
      imgHeight: observable,
      setImgWidth: observable,
      setImgHeight: observable,
      reset: observable,
      svgWidth: observable,
      svgHeight: observable,
      setSvgHeight: observable,
      resizeBlocker: observable,
      degree: observable,
      setDegree: action,
      setBoxRatio: action,
      setSvgWidth: action,
      setReset: action,
      setResizeBlocker: action,

    })
    this.setReset = this.setReset.bind(this);
    this.setResizeBlocker = this.setResizeBlocker.bind(this);
    this.setBoxRatio = this.setBoxRatio.bind(this);
    this.setMaxRatio = this.setMaxRatio.bind(this);
    this.setImgHeight = this.setImgHeight.bind(this);
    this.setImgWidth = this.setImgWidth.bind(this);
    this.setSvgHeight = this.setSvgHeight.bind(this);
    this.setSvgWidth = this.setSvgWidth.bind(this);
    this.setDegree = this.setDegree.bind(this);
  }
  setResizeBlocker(rb: boolean) {
    this.resizeBlocker = rb;
  }
  setDegree(degree: number) {
    this.degree = degree;
  }
  setReset(reset: boolean) {
    this.reset = reset;
  }
  setSvgHeight(height: number) {
    this.svgHeight = height;
  }
  setSvgWidth(width: number) {
    this.svgWidth = width;
  }
  setImgWidth(width: number) {
    this.imgWidth = width;
  }
  setImgHeight(height: number) {
    this.imgHeight = height;
  }
  setMaxRatio(ratio: number) {
    this.maxRatio = ratio;
  }
  setBoxRatio(ratio: number) {
    this.boxRatio = ratio;
  }
}

export const imageViewStore = new ImageViewStore();