import type { SVGProps } from "react";

export function ExtractorValidatorLogo(props: SVGProps<SVGSVGElement>) {
  return (
<svg width="177" height="30" viewBox="0 0 177 30" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M17.0022 6.91842L4 8.98288V7.76797L17.0022 4V6.91842Z" fill="black"/>
  <path d="M17.0022 11.654L4 12.1382V10.9233L17.0022 8.7356V11.654Z" fill="black"/>
  <path d="M17.0022 22.9501L4 20.8857V22.1006L17.0022 25.8685V22.9501Z" fill="black"/>
  <path d="M17.0022 18.2128L4 17.7242V18.9435L17.0022 21.1312V18.2128Z" fill="black"/>
  <path d="M17.0022 16.3918L4 15.5422V14.3273L17.0022 13.4778V16.3918Z" fill="black"/>
  <path d="M17.0022 6.91842L27.7363 8.74078L27.8961 8.16854L29.9998 8.37543V7.76797L17.0022 4V6.91842Z" fill="black"/>
  <path d="M17.0022 11.654L26.6607 12.0194L26.8637 11.4383L29.9998 11.5308V10.9233L17.0022 8.7356V11.654Z" fill="black"/>
  <path d="M17.0022 22.9501L29.9998 20.8857V21.4931L19.4685 24.3631L19.2223 25.1951L17.0022 25.8685V22.9501Z" fill="black"/>
  <path d="M17.0022 18.2128L29.9998 17.7242V18.3317L24.5398 18.8995L24.2633 19.8679L17.0022 21.1312V18.2128Z" fill="black"/>
  <path d="M17.0022 16.3918L28.8076 15.5422L28.9631 15.0052L29.9998 14.9348V14.3273L17.0022 13.4778V16.3918Z" fill="black"/>
  <path d="M170.541 11.3942C171.165 11.3942 171.813 11.5862 172.101 11.7302V13.8182C171.741 13.6742 171.069 13.4582 170.373 13.4582C169.005 13.4582 167.493 14.5862 167.493 17.5382V23.0582H165.093V11.6582H166.965L167.229 13.8182C167.805 12.4742 168.813 11.3942 170.541 11.3942Z" fill="#1F1F1F"/>
  <path d="M156.826 23.4182C153.418 23.4182 150.922 20.8742 150.922 17.3462C150.922 13.9622 153.418 11.3222 156.826 11.3222C160.186 11.3222 162.706 13.9622 162.706 17.3462C162.706 20.8742 160.162 23.4182 156.826 23.4182ZM156.826 21.3062C159.034 21.3062 160.258 19.4822 160.258 17.3462C160.258 15.2342 159.034 13.4342 156.826 13.4342C154.618 13.4342 153.37 15.2342 153.37 17.3462C153.37 19.4822 154.642 21.3062 156.826 21.3062Z" fill="#1F1F1F"/>
  <path d="M149.334 11.6582V13.6262H145.95V18.7862C145.95 20.4422 146.238 21.3062 147.438 21.3062C148.11 21.3062 148.758 21.0182 149.118 20.8262H149.166V22.6262C148.782 22.9382 147.822 23.3462 146.67 23.3462C143.55 23.3462 143.55 20.5142 143.55 19.7462V13.6262H141.606V11.6582H143.55V9.5222L145.902 7.69819H145.95V11.6582H149.334Z" fill="#1F1F1F"/>
  <path d="M135.159 11.3222C137.535 11.3222 139.959 12.3062 139.959 15.2582V23.0582H138.111L137.895 21.3062C137.271 22.3142 136.071 23.3942 134.007 23.3942C131.847 23.3942 129.831 22.1462 129.831 19.8422C129.831 17.1782 132.231 16.3382 134.871 16.3382C136.623 16.3382 137.583 16.2422 137.583 15.1142C137.583 14.0822 136.599 13.4582 134.967 13.4582C132.999 13.4582 131.487 14.3222 130.647 15.1142H130.599L130.671 12.9542C131.607 12.1622 133.455 11.3222 135.159 11.3222ZM134.535 21.4742C136.431 21.4742 137.751 20.1542 137.751 18.4742V17.2742C137.223 17.9702 136.143 18.1142 134.727 18.1142C133.239 18.1142 132.207 18.6422 132.207 19.7942C132.207 20.9222 133.383 21.4742 134.535 21.4742Z" fill="#1F1F1F"/>
  <path d="M124.956 13.2182V7.69819H127.356V23.0582H125.508L125.268 21.0662C124.548 22.3382 123.06 23.3702 121.14 23.3702C118.116 23.3702 115.836 20.8982 115.836 17.4902C115.836 13.9142 118.14 11.3462 121.212 11.3462C122.916 11.3462 124.212 12.1382 124.956 13.2182ZM121.716 21.3062C123.852 21.3062 125.052 19.4342 125.052 17.3942C125.052 15.3062 123.828 13.4342 121.692 13.4342C119.508 13.4342 118.284 15.2342 118.284 17.3462C118.284 19.4822 119.508 21.3062 121.716 21.3062Z" fill="#1F1F1F"/>
  <path d="M112.27 9.37819C111.358 9.37819 110.686 8.7302 110.686 7.9142C110.686 7.0982 111.358 6.4502 112.27 6.4502C113.206 6.4502 113.878 7.0982 113.878 7.9142C113.878 8.7302 113.206 9.37819 112.27 9.37819ZM113.47 23.0582H111.07V11.6582H113.47V23.0582Z" fill="#1F1F1F"/>
  <path d="M108.079 23.0582H105.679V7.69819H108.079V23.0582Z" fill="#1F1F1F"/>
  <path d="M98.1041 11.3222C100.48 11.3222 102.904 12.3062 102.904 15.2582V23.0582H101.056L100.84 21.3062C100.216 22.3142 99.0161 23.3942 96.9521 23.3942C94.7921 23.3942 92.7761 22.1462 92.7761 19.8422C92.7761 17.1782 95.1761 16.3382 97.8161 16.3382C99.5681 16.3382 100.528 16.2422 100.528 15.1142C100.528 14.0822 99.5441 13.4582 97.9121 13.4582C95.9441 13.4582 94.4321 14.3222 93.5921 15.1142H93.5441L93.6161 12.9542C94.5521 12.1622 96.4001 11.3222 98.1041 11.3222ZM97.4801 21.4742C99.3761 21.4742 100.696 20.1542 100.696 18.4742V17.2742C100.168 17.9702 99.0881 18.1142 97.6721 18.1142C96.1841 18.1142 95.1521 18.6422 95.1521 19.7942C95.1521 20.9222 96.3281 21.4742 97.4801 21.4742Z" fill="#1F1F1F"/>
  <path d="M85.625 23.2262H85.049L77.945 7.7462V7.69819H80.777C82.337 11.2982 83.873 14.9222 85.337 18.5222H85.385C86.825 14.9462 88.361 11.2742 89.921 7.69819H92.705V7.7462L85.625 23.2262Z" fill="#1F1F1F"/>
  <path d="M63.9335 16.5062V20.8742H70.7975V23.0582H61.4375V7.69819H70.7255V9.8822H63.9335V14.3222H70.2695V16.5062H63.9335Z" fill="#1F1F1F"/>
  <path d="M57.746 23.0582H55.25V7.69819H57.746V23.0582Z" fill="#1F1F1F"/>
  <path d="M43.496 23.0582H41V7.69819H43.496V23.0582ZM52.856 7.7462L46.448 14.9222L53.216 23.0102V23.0582H50.144L43.52 15.0422L50 7.69819H52.856V7.7462Z" fill="#1F1F1F"/>
</svg>


  );
}
