import { observer } from "mobx-react-lite";
import { useContext, useRef, useState } from "react";
import { useSearchParams } from "react-router-dom";
import ConfidenceSetting from "../../atom/ConfidenceSetting";
import { MODEL_FETCH_FAIL } from "../../constants/ErrorMessage";
import {
  DOWNLOAD_IMAGE_ON,
  DOWNLOAD_JSON_ON,
  TERMINATE_ON,
} from "../../constants/Features";
import {
  ResultFile,
  ValidatorFileContext,
  ValidatorModelsContext,
} from "../../hooks/useFile";
import { InferenceResult, OacJsonResponse } from "../../types/demo";
import { ExtractorOacJsonResponse } from "../../types/extractorOacResponse";
import { Group, Line } from "../../types/extractorResult";
import { LAElement } from "../../types/laOacResponse";
import { Line as OCRLine } from "../../types/ocrOacResponse";
import { classnames } from "../../utils/classnames";
import { downloadFile } from "../../utils/downloadFile";
import { endpointStore } from "../../utils/mobx/EndpointStore";
import { parsingLAStore } from "../../utils/mobx/ParsingLAStore";
import { parsingOCRStore } from "../../utils/mobx/ParsingOCRStore";
import { parsingTableStore } from "../../utils/mobx/ParsingTableStore";
import { Icon, Icons } from "../Icon";
import { useToast } from "../Toast/use-toast";

interface DownloadParams {
  json: OacJsonResponse | null;
  requestId: string | null;
  token?: string | null;
  fileNameWithoutExtenstion: string;
}

interface SubmitResultParams {
  oac: ExtractorOacJsonResponse;
  requestId: string | null;
  token: string | null;
  userId: string | null;
}

interface SubmitResult {
  code: number;
  message: string;
  ocrExtractResult: SubmitResultHMM | null;
}

interface SubmitResultHMM {
  bookingNumber: string;
  certificateNumber: string;
  emergencyTelephoneNumber: string;
  emergencyContactName: string;
  containers: SubmitResultHMMContainers[];
}

interface SubmitResultHMMContainers {
  containerNumber: string;
  unNumber: string;
  properShippingName: string;
  technicalName: string;
  imdgClass: string;
  subClass: string;
  packageGroup: string;
  marinePollutant: string;
  flashPoint: string;
  limitedQuantity: string;
  emsNumber: string;
  outerQuantity: string;
  outerPackaging: string;
  innerQuantity: string;
  innerPackaging: string;
  grossWeight: string;
  netWeight: string;
}

async function download({
  json,
  requestId,
  token,
  fileNameWithoutExtenstion,
}: DownloadParams): Promise<void> {
  if (DOWNLOAD_IMAGE_ON) {
    let downloadUrl = `${process.env.REACT_APP_BACKEND_URL ? process.env.REACT_APP_BACKEND_URL : ""}/api/result/${requestId}/image?token=${token}`
    try {
      const response = await fetch(downloadUrl);
      if (response.status !== 200) {
        alert("Image download failed");
        return;
      }
      const blob = await response.blob();
      const contentType = response.headers.get('content-type');
      let fileExtension = '';
      // MIME 타입에 따른 파일 확장자 설정
      if (contentType) {
        if (contentType.includes('image/jpeg')) {
          fileExtension = 'jpg';
        } else if (contentType.includes('image/png')) {
          fileExtension = 'png';
        } else if (contentType.includes('application/pdf')) {
          fileExtension = 'pdf';
        } else if (contentType.includes('application/zip')) {
          fileExtension = 'zip';
        } else {
          console.warn('Unknown content type:', contentType);
          fileExtension = 'bin'; // 기본값으로 이진 파일로 설정
        }
      }

      let filename = response?.headers.get('Content-Disposition')?.split('filename=')[1];
      if (!filename) {
        filename = fileNameWithoutExtenstion;
      }

      // 확장자가 이미 파일명에 포함되어 있는지 확인하고, 없으면 추가
      if (!filename.toLowerCase().endsWith(`.${fileExtension}`)) {
        filename += `.${fileExtension}`;
      }

      downloadFile({ blob, fileName: filename });
    } catch (error) {
      console.error("Image download failed", error);
    }
  } else if (DOWNLOAD_JSON_ON) {
    const jsonString = JSON.stringify(json, null, 0);
    const blob = new Blob([jsonString], { type: "application/json" });
    downloadFile({ blob, fileName: `${fileNameWithoutExtenstion}.json` });
  }
}

async function submitResult({
  oac,
  requestId,
  token,
  userId,
}: SubmitResultParams): Promise<SubmitResult> {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  if (requestId === null) {
    alert("invalid request id");
  }
  const submitUrl = `${process.env.REACT_APP_BACKEND_URL ? process.env.REACT_APP_BACKEND_URL : ""}/api/result/${requestId}?token=${token}`;
  if (userId) {
    submitUrl.concat(`&userId=${userId}`);
  }
  const submitResponse: SubmitResult = {
    code: 0,
    message: "",
    ocrExtractResult: null,
  };
  const response = await fetch(submitUrl, {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(oac),
  }).then(async resp => {
    const resultResponse = (await resp.json()) as SubmitResult;
    if (200 <= resp.status && resp.status < 300) {
      submitResponse.code = resultResponse.code;
      submitResponse.message = resultResponse.message;
      submitResponse.ocrExtractResult = resultResponse.ocrExtractResult;
    } else {
      submitResponse.code = resultResponse.code;
      submitResponse.message = resultResponse.message;
      submitResponse.ocrExtractResult = null;
    }
  });
  return submitResponse;
}

type changedResult = Group | Line;

const makeExtractorJson = (groups: Group[], lines: Line[]): InferenceResult => {
  const arr: changedResult[] = [];
  let groupIdx = 0;
  let lineIdx = 0;

  while (groupIdx < groups.length && lineIdx < lines.length) {
    if (groups[groupIdx].id > lines[lineIdx].id) {
      arr.push(lines[lineIdx]);
      lineIdx += 1;
    } else {
      arr.push(groups[groupIdx]);
      groupIdx += 1;
    }
  }
  while (lineIdx < lines.length) {
    arr.push(lines[lineIdx]);
    lineIdx += 1;
  }

  while (groupIdx < groups.length) {
    arr.push(groups[groupIdx]);
    groupIdx += 1;
  }
  return JSON.parse(JSON.stringify(arr)) as InferenceResult;
};

const makeOCRJson = (ocrLines: OCRLine[]) => {
  return JSON.parse(JSON.stringify(ocrLines)) as InferenceResult;
};

const makeLAJson = (laElements: LAElement[]) => {
  return JSON.parse(JSON.stringify(laElements)) as InferenceResult;
};

const makeResultToExtractorOACFormat = (
  data: ResultFile,
  res: any,
): ExtractorOacJsonResponse => {
  const result = data.result as ExtractorOacJsonResponse;
  const resultOacFormat = {
    code: result.code,
    filename: result.filename,
    apiVersion: result.apiVersion,
    confidence: result.confidence,
    documentType: result.documentType,
    mimeType: result.mimeType,
    modelVersion: result.modelVersion,
    stored: result.stored,
    metadata: result.metadata,
    imageBase64: [],
    fields: res,
    ontologies: [],
  };
  return resultOacFormat;
};

interface FileToolbarProps {
  resetValidator: () => void;
  files: ResultFile[];
  selectedFileIndex: number;
  setSelectedFileIndex: (idx: number) => void;
  setInfering: (infer: boolean) => void;
}

export interface MenuItem {
  title: string;
  description: string;
}

export interface HeaderProps {
  resetValidator: () => void;
  workToolHandler: (workToolOnoff: boolean) => void;
  workToolOn: boolean;
  tenantId: string;
  setInfering: (infering: boolean) => void;
  infering: boolean;
  modalOn: boolean;
}

const DetailFooter = ({
  resetValidator,
  workToolHandler,
  workToolOn,
  tenantId,
  modalOn,
}: HeaderProps) => {
  const {
    naController,
    toggleOn,
    setConfidenceThreshold,
    confidenceThreshold,
    confidenceOn,
    setConfidenceOn,
    lineGroups,
    sortedGroups,
  } = parsingTableStore;
  const changed = parsingTableStore.getChanged;
  const [powerOffHover, setPowerOffHover] = useState<boolean>(false);
  const { toast } = useToast();

  const { endpointType } = endpointStore;
  const { ocrLines } = parsingOCRStore;
  const { laElements } = parsingLAStore;

  const [errorOn, setErronOn] = useState<boolean>(false);
  const { models, selected } = useContext(ValidatorModelsContext);
  const [errorType, setErrorType] = useState<number>(1);
  const {
    files,
    selected: fileSelected,
    setSelected: setFileSelected,
    setFiles,
    setImageFiles,
    setInfering,
  } = useContext(ValidatorFileContext);
  const [isHover, setIsHover] = useState<boolean>(false);
  const [naHover, setNaHover] = useState<boolean>(false);
  const [closeFunction, setCloseFunction] = useState<() => void>(() => {});
  const [reloadFunction, setReloadFunction] = useState<() => void>(() => {});
  const [questionfHover, setQuestionHover] = useState<boolean>(false);
  const ref = useRef<HTMLDivElement>(null);
  const [searchParams, _] = useSearchParams();
  const requestId = searchParams.get("requestId");
  const token = searchParams.get("token");
  const userId = searchParams.get("userId");

  const [modalOpen, setModalOpen] = useState<boolean>(false);

  const handleFinishButtonClick = async () => {
    if (TERMINATE_ON) {
      resetValidator();
    } else {
      //isComposing중일때는 value가 업데이트가 되지 않아 수정후 바로 전송을 누르면 반영이 되지 않는다. 0.5초정도 딜레이를 준 후 전송한다.
      setInfering(true);
      setTimeout(async () => {
        const res = await submitResult({
          oac: makeResultToExtractorOACFormat(
            files[0],
            makeExtractorJson(sortedGroups, lineGroups),
          ),
          requestId: requestId,
          token: token,
          userId: userId,
        });
        if (res) {
          if (window.opener && window.opener !== window) {
            const message = {
              result: res,
            };
            window.opener.postMessage(message, "*");
  
            // Close the popup window
            window.close();
          } else if (window.parent && window.parent !== window) {
            const message = {
              result: res,
            };
            window.parent.postMessage(message, "*");
          }
        }
        toast({
          title: res.code.toString(),
          description: res.message,
        });
        setInfering(false);
      }, 500);
      // submitResult에서 에러가 발생한 경우, 에러 메세지를 띄워준다.
    }
  };

  const handleDemoModal = () => {
    if (models.length === 0 && !modalOpen) {
      setErrorType(MODEL_FETCH_FAIL);
      setErronOn(true);
      setCloseFunction(() => () => {
        setErronOn(false);
      });
      setReloadFunction(() => () => {
        setErronOn(false);
        window.location.reload();
      });
    } else {
      setModalOpen(!modalOpen);
    }
  };

  return (
    <>
      <div className="h-[4rem] bg-none rounded-t flex flex-row flex-1 justify-between border-t border-gray-100 items-center px-4 align-middle">
        {/*
        모델 선택 및 파일 이름 표시
      */}

        <div className="flex flex-row gap-2">
          {endpointType.toLowerCase() != "layout-analysis" ? (
            <div>
              <div
                className={classnames({
                  "flex flex-row justify-between gap-1 px-[10px] z-10 py-[6px] text-[14px] items-center":
                    true,
                  "cursor-pointer": true,
                })}
                ref={ref}
                onClick={e => {
                  setConfidenceOn(!confidenceOn);
                }}
              >
                <span>Confidence score</span>
                <span className="text-[#F85F53] mx-2">
                  {Math.floor(confidenceThreshold * 100)}
                </span>
                <span
                  className={classnames({
                    "text-gray-700 hover:opacity-70 mt-0.5": files.length > 0,
                  })}
                >
                  {confidenceOn ? (
                    <Icon source={Icons.ChevronDown} />
                  ) : (
                    <Icon source={Icons.ChevronUp} />
                  )}
                </span>
              </div>
            </div>
          ) : (
            <div></div>
          )}

          <div
            className={classnames({
              "flex flex-row h-9 z-10 gap-1.5 ml-3 py-0 --heading-sm rounded-lg border border-gray-100 px-2 align-middle items-center hover:bg-[#E5E7EB]":
                true,
              "cursor-pointer": true,
            })}
            onClick={() => {
              // let outputJson: InferenceResult = [];
              // if (endpointType.toLowerCase() === "extractor") {
              //   outputJson = makeExtractorJson(sortedGroups, lineGroups);
              // } else if (endpointType.toLowerCase() === "ocr") {
              //   outputJson = makeOCRJson(ocrLines);
              // } else if (endpointType.toLowerCase() === "layout-analysis") {
              //   outputJson = makeLAJson(laElements);
              // }
              let outputJson = files[fileSelected].result as OacJsonResponse;
              outputJson.imageBase64 = [];

              download({
                json: outputJson,
                requestId: requestId,
                token: token,
                fileNameWithoutExtenstion: "try_upstage_document_ai",
              });
            }}
          >
            <div>
              <Icon source={Icons.Download} />
            </div>
            <div
              className={classnames({
                "--body-sm": true,
              })}
            >
              Download
            </div>
          </div>
        </div>

        <div className="flex flex-row">
          <span
            className={classnames({
              "flex items-center justify-center --body-sm": true,
              " text-green-400": changed > 0,
              " text-grey-400": changed === 0,
            })}
          >
            {changed > 0 ? `${changed} Edited ` : "No field edited"}
          </span>
          <div
            className="flex ml-4"
            onMouseEnter={() => setPowerOffHover(true)}
            onMouseLeave={() => setPowerOffHover(false)}
          >
            <button
              className={classnames({
                "select-none flex justify-center w-fit place-items-center space-x-2 --body-base":
                  true,
                "rounded-lg px-3 py-2 w-full": true,
                "h-9 min-w-9": true,
                "text-white bg-black": true,
              })}
              onClick={handleFinishButtonClick}
            >
              {!powerOffHover ? (
                <svg
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M1.875 10C1.875 5.51269 5.51269 1.875 10 1.875C14.4873 1.875 18.125 5.51269 18.125 10C18.125 14.4873 14.4873 18.125 10 18.125C5.51269 18.125 1.875 14.4873 1.875 10ZM13.0086 8.48827C13.2092 8.20739 13.1442 7.81705 12.8633 7.61642C12.5824 7.41579 12.192 7.48084 11.9914 7.76173L9.29525 11.5364L7.94194 10.1831C7.69786 9.93898 7.30214 9.93898 7.05806 10.1831C6.81398 10.4271 6.81398 10.8229 7.05806 11.0669L8.93306 12.9419C9.06297 13.0719 9.24346 13.138 9.42655 13.1229C9.60964 13.1077 9.7768 13.0128 9.88358 12.8633L13.0086 8.48827Z"
                    fill="white"
                  />
                </svg>
              ) : (
                <svg
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M1.875 10C1.875 5.51269 5.51269 1.875 10 1.875C14.4873 1.875 18.125 5.51269 18.125 10C18.125 14.4873 14.4873 18.125 10 18.125C5.51269 18.125 1.875 14.4873 1.875 10ZM13.0086 8.48827C13.2092 8.20739 13.1442 7.81705 12.8633 7.61642C12.5824 7.41579 12.192 7.48084 11.9914 7.76173L9.29525 11.5364L7.94194 10.1831C7.69786 9.93898 7.30214 9.93898 7.05806 10.1831C6.81398 10.4271 6.81398 10.8229 7.05806 11.0669L8.93306 12.9419C9.06297 13.0719 9.24346 13.138 9.42655 13.1229C9.60964 13.1077 9.7768 13.0128 9.88358 12.8633L13.0086 8.48827Z"
                    fill="white"
                  />
                </svg>
              )}
              <span className="whitespace-nowrap">
                {TERMINATE_ON ? "Quit" : "Submit"}
              </span>
            </button>

            {/* power 버튼 호버시 안내문 */}
            {!TERMINATE_ON && (
              <div
                className={classnames({
                  "absolute bottom-[86px] right-[10px] transform translate-y-6 z-20 px-2 py-1.5 bg-gray-800 text-gray-100 text-[14px] rounded-[4px] tracking-wide whitespace-nowrap opacity-90":
                    true,
                  invisible: !powerOffHover,
                })}
              >
                Submit changes and quit the validator.
              </div>
            )}
          </div>
        </div>

        {/* <div className="font-normal leading-tight text-black text-opacity-50">
        {menuItems.find(menuItem => menuItem.id === selectedMenuId)
          ?.description || ""}
      </div> */}
      </div>

      <div
        className={classnames({
          "absolute bottom-12 left-5 w-[304px] h-[208px]": true,
          "z-[1]": confidenceOn,
          "z-[-1]": !confidenceOn,
        })}
      >
        {confidenceOn ? (
          <ConfidenceSetting
            upperRef={ref}
            setQuestionHover={setQuestionHover}
          />
        ) : (
          <></>
        )}
        {/*  power 버튼 호버시 안내문 */}
        {
          <div
            className={classnames({
              "float absolute top-[-86px] right-[-88px] px-2 py-1.5 gap-y-2 bg-gray-800 text-gray-100 --body-sm rounded-lg w-[396px] h-min tracking-wide opacity-90":
                true,
              invisible: !questionfHover,
            })}
          >
            <div className="pb-2">
              The AI model inferences results with a confidence score from 0 to
              100, where high scores indicate a lower chance of incorrect
              values. Red text indicates a confidence score lower than the
              configured threshold, requiring attention.
            </div>
          </div>
        }
      </div>
    </>
  );
};

export default observer(DetailFooter);
