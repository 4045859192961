import { Slider } from "./Slider";
import { parsingTableStore } from "../utils/mobx/ParsingTableStore";


// todo: Tailwind UI를 통해서 가지고 오면서 있는 친구들. 추후 상황에 맞게 수정예정
function parseTime(seconds: number) {
  let hours = Math.floor(seconds / 3600);
  let minutes = Math.floor((seconds - hours * 3600) / 60);
  seconds = seconds - hours * 3600 - minutes * 60;
  return [hours, minutes, seconds];
}
function formatHumanTime(seconds: number) {
  let [h, m, s] = parseTime(seconds);
  return `${h} hour${h === 1 ? "" : "s"}, ${m} minute${m === 1 ? "" : "s"
    }, ${s} second${s === 1 ? "" : "s"}`;
}

export function ConfidenceToolbar(): JSX.Element {
  const { confidenceThreshold, setConfidenceThreshold } = parsingTableStore;
  return (
    <div className="flex items-center w-full h-full gap-3">
      <span className="flex items-center justify-center w-full">
        <Slider
          label="confidence"
          maxValue={100}
          step={1}
          value={[confidenceThreshold * 100]}
          onChange={([value]) => {
            setConfidenceThreshold(value / 100);
          }}
          onChangeEnd={() => { }}
          numberFormatter={{ format: formatHumanTime } as Intl.NumberFormat}
          onChangeStart={() => { }}
        />
      </span>
    </div>
  );
}
