import type { SVGProps } from "react";

export function CursorBar(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="68"
      height="8"
      viewBox="0 0 68 8"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0 4C0 1.79086 1.79086 0 4 0H66.5C67.0523 0 67.5 0.447715 67.5 1V7C67.5 7.55228 67.0523 8 66.5 8H4C1.79086 8 0 6.20914 0 4Z"
        fill="white"
      />
    </svg>
  );
}
