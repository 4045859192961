import { observable, action, makeObservable } from "mobx"

class ResultToastStore {
  toastOpen: boolean = false;
  message: string = "";
  constructor() {
    makeObservable(this, {
      toastOpen: observable,
      message: observable,
      setMessage: action,
      setToastOpen: action,
    })
    this.setToastOpen = this.setToastOpen.bind(this);
    this.setMessage = this.setMessage.bind(this);
  }
  setMessage(msg: string) {
    this.message = msg;
  }
  setToastOpen(open: boolean) {
    this.toastOpen = open;
  }
}

export const resultToastStore = new ResultToastStore();