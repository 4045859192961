import type { SVGProps } from "react";

export function Plus(props: SVGProps<SVGSVGElement>) {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M8.59922 3.79995C8.59922 3.46858 8.33059 3.19995 7.99922 3.19995C7.66785 3.19995 7.39922 3.46858 7.39922 3.79995V7.39995H3.79922C3.46785 7.39995 3.19922 7.66858 3.19922 7.99995C3.19922 8.33132 3.46785 8.59995 3.79922 8.59995L7.39922 8.59995V12.2C7.39922 12.5313 7.66785 12.8 7.99922 12.8C8.33059 12.8 8.59922 12.5313 8.59922 12.2V8.59995L12.1992 8.59995C12.5306 8.59995 12.7992 8.33132 12.7992 7.99995C12.7992 7.66858 12.5306 7.39995 12.1992 7.39995H8.59922V3.79995Z" fill="#0F172A" />
    </svg>

  );
}
