import { parsingTableStore } from "./mobx/ParsingTableStore";

export const boxChangedFocusMover = (
  focusRowIds: string[],
  blockType: string,
  behavior: string = "smooth",
  tdId: number = NaN,
) => {
  const {
    filteredLineGroups: lineGroups,
    filteredSortedGroups: orgSortedGroups,
  } = parsingTableStore;
  if (!isNaN(tdId)) {
    document.getElementById(`td-${tdId}`)?.scrollIntoView({
      behavior: behavior as ScrollBehavior,
      block: blockType as ScrollLogicalPosition,
    });
  } else {
    if (
      lineGroups.find(lineGroup =>
        focusRowIds.includes(String(lineGroup.id)),
      ) ||
      orgSortedGroups.find(group =>
        group.properties.find(property =>
          focusRowIds.includes(String(property.id)),
        ),
      )
    ) {
      const firstGroupId =
        lineGroups.find(lineGroup => focusRowIds.includes(String(lineGroup.id)))
          ?.id ??
        orgSortedGroups.find(group =>
          group.properties.find(property =>
            focusRowIds.includes(String(property.id)),
          ),
        )?.id;
      document.getElementById(`row-${firstGroupId}`)?.scrollIntoView({
        behavior: behavior as ScrollBehavior,
        block: blockType as ScrollLogicalPosition,
      });
    }
  }
};
