import type { SVGProps } from "react";

export function PlusCircle(props: SVGProps<SVGSVGElement>) {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d="M8.00005 15.2C11.9765 15.2 15.2 11.9765 15.2 8.00005C15.2 4.0236 11.9765 0.800049 8.00005 0.800049C4.0236 0.800049 0.800049 4.0236 0.800049 8.00005C0.800049 11.9765 4.0236 15.2 8.00005 15.2ZM8.67505 5.07505C8.67505 4.70226 8.37284 4.40005 8.00005 4.40005C7.62726 4.40005 7.32505 4.70226 7.32505 5.07505V7.32505H5.07505C4.70226 7.32505 4.40005 7.62726 4.40005 8.00005C4.40005 8.37284 4.70226 8.67505 5.07505 8.67505H7.32505V10.925C7.32505 11.2978 7.62726 11.6 8.00005 11.6C8.37284 11.6 8.67505 11.2978 8.67505 10.925V8.67505H10.925C11.2978 8.67505 11.6 8.37284 11.6 8.00005C11.6 7.62726 11.2978 7.32505 10.925 7.32505H8.67505V5.07505Z" fill="#1F1F1F" />
    </svg>
  );
}

