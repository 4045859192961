import { TABLE_ABLE } from "../../constants/Features";
import { parsingTableStore } from "../../utils/mobx/ParsingTableStore";
import { resultInteractionStore } from "../../utils/mobx/ResultInteractionStore";

/*
 * KeyBoard Event를 핸들링 하기 위한 파일입니다
 */

const isInsideInput = (target: Node): boolean => {
  if (!target) return false;
  if (target.nodeName === "INPUT") return true;
  return isInsideInput(target.parentNode!);
};

// 키보드 이벤트를 바탕으로 Focus(검정색)을 이동시키는 함수인데 인풋이 활성화 되었을때 케이스.
export const handleKeyPress = (
  event:
    | React.KeyboardEvent<HTMLInputElement>
    | React.KeyboardEvent<HTMLTextAreaElement>,
  index: number,
  tdRef: React.MutableRefObject<HTMLTableCellElement[]>,
  inputRefs: React.MutableRefObject<(HTMLInputElement | HTMLTextAreaElement)[]>,
) => {
  const {
    setAdditionalFocusIndexes,
    setSelectedBoxIds,
    setFocusIndex: setIndex,
  } = resultInteractionStore;
  const { filteredLineGroups, groupKeys, redo, undo, lineModalOn } =
    parsingTableStore;
  if (event.nativeEvent.isComposing || lineModalOn) return;
  let dt = index >= filteredLineGroups.length ? groupKeys.length : 1; //아래 키나 엔터를 눌렀을때 이동해야하는 t의 변화량
  if (event.key === "Escape") {
    (document.activeElement as HTMLInputElement | HTMLTextAreaElement).blur();
    setSelectedBoxIds([]);
    setIndex(-1);
    setAdditionalFocusIndexes([]);
    event.stopPropagation();
    event.preventDefault();
  }
  if (event.key === "Enter" || event.key === "ArrowDown") {
    (document.activeElement as HTMLInputElement | HTMLTextAreaElement).blur();
    if (inputRefs.current[index + dt]) {
      setIndex(index + dt);
      tdRef.current[index + dt].click();
      event.stopPropagation();
      event.preventDefault();
    }
  }
  if (event.key === "ArrowUp") {
    (document.activeElement as HTMLInputElement | HTMLTextAreaElement).blur();
    if (
      index - groupKeys.length < filteredLineGroups.length &&
      index >= filteredLineGroups.length
    ) {
      tdRef.current[filteredLineGroups.length - 1].click();
      setIndex(filteredLineGroups.length - 1);
      event.stopPropagation();
      event.preventDefault();
    } else {
      tdRef.current[Math.max(index - dt, 0)].click();
      setIndex(Math.max(index - dt, 0));
      event.stopPropagation();
      event.preventDefault();
    }
  }

  if (event.shiftKey && event.key === "Tab") {
    (document.activeElement as HTMLInputElement | HTMLTextAreaElement).blur();
    if (inputRefs.current[index - 1]) {
      tdRef.current[index - 1].click();
      setIndex(index - 1);
      event.stopPropagation();
      event.preventDefault();
    }
  } else if (event.key === "Tab") {
    if (inputRefs.current[index] && index >= filteredLineGroups.length) {
      (document.activeElement as HTMLInputElement | HTMLTextAreaElement).blur();
      tdRef.current[index].click();
      setIndex(index);
    } else if (index < filteredLineGroups.length) {
      (document.activeElement as HTMLInputElement | HTMLTextAreaElement).blur();
      tdRef.current[index + 1].click();
      setIndex(index + 1);
    }
  }
  if (event.ctrlKey || event.metaKey) {
    if (event.shiftKey && event.key === "z") {
      redo();
      event.preventDefault(); // Enter 키의 기본 동작 중지
    } else if (event.key === "z") {
      undo();
      event.preventDefault(); // Enter 키의 기본 동작 중지
    }
  }
};

export const keyBoardManage = (event: KeyboardEvent) => {
  const {
    filteredLineGroups,
    groupKeys,
    tdRef,
    inputRefs,
    tdClick,
    tdFocus,
    inputFocus,
    inputValueUpdate: inputValueUpdate,
    filteredSortedGroups,
    filteredIndex2orgIndex,
    undo,
    redo,
    lineModalOn,
  } = parsingTableStore;
  if (!tdRef) return;
  const {
    additionalFocusIndexes,
    setAdditionalFocusIndexes,
    setHoveredBoxIds,
    setSelectedBoxIds,
    focusIndex: curIndex,
    setFocusIndex: setIndex,
  } = resultInteractionStore;
  if (event.ctrlKey || event.metaKey) {
    if (event.shiftKey && event.key === "z") {
      redo();
      event.preventDefault();
    } else if (event.key === "z") {
      undo();
      event.preventDefault();
    }
  }
  if (curIndex === -1 || isInsideInput(event.target as Node) || lineModalOn)
    return;
  else if (event.key === "Escape") {
    setHoveredBoxIds([]);
    setSelectedBoxIds([]);
    setAdditionalFocusIndexes([]);
    setIndex(-1);
  } else if (event.key === "ArrowDown") {
    event.preventDefault();
    event.stopPropagation();
    if (curIndex < filteredLineGroups.length) {
      setIndex(curIndex + 1);
      tdClick(curIndex + 1);
    } else if (
      curIndex + groupKeys.length <
      filteredLineGroups.length + filteredSortedGroups.length * groupKeys.length
    ) {
      setIndex(curIndex + groupKeys.length);
      tdClick(curIndex + groupKeys.length);
    }
  } else if (event.key === "ArrowUp") {
    event.preventDefault();
    event.stopPropagation();
    if (curIndex - groupKeys.length >= filteredLineGroups.length) {
      setIndex(curIndex - groupKeys.length);
      tdClick(curIndex - groupKeys.length);
    } else if (curIndex - 1 > filteredLineGroups.length) {
      setIndex(filteredLineGroups.length - 1);
      tdClick(filteredLineGroups.length - 1);
    } else {
      setIndex(Math.max(curIndex - 1, 0));
      tdClick(Math.max(curIndex - 1, 0));
    }
  } else if (
    (event.shiftKey && event.key === "Tab") ||
    event.key === "ArrowLeft"
  ) {
    event.preventDefault();
    event.stopPropagation();
    setIndex(Math.max(curIndex - 1, 0));
    tdClick(Math.max(curIndex - 1, 0));
  } else if (event.key === "Tab" || event.key === "ArrowRight") {
    event.preventDefault();
    event.stopPropagation();
    const nextIdx = Math.min(
      filteredLineGroups.length +
        filteredSortedGroups.length * groupKeys.length -
        1,
      curIndex + 1,
    );
    setIndex(nextIdx);
    tdClick(nextIdx);
    tdFocus(nextIdx);
  } else if (event.key === "Backspace" && TABLE_ABLE) {
    inputValueUpdate(filteredIndex2orgIndex[curIndex], "");
    additionalFocusIndexes.forEach(idx =>
      inputValueUpdate(filteredIndex2orgIndex[idx], ""),
    );
    setAdditionalFocusIndexes([]);
    setIndex(-1);
  } else {
    if (event.key === "Enter") {
      event.preventDefault(); // Enter 키의 기본 동작 중지
    }
    inputFocus(curIndex);
    if (inputRefs) {
      inputRefs.current[curIndex]?.focus();
    }
  }
};
