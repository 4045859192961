import type { SVGProps } from "react";

export function UpArrow(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="36"
      height="36"
      viewBox="0 0 36 36"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.4697 14.4697C17.7626 14.1768 18.2374 14.1768 18.5303 14.4697L22.7803 18.7197C23.0732 19.0126 23.0732 19.4874 22.7803 19.7803C22.4874 20.0732 22.0126 20.0732 21.7197 19.7803L18 16.0607L14.2803 19.7803C13.9874 20.0732 13.5126 20.0732 13.2197 19.7803C12.9268 19.4874 12.9268 19.0126 13.2197 18.7197L17.4697 14.4697Z"
        fill="#111218"
      />
    </svg>
  );
}
