import React, { useContext } from "react";
import { InferenceResult } from "../types/demo";

export interface Select {
  selectedBoxIds: string[];
  setSelectedBoxIds: React.Dispatch<React.SetStateAction<string[]>>;
}
export interface Hover {
  hoveredBoxIds: string[];
  setHoveredBoxIds: React.Dispatch<React.SetStateAction<string[]>>;
  hoveredEntityNames?: string[];
  setHoveredEntityNames?: React.Dispatch<React.SetStateAction<string[]>>;
}
export interface DemoResult {
  result: InferenceResult | null;
  hover: Hover;
  select: Select;
  boxesChangeDetector: Set<number>;
  setBoxesChangeDetector: (t: Set<number>) => void;
  focusIndex: number;
  setFocusIndex: (idx: number) => void;
}
export const emtpyDemoResult: DemoResult = {
  result: null,
  hover: {
    hoveredBoxIds: [],
    setHoveredBoxIds: () => {},
  },
  select: {
    selectedBoxIds: [],
    setSelectedBoxIds: () => {},
  },
  boxesChangeDetector: new Set(),
  setBoxesChangeDetector: (t: Set<number>) => {},
  focusIndex: -1,
  setFocusIndex: (idx: number) => {},
};

const DemoResultContext = React.createContext<DemoResult>(emtpyDemoResult);

export const DemoResultProvider = DemoResultContext.Provider;

export function useDemoResult(): DemoResult {
  return useContext(DemoResultContext);
}
