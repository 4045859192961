import type { SVGProps } from "react";

export function DownArrow(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="36"
      height="36"
      viewBox="0 0 36 36"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.2197 16.2197C13.5126 15.9268 13.9874 15.9268 14.2803 16.2197L18 19.9393L21.7197 16.2197C22.0126 15.9268 22.4874 15.9268 22.7803 16.2197C23.0732 16.5126 23.0732 16.9874 22.7803 17.2803L18.5303 21.5303C18.3897 21.671 18.1989 21.75 18 21.75C17.8011 21.75 17.6103 21.671 17.4697 21.5303L13.2197 17.2803C12.9268 16.9874 12.9268 16.5126 13.2197 16.2197Z"
        fill="#111218"
      />
    </svg>
  );
}
