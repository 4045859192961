import { useContext, useEffect, useRef, useState } from "react";
import ReactModal from "react-modal";
import {
  DEMO_MODE_FEATURE,
  FEATURE,
  ONPREMISE_DEMO_FEATURE,
} from "../../constants/FeatureFlag";
import {
  ValidatorFileContext,
  ValidatorModelsContext,
} from "../../hooks/useFile";
import { fileUpload, validateFile } from "../../hooks/useImageUploader";
import { OacJsonResponse } from "../../types/demo";
import {
  OCROacJsonResponse,
  convertResponseToImageOCROacJson,
} from "../../types/ocrOacResponse";
import { classnames } from "../../utils/classnames";
import { endpointStore } from "../../utils/mobx/EndpointStore";
import { Capitalize, ModifyModelName } from "../../utils/stringUtil";
import { Icon, Icons } from "../Icon";
import { DocumentArrowUp } from "../Icon/generated/DocumentArrowUp";
import ErrorModal from "./ErrorModal";

type SampleFile = OacJsonResponse;
interface Sample {
  id: string;
  title: string;
  thumbnailPath: string;
  filePath: string;
  modelName: string;
}

export const extractorSamples: Sample[] = [
  {
    id: "kie1",
    title: "receipt",
    thumbnailPath: "/samples/extractor/KIE01.jpeg",
    filePath: "/samples/extractor/KIE01.json",
    modelName: "receipt-extraction-3.2.0",
  },
  {
    id: "kie2",
    title: "bill_of_lading1",
    thumbnailPath: "/samples/extractor/KIE11.jpeg",
    filePath: "/samples/extractor/KIE11.json",
    modelName: "bill-of-lading-and-shipping-request-extraction-4.1.6",
  },
  {
    id: "kie3",
    title: "bill_of_lading2",
    thumbnailPath: "/samples/extractor/KIE12.jpeg",
    filePath: "/samples/extractor/KIE12.json",
    modelName: "bill-of-lading-and-shipping-request-extraction-4.1.6",
  },
  {
    id: "kie4",
    title: "commercial_invoice1",
    thumbnailPath: "/samples/extractor/KIE21.jpeg",
    filePath: "/samples/extractor/KIE21.json",
    modelName: "commercial-invoice-and-packing-list-extraction-4.1.6",
  },
  {
    id: "kie5",
    title: "commercial_invoice2",
    thumbnailPath: "/samples/extractor/KIE22.jpeg",
    filePath: "/samples/extractor/KIE22.json",
    modelName: "commercial-invoice-and-packing-list-extraction-4.1.6",
  },
  {
    id: "kie6",
    title: "air_waybill",
    thumbnailPath: "/samples/extractor/KIE31.jpeg",
    filePath: "/samples/extractor/KIE31.json",
    modelName: "air-waybill-extraction-4.1.6",
  },
  {
    id: "kie7",
    title: "export_declaration",
    thumbnailPath: "/samples/extractor/KIE41.jpeg",
    filePath: "/samples/extractor/KIE41.json",
    modelName: "kr-export-declaration-certificate-extraction-4.1.6",
  },
];

export const ocrSamples: Sample[] = [
  {
    id: "ocr1",
    title: "bill_of_lading",
    thumbnailPath: "/samples/ocr/OCR01.jpeg",
    filePath: "/samples/ocr/OCR01.json",
    modelName: "OCR",
  },
  {
    id: "ocr2",
    title: "product_manual",
    thumbnailPath: "/samples/ocr/OCR02.jpeg",
    filePath: "/samples/ocr/OCR02.json",
    modelName: "OCR",
  },
  {
    id: "ocr3",
    title: "business_registration",
    thumbnailPath: "/samples/ocr/OCR03.jpeg",
    filePath: "/samples/ocr/OCR03.json",
    modelName: "OCR",
  },
  {
    id: "ocr4",
    title: "business_card",
    thumbnailPath: "/samples/ocr/OCR04.jpeg",
    filePath: "/samples/ocr/OCR04.json",
    modelName: "OCR",
  },
  {
    id: "ocr6",
    title: "privacy_policy_consent",
    thumbnailPath: "/samples/ocr/OCR06.jpeg",
    filePath: "/samples/ocr/OCR06.json",
    modelName: "OCR",
  },
];

export const layoutAnalysisSamples: Sample[] = [
  {
    id: "la1",
    title: "commercial_invoice",
    thumbnailPath: "/samples/layout-analysis/LA01.jpeg",
    filePath: "/samples/layout-analysis/LA01.json",
    modelName: "LA",
  },
  {
    id: "la2",
    title: "paper",
    thumbnailPath: "/samples/layout-analysis/LA02.jpeg",
    filePath: "/samples/layout-analysis/LA02.json",
    modelName: "LA",
  },
  {
    id: "la3",
    title: "product_user_manual",
    thumbnailPath: "/samples/layout-analysis/LA03.jpeg",
    filePath: "/samples/layout-analysis/LA03.json",
    modelName: "LA",
  },
  {
    id: "la4",
    title: "report_with_index",
    thumbnailPath: "/samples/layout-analysis/LA04.jpeg",
    filePath: "/samples/layout-analysis/LA04.json",
    modelName: "LA",
  },
  {
    id: "la5",
    title: "report_with_multi_stage",
    thumbnailPath: "/samples/layout-analysis/LA05.jpeg",
    filePath: "/samples/layout-analysis/LA05.json",
    modelName: "LA",
  },
  {
    id: "la6",
    title: "product_user_manual_with_table",
    thumbnailPath: "/samples/layout-analysis/LA06.jpeg",
    filePath: "/samples/layout-analysis/LA06.json",
    modelName: "LA",
  },
  {
    id: "la7",
    title: "report_with_main_title",
    thumbnailPath: "/samples/layout-analysis/LA07.jpeg",
    filePath: "/samples/layout-analysis/LA07.json",
    modelName: "LA",
  },
];

interface ModalComponent {
  isOpen: boolean;
  setIsOpen: (open: boolean) => void;
  tenantId: string;
  isLogin: boolean;
  gotMsg: boolean;
}

const customInitModalStyles: ReactModal.Styles = {
  overlay: {
    backgroundColor: "white",
    width: "100%",
    height: "100%",
    zIndex: "10",
    position: "fixed",
    top: "0",
    left: "0",
  },
  content: {
    width: "auto",
    minWidth: "866px",
    maxWidth: "967px",
    height: "auto",
    minHeight: "700px",
    maxHeight: "880px",
    zIndex: "150",
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    border: "none",
    backgroundColor: "white",
    justifyContent: "center",
    overflow: "auto",
  },
};

export default function InitSelectModal({
  isOpen,
  setIsOpen,
  tenantId,
  isLogin,
  gotMsg,
}: ModalComponent) {
  const { endpointType } = endpointStore;
  const [checked, setChecked] = useState<number>(0);
  const { models, selected, setSelected } = useContext(ValidatorModelsContext);
  const {
    files,
    setSelected: setFileSelected,
    setFiles,
    setImageFiles,
    setInfering,
  } = useContext(ValidatorFileContext);
  const [errorOn, setErrorOn] = useState<boolean>(false);
  const imageUploadFileRef = useRef<HTMLInputElement | null>(null);
  const ref = useRef<HTMLDivElement>(null);
  const [isDropping, setIsDroppinrg] = useState<boolean>(false);
  const [errorType, setErrorType] = useState<number>(1);
  const [modelIdx, setModelIndex] = useState<number>(0);
  const [modalHeight, setModalHeight] = useState(340); // 초기 모달 높이
  const [closeFunction, setCloseFunction] = useState<() => void>(() => {});
  const [reloadFunction, setReloadFunction] = useState<() => void>(() => {});
  const [hoveredModel, setHoveredModel] = useState<number | null>(null);
  const [sampleDocHover, setSampleDocHover] = useState<number | null>(null);
  const [samples, setSamples] = useState<Sample[]>([]);

  const [dialogVisible, setDialogVisible] = useState<boolean>(false);

  const [endpointUrl, setEndpointUrl] = useState<string>("");

  const [isOnprem, setIsOnprem] = useState<boolean>(false);

  // useEffect(() => {
  //   // 윈도우 창 크기가 변경될 때마다 모달 높이를 업데이트
  //   const handleResize = () => {
  //     const height = document.getElementById("React-Modal")?.clientHeight;
  //     const contentHeight = 340;
  //     setModalHeight(contentHeight);
  //   };
  //   handleResize(); // 처음 렌더링 시 모달 높이를 설정
  //   window.addEventListener("resize", handleResize);
  //   window.addEventListener("mouseover", handleResize);

  //   return () => {
  //     window.removeEventListener("resize", handleResize);
  //     window.removeEventListener("mouseover", handleResize);
  //   };
  // }, []);

  useEffect(() => {
    setModelIndex(selected);
    setChecked(selected);
  }, [isOpen, selected]);

  useEffect(() => {
    if (FEATURE === ONPREMISE_DEMO_FEATURE) {
      setIsOnprem(true);
    }
  }, []);

  useEffect(() => {
    if (endpointType.toLowerCase() === "extractor") {
      setEndpointUrl(`/playground/kie`);
    } else if (endpointType.toLowerCase() === "ocr") {
      setEndpointUrl(`/playground/ocr`);
    } else if (endpointType.toLowerCase() === "layout-analysis") {
      setEndpointUrl(`/playground/la`);
    }
  }, [endpointType]);

  useEffect(() => {
    setSamples(
      endpointType.toLowerCase() === "extractor"
        ? extractorSamples
        : endpointType.toLowerCase() === "ocr"
          ? ocrSamples
          : endpointType.toLowerCase() === "layout-analysis"
            ? layoutAnalysisSamples
            : [],
    );
  }, [endpointType]);

  // 드래그 중인 아이템이 영역 위에 있을 때 실행되는 함수
  const onDragOver = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    setIsDroppinrg(true);
  };

  // 드래그 영역 이탈시
  const onDragLeave = (e: React.DragEvent<HTMLDivElement>) => {
    setIsDroppinrg(false);
  };

  // drop시 실행되는 함수
  const handleDrop = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    setIsDroppinrg(false);
    if (!isLogin) {
      return;
    }
    const file = event.dataTransfer.files[0];
    // 파일 유효성 검즘.
    if (!validateFile(file, setErrorType)) {
      setErrorOn(true);
      event.dataTransfer.clearData();
      setCloseFunction(() => () => {
        document.getElementById("file-upload")?.click();
        setErrorOn(false);
      });
      setReloadFunction(() => () => {
        setErrorOn(false);
      });

      return;
    }

    fileUpload(
      [event.dataTransfer.files[0]],
      setErrorType,
      () => {},
      tenantId,
      setIsOpen,
      setErrorOn,
      setInfering,
      setImageFiles,
      models,
      modelIdx,
      files,
      setFileSelected,
      setFiles,
      setCloseFunction,
      setReloadFunction,
      errorType,
      null as unknown as React.ChangeEvent<HTMLInputElement>,
      false,
    );
  };

  const handleSampleClick = async (sample: Sample) => {
    try {
      const response = await fetch(sample.filePath); // 실제 JSON 파일 경로로 변경
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const jsonData: SampleFile = await response.json();
      if (endpointType.toLowerCase() === "extractor") {
        const modelIndex = models.findIndex(model =>
          sample.modelName.includes(model.name),
        );
        setFiles([
          {
            title: sample.title,
            result: jsonData,
            modelIndex: modelIndex,
          },
        ]);
      } else if (endpointType.toLowerCase() === "ocr") {
        setFiles([
          {
            title: sample.title,
            result: convertResponseToImageOCROacJson(
              jsonData as unknown as OCROacJsonResponse,
            ),
            modelIndex: selected,
          },
        ]);
      } else if (endpointType.toLowerCase() === "layout-analysis") {
        setFiles([
          {
            title: sample.title,
            result: jsonData,
            modelIndex: selected,
          },
        ]);
      }
      setFileSelected(0);
      window.history.pushState({ page: 1 }, `Init Modal close`, "");
      setIsOpen(false);
    } catch (error) {
      console.error("Error fetching JSON file:", error);
      setErrorOn(true);
    }
  };

  return (
    <>
      <ErrorModal
        open={errorOn}
        setOpen={setErrorOn}
        errorType={errorType}
        closeFunction={closeFunction}
        reloadFunction={reloadFunction}
      />

      <ReactModal
        isOpen={isOpen}
        style={customInitModalStyles}
        id="React-Modal"
        ariaHideApp={false}
      >
        <div className="z-10 flex flex-col justify-between" ref={ref}>
          <div className="flex flex-col justify-between">
            <div className="">
              {endpointType.toLowerCase() === "extractor" ? (
                <div className="text-center text-gray-700 --heading-lg">
                  Upload a document to get Key Information Extraction results
                </div>
              ) : endpointType.toLowerCase() === "ocr" ? (
                <div className="text-center text-gray-700 --heading-lg">
                  Upload a document to get OCR results
                </div>
              ) : endpointType.toLowerCase() === "layout-analysis" ? (
                <div className="text-center text-gray-700 --heading-lg">
                  Upload a document to get Layout Analysis results
                </div>
              ) : (
                <></>
              )}
            </div>
            <div className="flex flex-col">
              <div className="flex justify-start h-full gap-5 mx-3 my-6">
                {endpointType.toLowerCase() === "extractor" && isLogin ? (
                  <div className="flex-col justify-start flex-1 w-1/2">
                    <div className="--body-lm leading-[22px] text-gray-700">
                      Model
                    </div>
                    <div
                      className={`flex px-2 mt-2 border overflow-y-scroll border-gray-100 rounded-lg bg-gray-25`}
                      style={{ height: modalHeight }}
                    >
                      <fieldset className="w-full">
                        <div>
                          <div className="mx-2 divide-y divide-gray-100 py-3">
                            {models.map((model, idx) => (
                              <div
                                onClick={() => {
                                  setModelIndex(idx);
                                  setChecked(idx);
                                }}
                                onMouseEnter={() => {
                                  setHoveredModel(idx);
                                }}
                                onMouseLeave={() => {
                                  setHoveredModel(null);
                                }}
                                className={classnames({
                                  "text-gray-500 --body-sm": idx !== checked,
                                  "text-gray-900 --heading-ss": idx === checked,
                                  "px-2": true,
                                })}
                                key={idx}
                              >
                                <div
                                  key={idx}
                                  className="relative flex items-center w-full cursor-pointer"
                                >
                                  <div className="flex-1 w-full min-w-0 leading-[18px] py-1">
                                    <label
                                      htmlFor={`model-${idx}`}
                                      className="select-none leading-7 cursor-pointer"
                                    >
                                      {ModifyModelName(
                                        model.name,
                                        model.status,
                                      )}
                                    </label>
                                    {model.status?.toLowerCase() != "public" ? (
                                      <span
                                        className={classnames({
                                          "inline-flex ml-3 items-center text-[12px] font-normal":
                                            true,
                                          "text-[#1D4ED8] ":
                                            model.status?.toLowerCase() ===
                                            "beta",
                                          "text-[#22C55E] ":
                                            model.status?.toLowerCase() !=
                                            "beta",
                                        })}
                                      >
                                        {Capitalize(model.status)}
                                      </span>
                                    ) : (
                                      <></>
                                    )}
                                  </div>

                                  {
                                    <div
                                      className={`flex items-center h-6 my-1 ${checked === idx ? "" : "invisible"}`}
                                    >
                                      <Icon source={Icons.Check} />
                                    </div>
                                  }
                                </div>
                              </div>
                            ))}
                          </div>
                        </div>
                      </fieldset>
                    </div>
                  </div>
                ) : (
                  <></>
                )}
                <div className="flex-col flex-1 w-1/2 h-full">
                  {endpointType.toLowerCase() === "extractor" && isLogin ? (
                    <div className="leading-5 text-gray-700 --body-lm">
                      File
                    </div>
                  ) : (
                    <></>
                  )}
                  <div
                    className={classnames({
                      "flex flex-col justify-center px-6 mt-2 border border-dashed rounded-lg ":
                        true,
                      "border-gray-900/20": !isDropping,
                      "border-blue-400 ring ring-blue-50 bg-gray-50":
                        isLogin && isDropping,
                      "cursor-pointer": isLogin,
                    })}
                    onDragOver={onDragOver}
                    onDragLeave={onDragLeave}
                    onDrop={handleDrop}
                    onClick={() => {
                      isLogin && imageUploadFileRef.current?.click();
                    }}
                    onMouseEnter={onDragOver}
                    onMouseLeave={onDragLeave}
                    style={{ height: modalHeight }}
                  >
                    <input
                      id="file-upload"
                      name="image"
                      type="file"
                      accept=".jpg,.jpeg,.png,.bmp,.pdf,.tif,.tiff,.heic,.heif"
                      onChange={async (
                        e: React.ChangeEvent<HTMLInputElement>,
                      ) => {
                        const _file = e.target.files;
                        const t = async () =>
                          await fileUpload(
                            [_file![0]],
                            setErrorType,
                            () => {},
                            tenantId,
                            setIsOpen,
                            setErrorOn,
                            setInfering,
                            setImageFiles,
                            models,
                            modelIdx,
                            files,
                            setFileSelected,
                            setFiles,
                            setCloseFunction,
                            setReloadFunction,
                            errorType,
                            e,
                            false,
                          );
                        t()
                          .then(() => {
                            setSelected(modelIdx);
                          })
                          .catch(() => {});
                      }}
                      ref={imageUploadFileRef}
                      style={{ display: "none" }}
                    />
                    <div className="flex flex-col items-center justify-center text-center">
                      <DocumentArrowUp
                        className="w-full mx-auto"
                        aria-hidden="true"
                      />
                      <div className="justify-center mt-4 --body-sm leading-[22px] text-gray-600">
                        {isLogin ? (
                          <label className="cursor-pointer">
                            <span className="font-medium text-blue-400 rounded-md">
                              Select a file
                            </span>

                            <span className="text-gray-700 --body-sm">
                              &nbsp;or drop it here
                            </span>
                          </label>
                        ) : (
                          <span className="font-medium text-gray-700 --body-sm">
                            <a
                              href={
                                window.location != window.parent.location
                                  ? `${document.referrer}/login?redirect=${endpointUrl}`
                                  : `${document.location.href}/login?redirect=${endpointUrl}`
                              }
                              target="_parent"
                              className="text-blue-400 cursor-pointer hover:text-blue-300"
                            >
                              Log in
                            </a>
                            &nbsp;to upload your document
                          </span>
                        )}
                      </div>
                      <p className="leading-5 text-gray-600 --body-xs">
                        JPEG, PNG, BMP, PDF, TIFF, HEIC
                      </p>
                      <p className="leading-5 text-gray-600 --body-xs">
                        Up to 50MB
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {true || FEATURE === DEMO_MODE_FEATURE ? (
              <>
                <div className="text-center text-gray-700 --heading-sm mb-4">
                  Sample documents
                </div>
                <div className="flex justify-center gap-2">
                  <div className="flex flex-col items-center">
                    <div className="flex justify-center gap-2">
                      {samples.map((sample, i) => (
                        <div
                          className="flex flex-col items-center relative border-[#E5E7EB] border-[1px] rounded-md p-1"
                          key={i}
                        >
                          <img
                            src={sample.thumbnailPath}
                            alt={`sample-document-${i}`}
                            className="w-16 h-16 object-cover cursor-pointer"
                            onClick={() => {
                              if (true || !isLogin || gotMsg) {
                                handleSampleClick(sample);
                              }
                            }}
                            onMouseEnter={() => {
                              setSampleDocHover(i);
                            }}
                            onMouseLeave={() => {
                              setSampleDocHover(null);
                            }}
                          />
                          <div
                            className={classnames({
                              "float absolute bottom-[-40px] py-1.5 px-2 bg-gray-800 text-white text-[14px] rounded-[6px] h-min tracking-wide opacity-90 z-40":
                                true,
                              invisible: sampleDocHover !== i,
                            })}
                          >
                            <div className="">{sample.title}</div>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              </>
            ) : null}
          </div>
        </div>
      </ReactModal>
    </>
  );
}
