import type { SVGProps } from "react";

export function DocumentArrowUp(props: SVGProps<SVGSVGElement>) {
    return (
      <svg 
         width="40"
         height="40" 
         viewBox="0 0 40 40" 
         fill="none" 
         xmlns="http://www.w3.org/2000/svg">
        <path 
          d="M18.9978 22.517V29.5491C18.9978 29.8463 19.0985 30.0951 19.2999 30.2955C19.5013 30.4959 19.7513 30.5961 20.0499 30.5961C20.3485 30.5961 20.5968 30.4959 20.7948 30.2955C20.9928 30.0951 21.0918 29.8463 21.0918 29.5491V22.517L23.7991 25.235C23.8817 25.3177 24.0315 25.3355 24.2484 25.2885C24.4652 25.2414 24.6718 25.1901 24.868 25.1346C25.0642 25.079 25.2175 25.0438 25.328 25.0288C25.4383 25.0139 25.4202 25.0798 25.2735 25.2265C25.485 25.0092 25.5875 24.7635 25.5811 24.4893C25.5747 24.2151 25.4664 23.9694 25.2563 23.7522L20.9099 19.4689C20.7748 19.333 20.6308 19.2358 20.4781 19.1774C20.3253 19.119 20.1647 19.0898 19.9963 19.0898C19.8278 19.0898 19.6684 19.119 19.5181 19.1774C19.3678 19.2358 19.2251 19.3328 19.09 19.4683L14.7713 23.797C14.5648 24.0036 14.4603 24.2466 14.4578 24.5262C14.4553 24.8057 14.5591 25.0577 14.7692 25.2822C14.9864 25.4794 15.2339 25.5794 15.5117 25.5823C15.7895 25.5851 16.0334 25.4815 16.2435 25.2714L18.9978 22.517ZM10.1068 35.8333C9.3905 35.8333 8.77694 35.5779 8.26617 35.0672C7.75539 34.5564 7.5 33.9428 7.5 33.2265V6.77358C7.5 6.05725 7.75539 5.44369 8.26617 4.93291C8.77694 4.42214 9.3905 4.16675 10.1068 4.16675H23.0951C23.4523 4.16675 23.7918 4.23726 24.1135 4.37829C24.4353 4.51932 24.7158 4.70593 24.9551 4.93812L31.7285 11.7115C31.9607 11.9509 32.1473 12.2314 32.2884 12.5531C32.4294 12.8749 32.4999 13.2144 32.4999 13.5716V33.2265C32.4999 33.9428 32.2445 34.5564 31.7337 35.0672C31.223 35.5779 30.6094 35.8333 29.8931 35.8333H10.1068ZM23.1261 12.1752V6.26071H10.1068C9.97861 6.26071 9.86108 6.31414 9.75425 6.421C9.64739 6.52783 9.59396 6.64536 9.59396 6.77358V33.2265C9.59396 33.3547 9.64739 33.4722 9.75425 33.5791C9.86108 33.6859 9.97861 33.7394 10.1068 33.7394H29.8931C30.0213 33.7394 30.1388 33.6859 30.2457 33.5791C30.3525 33.4722 30.406 33.3547 30.406 33.2265V13.4786H24.4295C24.0645 13.4786 23.756 13.3526 23.504 13.1007C23.2521 12.8487 23.1261 12.5402 23.1261 12.1752Z" 
          fill="#22232B"
        />
      </svg>
    );
  }
  