import type { SVGProps } from "react";

export function EyeOpen(props: SVGProps<SVGSVGElement>) {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M8 9.9999C9.10457 9.9999 10 9.10447 10 7.9999C10 6.89533 9.10457 5.9999 8 5.9999C6.89543 5.9999 6 6.89533 6 7.9999C6 9.10447 6.89543 9.9999 8 9.9999Z" fill="#1F1F1F" />
      <path fillRule="evenodd" clipRule="evenodd" d="M0.531404 8.47221C0.413913 8.16689 0.414015 7.82841 0.531688 7.52316C1.68702 4.52621 4.59464 2.3999 7.99887 2.3999C11.4048 2.3999 14.3136 4.52831 15.4678 7.5276C15.5853 7.83292 15.5852 8.1714 15.4675 8.47664C14.3121 11.4736 11.4045 13.5999 8.0003 13.5999C4.59438 13.5999 1.68558 11.4715 0.531404 8.47221ZM11.2003 7.9999C11.2003 9.76721 9.76761 11.1999 8.0003 11.1999C6.23299 11.1999 4.8003 9.76721 4.8003 7.9999C4.8003 6.23259 6.23299 4.7999 8.0003 4.7999C9.76761 4.7999 11.2003 6.23259 11.2003 7.9999Z" fill="#1F1F1F" />
    </svg>

  );
}


export function EyeOpenGrey(props: SVGProps<SVGSVGElement>) {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M8 9.9999C9.10457 9.9999 10 9.10447 10 7.9999C10 6.89533 9.10457 5.9999 8 5.9999C6.89543 5.9999 6 6.89533 6 7.9999C6 9.10447 6.89543 9.9999 8 9.9999Z" fill="#C2C2C2" />
      <path fillRule="evenodd" clipRule="evenodd" d="M0.531404 8.47221C0.413913 8.16689 0.414015 7.82841 0.531688 7.52316C1.68702 4.52621 4.59464 2.3999 7.99887 2.3999C11.4048 2.3999 14.3136 4.52831 15.4678 7.5276C15.5853 7.83292 15.5852 8.1714 15.4675 8.47664C14.3121 11.4736 11.4045 13.5999 8.0003 13.5999C4.59438 13.5999 1.68558 11.4715 0.531404 8.47221ZM11.2003 7.9999C11.2003 9.76721 9.76761 11.1999 8.0003 11.1999C6.23299 11.1999 4.8003 9.76721 4.8003 7.9999C4.8003 6.23259 6.23299 4.7999 8.0003 4.7999C9.76761 4.7999 11.2003 6.23259 11.2003 7.9999Z" fill="#C2C2C2" />
    </svg>

  );
}
