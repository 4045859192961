import type { SVGProps } from "react";

export function FileOpener(props: SVGProps<SVGSVGElement>) {
  return (
    <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M0 8C0 3.58172 3.58172 0 8 0H28C32.4183 0 36 3.58172 36 8V28C36 32.4183 32.4183 36 28 36H8C3.58172 36 0 32.4183 0 28V8Z" fill="#518FFB" />
      <path fillRule="evenodd" clipRule="evenodd" d="M10 12.75C10 12.3358 10.3358 12 10.75 12H25.25C25.6642 12 26 12.3358 26 12.75C26 13.1642 25.6642 13.5 25.25 13.5H10.75C10.3358 13.5 10 13.1642 10 12.75ZM10 18C10 17.5858 10.3358 17.25 10.75 17.25H25.25C25.6642 17.25 26 17.5858 26 18C26 18.4142 25.6642 18.75 25.25 18.75H10.75C10.3358 18.75 10 18.4142 10 18ZM10 23.25C10 22.8358 10.3358 22.5 10.75 22.5H25.25C25.6642 22.5 26 22.8358 26 23.25C26 23.6642 25.6642 24 25.25 24H10.75C10.3358 24 10 23.6642 10 23.25Z" fill="white" />
    </svg>

  );
}


export function FileOpener3(props: SVGProps<SVGSVGElement>) {
  return (
    <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d="M9.99951 12.75C9.99951 12.3358 10.3353 12 10.7495 12H25.2495C25.6637 12 25.9995 12.3358 25.9995 12.75C25.9995 13.1642 25.6637 13.5 25.2495 13.5H10.7495C10.3353 13.5 9.99951 13.1642 9.99951 12.75ZM9.99951 18C9.99951 17.5858 10.3353 17.25 10.7495 17.25H25.2495C25.6637 17.25 25.9995 17.5858 25.9995 18C25.9995 18.4142 25.6637 18.75 25.2495 18.75H10.7495C10.3353 18.75 9.99951 18.4142 9.99951 18ZM9.99951 23.25C9.99951 22.8358 10.3353 22.5 10.7495 22.5H25.2495C25.6637 22.5 25.9995 22.8358 25.9995 23.25C25.9995 23.6642 25.6637 24 25.2495 24H10.7495C10.3353 24 9.99951 23.6642 9.99951 23.25Z" fill="#111218" />
    </svg>

  );
}




