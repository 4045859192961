export const MAX_FILE_SIZE_MB: number = 50;
export const MAX_FILE_PAGE: number = 30;
export const ALLOW_FILE_TYPE: string[] = [
  "image/jpeg",
  "image/png",
  "image/bmp",
  "image/heic",
  "image/heif",
  "application/pdf",
  "image/tiff",
];

export const ALLOW_FILE_EXTENSION: string[] = [
  "jpeg",
  "jpg",
  "png",
  "bmp",
  "heic",
  "heif",
  "pdf",
  "tiff",
];
