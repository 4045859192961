import { ComponentPropsWithoutRef } from "react";
import { classnames } from "../../utils/classnames";

export interface BlockerProps extends ComponentPropsWithoutRef<"div"> { }

export function Blocker({ children }: BlockerProps) {
  return (
    <div className="fixed inset-0 z-30 flex items-center justify-center bg-black/50">
      <div
        className={classnames({
          "rounded max-w-[calc(100vw-64px)] m-[32px] p-6 max-h-[calc(100vh-4rem)] overflow-auto":
            true,
        })}
        onClick={e => e.stopPropagation()}
      >
        {children}
      </div>
    </div>
  );
}
