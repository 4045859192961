/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import { observer } from "mobx-react";
import { FC, useEffect, useMemo, useRef, useState } from "react";
import useResizeObserver from "use-resize-observer";
import {
  drawBox,
  drawDimmedBox,
  drawDimmedBoxOut,
  drawLABox,
  drawLargeBox,
  drawLargestBox,
  drawOCRBox,
} from "../atom/function/boxDrawingFunc";
import { useImageTool } from "../hooks/useImageTool";
import {
  ContentField,
  ExtractorInferenceResult,
  GroupField,
} from "../types/extractorOacResponse";
import { LAInferenceResult } from "../types/laOacResponse";
import { OCRInferenceResult } from "../types/ocrOacResponse";
import {
  BoxDrawing,
  BoxTheme,
  DimmedBoxTheme,
  LargeBoxTheme,
  WarningBoxTheme,
} from "../types/parsingImage";
import { classnames } from "../utils/classnames";
import { endpointStore } from "../utils/mobx/EndpointStore";
import { imageViewStore } from "../utils/mobx/ImageViewStore";
import { resultInteractionStore } from "../utils/mobx/ResultInteractionStore";
import { NothingSelected } from "./NothingSelected";

export interface ParsingImageViewProps {
  image: string;
  cssTransform: string;
  onResizeCanvas: (size: { width: number; height: number }) => void;
  onMoveImage: (offset: { x: number; y: number }) => void;
  onImageSizeChanged: (size: { width: number; height: number }) => void;
  allowBoxClick?: boolean;
  infering?: boolean;
  page: number;
  isDRSP: boolean;
  pageSelectHandler: (index: number) => void;
  setImageHoveredBoxIds: (boxIds: string[]) => void;
  imageSelectedBoxIds: string[];
  imageHoveredBoxIds: string[];
  moveAndFocus: (x: number, y: number, ratio: number[], N: number) => number;
}
const ParsingImageView: FC<ParsingImageViewProps> = ({
  image,
  cssTransform,
  onResizeCanvas,
  onMoveImage,
  onImageSizeChanged,
  allowBoxClick = true,
  infering = false,
  page,
  isDRSP,
  pageSelectHandler,
  setImageHoveredBoxIds,
  imageSelectedBoxIds,
  imageHoveredBoxIds,
  moveAndFocus,
}) => {
  const [imageSource, setImageSource] = useState<string>();
  const { endpointType } = endpointStore;

  const canvasRef = useRef<HTMLDivElement>(null);
  const imageRef = useRef<SVGSVGElement>(null);
  const boxRef = useRef<SVGGElement[]>([]);

  const {
    result,
    hoveredBoxIds,
    setHoveredBoxIds,
    selectedBoxIds,
    setSelectedBoxIds,
    focusIndex,
  } = resultInteractionStore;
  const {
    boxRatio,
    setImgHeight,
    setImgWidth,
    imgHeight,
    imgWidth,
    svgWidth,
    setSvgWidth,
    svgHeight,
    setSvgHeight,
    degree,
    resizeBlocker,
  } = imageViewStore;

  const getBoxTheme = (boxId: string, themeName: string, boxPage: number) => {
    const theme =
      themeName === "warning"
        ? WarningBoxTheme
        : themeName === "large"
          ? LargeBoxTheme
          : themeName === "dimmed"
            ? DimmedBoxTheme
            : BoxTheme;
    if (boxPage !== page + 1) return theme.default;
    if (
      (selectedBoxIds && selectedBoxIds.includes(boxId)) ||
      (imageSelectedBoxIds && imageSelectedBoxIds.includes(boxId))
    )
      return theme.selected;
    if (
      (hoveredBoxIds && hoveredBoxIds.includes(boxId)) ||
      (imageHoveredBoxIds && imageHoveredBoxIds.includes(boxId))
    )
      return theme.hovered;
    return theme.default;
  };

  const { ref, width = 1, height = 1 } = useResizeObserver<HTMLDivElement>();
  const imageTool = useImageTool(canvasRef, imageRef, onMoveImage);

  const ocrBoxes: BoxDrawing[] = useMemo(() => {
    const disArrowType = ["header"];
    if (infering || !result) return [];
    if (endpointType.toLowerCase() === "ocr") {
      return drawOCRBox(result as OCRInferenceResult) ?? [];
    }
    return [];
  }, [result, infering]);

  const laBoxes: BoxDrawing[] = useMemo(() => {
    const disArrowType = ["header"];
    if (infering || !result) return [];
    if (endpointType.toLowerCase() === "layout-analysis") {
      return drawLABox(result as LAInferenceResult) ?? [];
    }
    return [];
  }, [result, infering]);

  const boxes: BoxDrawing[] = useMemo(() => {
    const disArrowType = ["header"];
    if (infering || !result) return [];
    if (endpointType.toLowerCase() === "extractor") {
      return (
        drawBox(
          (result as ExtractorInferenceResult)?.filter(
            field => !disArrowType.includes(field.type),
          ),
        ) ?? []
      );
    }
    return [];
  }, [result, infering]);

  const largestBoxes: BoxDrawing[] = useMemo(() => {
    const disArrowType = ["header"];
    if (infering || !result) return [];
    if (endpointType.toLowerCase() === "extractor") {
      return (
        drawLargestBox(
          (result as ExtractorInferenceResult)?.filter(
            field => !disArrowType.includes(field.type),
          ),
        ) ?? []
      );
    }
    return [];
  }, [result, infering, imgWidth, imgHeight]);

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const largeBoxes: BoxDrawing[] = useMemo(() => {
    const disArrowType = ["header"];
    if (infering || !result) return [];
    if (endpointType.toLowerCase() === "extractor") {
      return (
        drawLargeBox(
          (result as ExtractorInferenceResult)?.filter(
            field => !disArrowType.includes(field.type),
          ),
        ) ?? []
      );
    }
    return [];
  }, [result, infering, imgWidth, imgHeight]);

  const dimmedBoxes: BoxDrawing[] = useMemo(() => {
    const disArrowType = ["header"];
    if (infering || !result) return [];
    if (endpointType.toLowerCase() === "extractor") {
      return (
        drawDimmedBox(
          (result as ExtractorInferenceResult)?.filter(
            field => !disArrowType.includes(field.type),
          ),
        ) ?? []
      );
    }
    return [];
  }, [result, infering]);

  const dimmedBoxesO: BoxDrawing[] = useMemo(() => {
    const disArrowType = ["header"];
    if (infering || !result) return [];
    if (endpointType.toLowerCase() === "extractor") {
      return (
        drawDimmedBoxOut(
          (result as ExtractorInferenceResult)?.filter(
            field => !disArrowType.includes(field.type),
          ),
        ) ?? []
      );
    }
    return [];
  }, [result, infering, imgWidth, imgHeight]);
  // focus하는 부분이 변화할시, 페이지가 바뀌는지 여부를 보고 페이지를 변환합니다.
  // 큰 박스의 위치 또한 Focus한 박스에 맞게 위치를 계산해 이미지를 적절한 곳으로 옮깁니다.
  useMemo(() => {
    if (endpointType.toLowerCase() === "extractor") {
      return (
        (result as ExtractorInferenceResult)?.flatMap(field => {
          if (field.type === "group") {
            const f = field as GroupField;
            let minX = 100000;
            let minY = 100000;
            let maxX = 0;
            let maxY = 0;
            let isChanged = false;
            f.properties?.forEach(property => {
              if (selectedBoxIds.find(t => t === String(property.id))) {
                if (
                  property.boundingBoxes !== undefined &&
                  property.boundingBoxes.length > 0 &&
                  property.boundingBoxes[0].page !== -1
                ) {
                  if (property.boundingBoxes[0].page > 0) {
                    pageSelectHandler(property.boundingBoxes[0].page - 1);
                  } else {
                    pageSelectHandler(0);
                  }
                }
                if (isDRSP) {
                  boxes.forEach((box, idx) => {
                    if (box.id === String(property.id)) {
                      isChanged = true;
                      minX = Math.min(minX, box.points[0][0]);
                      minY = Math.min(minY, box.points[0][1]);
                      maxX = Math.max(maxX, box.points[2][0]);
                      maxY = Math.max(maxY, box.points[2][1]);
                    }
                  });
                } else {
                  largestBoxes.forEach((box, idx) => {
                    if (box.id === String(property.id)) {
                      const bp_x = (box.points[0][0] + box.points[2][0]) / 2;
                      const bp_y = (box.points[0][1] + box.points[2][1]) / 2;
                      const boxSize = [
                        -box.points[0][0] + box.points[2][0],
                        -box.points[0][1] + box.points[2][1],
                      ];
                      moveAndFocus(bp_x, bp_y, boxSize, boxRatio);
                    }
                  });
                }
              }
            });
            if (isDRSP && isChanged) {
              const bp_x = (minX + maxX) / 2;
              const bp_y = (minY + maxY) / 2;
              const boxSize = [-minX + maxX, -minY + maxY];
              moveAndFocus(bp_x, bp_y, boxSize, boxRatio);
            }
          } else {
            const f = field as ContentField;
            if (selectedBoxIds.includes(String(f.id))) {
              if (
                f.boundingBoxes !== undefined &&
                f.boundingBoxes.length > 0 &&
                f.boundingBoxes[0].page !== -1
              ) {
                if (f.boundingBoxes[0].page > 0) {
                  pageSelectHandler(f.boundingBoxes[0].page - 1);
                } else {
                  pageSelectHandler(0);
                }
              }
              if (isDRSP) {
                let minX = 100000;
                let minY = 100000;
                let maxX = 0;
                let maxY = 0;
                let isChanged = false;

                boxes.forEach((box, idx) => {
                  if (box.id === String(f.id)) {
                    isChanged = true;
                    minX = Math.min(minX, box.points[0][0]);
                    minY = Math.min(minY, box.points[0][1]);
                    maxX = Math.max(maxX, box.points[2][0]);
                    maxY = Math.max(maxY, box.points[2][1]);
                  }
                });
                if (isChanged) {
                  const bp_x = (minX + maxX) / 2;
                  const bp_y = (minY + maxY) / 2;
                  const boxSize = [-minX + maxX, -minY + maxY];
                  moveAndFocus(bp_x, bp_y, boxSize, boxRatio);
                }
              } else {
                boxes.forEach((box, idx) => {
                  if (box.id === String(f.id)) {
                    const bp_x = (box.points[0][0] + box.points[2][0]) / 2;
                    const bp_y = (box.points[0][1] + box.points[2][1]) / 2;
                    const boxSize = [
                      -box.points[0][0] + box.points[2][0],
                      -box.points[0][1] + box.points[2][1],
                    ];
                    const ratio = moveAndFocus(bp_x, bp_y, boxSize, boxRatio);
                  }
                });
              }
            }
          }
        }) ?? []
      );
    } else if (endpointType.toLowerCase() === "ocr") {
      return (
        (result as OCRInferenceResult)?.flatMap((page, pIdx) => {
          const p = page;

          p.lines.forEach(line => {
            if (selectedBoxIds.includes(`page-${pIdx + 1}-line-${line.id}`)) {
              let minX = 100000;
              let minY = 100000;
              let maxX = 0;
              let maxY = 0;

              pageSelectHandler(pIdx);
              ocrBoxes.forEach((box, idx) => {
                if (box.id === `page-${pIdx + 1}-line-${line.id}`) {
                  minX = Math.min(minX, box.points[0][0]);
                  minY = Math.min(minY, box.points[0][1]);
                  maxX = Math.max(maxX, box.points[2][0]);
                  maxY = Math.max(maxY, box.points[2][1]);
                }
              });

              const bp_x = (minX + maxX) / 2;
              const bp_y = (minY + maxY) / 2;
              const boxSize = [-minX + maxX, -minY + maxY];
              moveAndFocus(bp_x, bp_y, boxSize, boxRatio);
            }
          });
        }) ?? []
      );
    } else if (endpointType.toLowerCase() === "layout-analysis") {
      return (
        (result as LAInferenceResult)?.flatMap(element => {
          const e = element;

          if (selectedBoxIds.includes(`page-${e.page}-element-${e.id}`)) {
            laBoxes.forEach((box, idx) => {
              pageSelectHandler(e.page - 1);
              if (box.id === `page-${e.page}-element-${e.id}`) {
                const bp_x = (box.points[0][0] + box.points[2][0]) / 2;
                const bp_y = (box.points[0][1] + box.points[2][1]) / 2;
                const boxSize = [
                  -box.points[0][0] + box.points[2][0],
                  -box.points[0][1] + box.points[2][1],
                ];
                moveAndFocus(bp_x, bp_y, boxSize, boxRatio);
              }
            });
          }
        }) ?? []
      );
    }
  }, [selectedBoxIds, height, focusIndex, width, onResizeCanvas, degree]);

  useEffect(() => {
    // HACKs
    // wrapper 와 svg 의 width, height 값이 같으면
    // resize event 일어났을때도 wrapper 내부의 svg 크기 때문에 wrapper 크기가 변동되지 않는다.
    // 그래서 크기 변화 감지를 위해 10 정도 더 작게 svg 를 만들어서 감지한다.
    // if (resizeBlocker) return;
    const canvasWidth = width - 10;
    const canvasHeight = height - 10;
    setSvgWidth(canvasWidth);
    setSvgHeight(canvasHeight);
    onResizeCanvas({ width: canvasWidth, height: canvasHeight });
  }, [resizeBlocker, width, height, onResizeCanvas]);

  useEffect(() => {
    if (!image) return;
    const newImageSource = "data:image/jpeg;base64," + image;
    setImageSource(newImageSource);
    // 이미지 객체 생성
    const img: HTMLImageElement = new Image();

    // 이미지 로드 완료 후 실행할 함수 정의
    img.onload = function () {
      setImgHeight(img.height);
      setImgWidth(img.width);
    };

    // 이미지의 소스 설정 (Base64 데이터 사용)
    img.src = newImageSource;
  }, [image]);

  return (
    <>
      <div
        className={classnames({
          "relative grid content-center justify-items-center h-full": true,
        })}
        ref={ref}
      >
        {image ? (
          <div
            className={`relative overflow-hidden ${imageTool.cursor}`}
            ref={canvasRef}
          >
            <div className="w-full h-full" id="canvas-div">
              <svg
                width={svgWidth}
                height={svgHeight}
                viewBox={`0 0 ${svgWidth} ${svgHeight}`}
                style={{
                  transform: cssTransform,
                  overflow: "visible",
                  transformOrigin: "top left",
                }}
                ref={imageRef}
              >
                <image
                  href={imageSource}
                  onLoad={e => {
                    onImageSizeChanged({
                      width: e.currentTarget.getBBox().width,
                      height: e.currentTarget.getBBox().height,
                    });
                  }}
                />

                {/* {largeBoxes.map((box: BoxDrawing, idx) => {
                  const theme = getBoxTheme(box.id, box.theme, box.page);
                  return (
                    <g key={"L" + box.id + box.points.join(" ")}
                      ref={el => boxRef.current[boxes.length + idx] = el as SVGGElement}>
                      <path
                        className={(theme ? theme.fill : "")}
                        key={"L" + box.id}
                        d={`M ${box.points.join("L")}Z`}
                        fillOpacity={theme.opacity}
                        strokeOpacity={theme.strokeOpacity !== undefined ? theme.strokeOpacity : theme.opacity}
                        strokeLinejoin="round"
                        style={{ strokeWidth: 5 }} // CSS의 strokeWidth 속성을 사용하여 선의 굵기를 조절합니다.
                        onClick={(e) => {
                          if (box.page === page + 1 && allowBoxClick) {
                            e.stopPropagation();
                            setSelectedBoxIds(box.groupIds ? box.groupIds : [box.id]);
                            setHoveredBoxIds(box.groupIds ? box.groupIds : [box.id]);
                          }
                        }}

                      />
                    </g>
                  );
                })} */}
                {dimmedBoxes.map((box: BoxDrawing, idx) => {
                  const theme = getBoxTheme(box.id, box.theme, -1);
                  return (
                    <g
                      key={"L" + box.id + box.points.join(" ")}
                      ref={el =>
                        (boxRef.current[boxes.length + idx] = el as SVGGElement)
                      }
                    >
                      <path
                        className={theme ? theme.fill : ""}
                        key={"L" + box.id}
                        d={`M ${box.points.join("L")}Z`}
                        fillOpacity={theme.opacity}
                        strokeOpacity={
                          theme.strokeOpacity !== undefined
                            ? theme.strokeOpacity
                            : theme.opacity
                        }
                        strokeLinejoin="round"
                        style={{ strokeWidth: 5 }} // CSS의 strokeWidth 속성을 사용하여 선의 굵기를 조절합니다.
                      />
                    </g>
                  );
                })}

                {dimmedBoxes.flatMap((box: BoxDrawing, idx) => {
                  const theme = getBoxTheme(box.id, box.theme, box.page);
                  const [tl, bl, br, tr] = box.points;
                  const [w, h] = [br[0] - tl[0], br[1] - tl[1]];
                  const [minX, minY, maxX, maxY] = [
                    tl[0] - w * 0.1,
                    tl[1] - h * 0.1,
                    br[0] + w * 0.1,
                    br[1] + h * 0.1,
                  ];
                  const [x, y] = [maxX, maxY];
                  return (
                    <g key={"L" + box.id + box.points.join(" ")}>
                      <path
                        className={theme ? theme.fill : ""}
                        key={"L" + box.id}
                        d={`M${x - 8} ${y}L${x} ${y}L${x} ${y - 8}C${x} ${y - 4} ${x - 4} ${y} ${x - 8} ${y}Z`}
                        clipRule={"evenodd"}
                        fill="black"
                        fillOpacity={theme.opacity}
                        strokeOpacity={
                          theme.strokeOpacity !== undefined
                            ? theme.strokeOpacity
                            : theme.opacity
                        }
                        strokeLinejoin="round"
                        style={{ strokeWidth: 5 }} // CSS의 strokeWidth 속성을 사용하여 선의 굵기를 조절합니다.
                      />
                    </g>
                  );
                })}

                {dimmedBoxes.flatMap((box: BoxDrawing, idx) => {
                  const theme = getBoxTheme(box.id, box.theme, box.page);
                  const [tl, bl, br, tr] = box.points;
                  const [w, h] = [br[0] - tl[0], br[1] - tl[1]];
                  const [minX, minY, maxX, maxY] = [
                    tl[0] - w * 0.1,
                    tl[1] - h * 0.1,
                    br[0] + w * 0.1,
                    br[1] + h * 0.1,
                  ];
                  const [x, y] = [minX, minY];
                  return (
                    <g key={"L" + box.id + box.points.join(" ")}>
                      <path
                        className={theme ? theme.fill : ""}
                        key={"L" + box.id}
                        d={`M${x + 8} ${y}H${x}V${y + 8}C${x} ${y + 4} ${x + 4} ${y} ${x + 8} ${y}Z`}
                        clipRule={"evenodd"}
                        fillOpacity={theme.opacity}
                        strokeOpacity={
                          theme.strokeOpacity !== undefined
                            ? theme.strokeOpacity
                            : theme.opacity
                        }
                        strokeLinejoin="round"
                        style={{ strokeWidth: 5 }} // CSS의 strokeWidth 속성을 사용하여 선의 굵기를 조절합니다.
                      />
                    </g>
                  );
                })}

                {dimmedBoxes.flatMap((box: BoxDrawing, idx) => {
                  const theme = getBoxTheme(box.id, box.theme, box.page);
                  const [tl, bl, br, tr] = box.points;
                  const [w, h] = [br[0] - tl[0], br[1] - tl[1]];
                  const [minX, minY, maxX, maxY] = [
                    tl[0] - w * 0.1,
                    tl[1] - h * 0.1,
                    br[0] + w * 0.1,
                    br[1] + h * 0.1,
                  ];
                  const [x, y] = [minX, maxY];
                  return (
                    <g key={"L" + box.id + box.points.join(" ")}>
                      <path
                        className={theme ? theme.fill : ""}
                        key={"L" + box.id}
                        d={`M${x} ${y - 8}L${x} ${y}L${x + 8} ${y}C${x + 4} ${y} ${x} ${y - 4} ${x} ${y - 8}Z`}
                        clipRule={"evenodd"}
                        fillOpacity={theme.opacity}
                        strokeOpacity={
                          theme.strokeOpacity !== undefined
                            ? theme.strokeOpacity
                            : theme.opacity
                        }
                        strokeLinejoin="round"
                        style={{ strokeWidth: 5 }} // CSS의 strokeWidth 속성을 사용하여 선의 굵기를 조절합니다.
                      />
                    </g>
                  );
                })}

                {dimmedBoxes.flatMap((box: BoxDrawing, idx) => {
                  const theme = getBoxTheme(box.id, box.theme, box.page);
                  const [tl, bl, br, tr] = box.points;
                  const [w, h] = [br[0] - tl[0], br[1] - tl[1]];
                  const [minX, minY, maxX, maxY] = [
                    tl[0] - w * 0.1,
                    tl[1] - h * 0.1,
                    br[0] + w * 0.1,
                    br[1] + h * 0.1,
                  ];
                  const [x, y] = [maxX, minY];
                  return (
                    <g key={"L" + box.id + box.points.join(" ")}>
                      <path
                        className={theme ? theme.fill : ""}
                        key={"L" + box.id}
                        d={`M${x} ${y + 8}L${x} ${y}L${x - 8} ${y}C${x - 4} ${y} ${x} ${y + 4} ${x} ${y + 8}Z`}
                        fillOpacity={theme.opacity}
                        strokeOpacity={
                          theme.strokeOpacity !== undefined
                            ? theme.strokeOpacity
                            : theme.opacity
                        }
                        strokeLinejoin="round"
                        style={{ strokeWidth: 5 }} // CSS의 strokeWidth 속성을 사용하여 선의 굵기를 조절합니다.
                      />
                    </g>
                  );
                })}

                {dimmedBoxesO.map((box: BoxDrawing, idx) => {
                  const theme = getBoxTheme(box.id, box.theme, box.page);
                  return (
                    <g key={"L" + box.id + box.points.join(" ")}>
                      <path
                        className={theme ? theme.fill : ""}
                        key={"L" + box.id}
                        d={`M ${box.points.join("L")}Z`}
                        fillOpacity={theme.opacity}
                        strokeOpacity={
                          theme.strokeOpacity !== undefined
                            ? theme.strokeOpacity
                            : theme.opacity
                        }
                        strokeLinejoin="round"
                        style={{ strokeWidth: 5 }} // CSS의 strokeWidth 속성을 사용하여 선의 굵기를 조절합니다.
                      />
                    </g>
                  );
                })}
                {isDRSP
                  ? boxes
                      .filter(
                        box =>
                          box?.points.length > 0 &&
                          (box.page === page + 1 || box.page === 0),
                      )
                      .map((box: BoxDrawing, idx) => {
                        let theme = getBoxTheme(box.id, box.theme, box.page);
                        if (box.page === 0) {
                          theme = getBoxTheme(box.id, box.theme, page + 1);
                        }
                        const r = 12;
                        return (
                          <g
                            key={box.id + box.points.join(" ") + idx}
                            ref={el =>
                              (boxRef.current[idx] = el as SVGGElement)
                            }
                          >
                            <path
                              className={theme ? theme.fill : ""}
                              key={box.id + "id:" + idx}
                              d={`M ${box.points.join("L")}Z`}
                              onMouseEnter={() => {
                                if (box.page === page + 1) {
                                  setImageHoveredBoxIds(
                                    box.groupIds ? box.groupIds : [box.id],
                                  );
                                  if (focusIndex === -1) {
                                    setHoveredBoxIds(
                                      box.groupIds ? box.groupIds : [box.id],
                                    );
                                  }
                                }
                              }}
                              onMouseLeave={() => {
                                if (box.page === page + 1) {
                                  setImageHoveredBoxIds([]);
                                  if (focusIndex === -1) {
                                    setHoveredBoxIds([]);
                                  }
                                }
                              }}
                              fillOpacity={theme.opacity}
                              strokeOpacity={
                                theme.strokeOpacity !== undefined
                                  ? theme.strokeOpacity
                                  : theme.opacity
                              }
                              strokeLinejoin="round"
                              style={{ strokeWidth: 5 }} // CSS의 strokeWidth 속성을 사용하여 선의 굵기를 조절합니다.
                              onClick={e => {
                                if (box.page === page + 1 && allowBoxClick) {
                                  e.stopPropagation();
                                  setSelectedBoxIds(
                                    box.groupIds ? box.groupIds : [box.id],
                                  );
                                  setHoveredBoxIds(
                                    box.groupIds ? box.groupIds : [box.id],
                                  );
                                }
                              }}
                            />
                          </g>
                        );
                      })
                  : largestBoxes
                      .filter(box => box?.points.length > 0)
                      .map((box: BoxDrawing, idx) => {
                        const theme = getBoxTheme(box.id, box.theme, box.page);
                        const r = 12;
                        return (
                          <g
                            key={box.id + box.points.join(" ") + idx}
                            ref={el =>
                              (boxRef.current[idx] = el as SVGGElement)
                            }
                          >
                            <path
                              className={theme ? theme.fill : ""}
                              key={box.id + "id:" + idx}
                              d={`
                          M ${box.points[0][0] + r},${box.points[0][1]}
                          H ${Math.max(box.points[3][0] - r, 0)}
                          C ${box.points[3][0]},${box.points[3][1]} ${box.points[3][0]},${box.points[3][1] + r} ${box.points[3][0]},${box.points[3][1] + r}
                          V ${Math.max(box.points[2][1] - r, 0)}
                          C ${box.points[2][0]},${box.points[2][1]} ${Math.max(box.points[2][0] - r, 0)},${box.points[2][1]} ${Math.max(box.points[2][0] - r, 0)},${box.points[2][1]}
                          H ${box.points[1][0] + r}
                          C ${box.points[1][0]},${box.points[1][1]} ${box.points[1][0]},${Math.max(box.points[1][1] - r, 0)} ${box.points[1][0]},${Math.max(box.points[1][1] - r, 0)}
                          V ${box.points[0][1] + r}
                          C ${box.points[0][0]},${box.points[0][1]} ${box.points[0][0] + r},${box.points[0][1]} ${box.points[0][0] + r},${box.points[0][1]}
                          Z
                        `}
                              onMouseEnter={() => {
                                if (box.page === page + 1) {
                                  setImageHoveredBoxIds(
                                    box.groupIds ? box.groupIds : [box.id],
                                  );
                                  if (focusIndex === -1) {
                                    setHoveredBoxIds(
                                      box.groupIds ? box.groupIds : [box.id],
                                    );
                                  }
                                }
                              }}
                              onMouseLeave={() => {
                                if (box.page === page + 1) {
                                  setImageHoveredBoxIds([]);
                                  if (focusIndex === -1) {
                                    setHoveredBoxIds([]);
                                  }
                                }
                              }}
                              fillOpacity={theme.opacity}
                              strokeOpacity={
                                theme.strokeOpacity !== undefined
                                  ? theme.strokeOpacity
                                  : theme.opacity
                              }
                              strokeLinejoin="round"
                              style={{ strokeWidth: 5 }} // CSS의 strokeWidth 속성을 사용하여 선의 굵기를 조절합니다.
                              onClick={e => {
                                if (box.page === page + 1 && allowBoxClick) {
                                  e.stopPropagation();
                                  setSelectedBoxIds(
                                    box.groupIds ? box.groupIds : [box.id],
                                  );
                                  setHoveredBoxIds(
                                    box.groupIds ? box.groupIds : [box.id],
                                  );
                                }
                              }}
                            />
                          </g>
                        );
                      })}

                {ocrBoxes
                  .filter(
                    box => box?.points.length > 0 && box.page === page + 1,
                  )
                  .map((box: BoxDrawing, idx) => {
                    const theme = getBoxTheme(box.id, box.theme, box.page);
                    const r = 12;
                    return (
                      <g
                        key={box.id + box.points.join(" ") + idx}
                        ref={el => (boxRef.current[idx] = el as SVGGElement)}
                      >
                        <path
                          className={theme ? theme.fill : ""}
                          key={box.id + "id:" + idx}
                          d={`M ${box.points.join("L")}Z`}
                          onMouseEnter={() => {
                            if (box.page === page + 1) {
                              setImageHoveredBoxIds(
                                box.groupIds ? box.groupIds : [box.id],
                              );
                              if (focusIndex === -1) {
                                setHoveredBoxIds(
                                  box.groupIds ? box.groupIds : [box.id],
                                );
                              }
                            }
                          }}
                          onMouseLeave={() => {
                            if (box.page === page + 1) {
                              setImageHoveredBoxIds([]);
                              if (focusIndex === -1) {
                                setHoveredBoxIds([]);
                              }
                            }
                          }}
                          fillOpacity={theme.opacity}
                          strokeOpacity={
                            theme.strokeOpacity !== undefined
                              ? theme.strokeOpacity
                              : theme.opacity
                          }
                          strokeLinejoin="round"
                          style={{ strokeWidth: 5 }} // CSS의 strokeWidth 속성을 사용하여 선의 굵기를 조절합니다.
                          onClick={e => {
                            if (box.page === page + 1 && allowBoxClick) {
                              e.stopPropagation();
                              setSelectedBoxIds(
                                box.groupIds ? box.groupIds : [box.id],
                              );
                              setHoveredBoxIds(
                                box.groupIds ? box.groupIds : [box.id],
                              );
                            }
                          }}
                        />
                      </g>
                    );
                  })}

                {laBoxes
                  .filter(
                    box => box?.points.length > 0 && box.page === page + 1,
                  )
                  .map((box: BoxDrawing, idx) => {
                    const theme = getBoxTheme(box.id, box.theme, box.page);
                    const r = 12;
                    return (
                      <g
                        key={box.id + box.points.join(" ") + idx}
                        ref={el => (boxRef.current[idx] = el as SVGGElement)}
                      >
                        <path
                          className={theme ? theme.fill : ""}
                          key={box.id + "id:" + idx}
                          d={`M ${box.points.join("L")}Z`}
                          onMouseEnter={() => {
                            if (box.page === page + 1) {
                              setImageHoveredBoxIds(
                                box.groupIds ? box.groupIds : [box.id],
                              );
                              if (focusIndex === -1) {
                                setHoveredBoxIds(
                                  box.groupIds ? box.groupIds : [box.id],
                                );
                              }
                            }
                          }}
                          onMouseLeave={() => {
                            if (box.page === page + 1) {
                              setImageHoveredBoxIds([]);
                              if (focusIndex === -1) {
                                setHoveredBoxIds([]);
                              }
                            }
                          }}
                          fillOpacity={theme.opacity}
                          strokeOpacity={
                            theme.strokeOpacity !== undefined
                              ? theme.strokeOpacity
                              : theme.opacity
                          }
                          strokeLinejoin="round"
                          style={{ strokeWidth: 5 }} // CSS의 strokeWidth 속성을 사용하여 선의 굵기를 조절합니다.
                          onClick={e => {
                            if (box.page === page + 1 && allowBoxClick) {
                              e.stopPropagation();
                              setSelectedBoxIds([box.id]);
                              setHoveredBoxIds([box.id]);
                            }
                          }}
                        />
                      </g>
                    );
                  })}
              </svg>
            </div>
          </div>
        ) : (
          <div className="flex flex-col items-center justify-center h-full">
            <NothingSelected />
          </div>
        )}
      </div>
    </>
  );
};

export default observer(ParsingImageView);
