import type { SVGProps } from "react";

export function FileOpenerClosed(props: SVGProps<SVGSVGElement>) {
  return (
<svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M18.0022 9.98422L5 12.0487V10.8338L18.0022 7.0658V9.98422Z" fill="#353741"/>
  <path d="M18.0022 14.7198L5 15.204V13.9891L18.0022 11.8014V14.7198Z" fill="#353741"/>
  <path d="M18.0022 26.0159L5 23.9514V25.1664L18.0022 28.9343V26.0159Z" fill="#353741"/>
  <path d="M18.0022 21.2786L5 20.79V22.0093L18.0022 24.197V21.2786Z" fill="#353741"/>
  <path d="M18.0022 19.4576L5 18.608V17.3931L18.0022 16.5436V19.4576Z" fill="#353741"/>
  <path d="M18.0022 9.98422L28.7363 11.8066L28.8961 11.2343L30.9998 11.4412V10.8338L18.0022 7.0658V9.98422Z" fill="#353741"/>
  <path d="M18.0022 14.7198L27.6607 15.0852L27.8637 14.5041L30.9998 14.5966V13.9891L18.0022 11.8014V14.7198Z" fill="#353741"/>
  <path d="M18.0022 26.0159L30.9998 23.9514V24.5589L20.4685 27.4289L20.2223 28.2608L18.0022 28.9343V26.0159Z" fill="#353741"/>
  <path d="M18.0022 21.2786L30.9998 20.79V21.3975L25.5398 21.9653L25.2633 22.9337L18.0022 24.197V21.2786Z" fill="#353741"/>
  <path d="M18.0022 19.4576L29.8076 18.608L29.9631 18.071L30.9998 18.0006V17.3931L18.0022 16.5436V19.4576Z" fill="#353741"/>
</svg>


  );
}
