/* eslint-disable react-hooks/exhaustive-deps */
import type { Dispatch, SetStateAction } from "react";
import { useCallback, useEffect, useState } from "react";
import { ImageOacJson } from "../types/demo";
import * as matrix from "../utils/drawing/matrix";
import * as rendering from "../utils/drawing/rendering";
import type { Transform } from "../utils/drawing/types";
import { imageViewStore } from "../utils/mobx/ImageViewStore";

export const useImageRendering = (
  file: ImageOacJson,
  imageScale: number,
  reset: boolean,
  imageScaleChangeHandler: (scale: number) => void,
  setCssTransform: Dispatch<SetStateAction<string>>,
  degree: number,
) => {
  const [transform, setTransform] = useState<Transform>({
    scale: 1,
    rotation: 0,
    tx: 0,
    ty: 0,
    matrix: matrix.identity(),
  });
  const [canvasSize, setCanvasSize] = useState<{
    width: number;
    height: number;
  }>({ width: 0, height: 0 });

  const [offset, setMouseMoveOffset] = useState<{
    x: number;
    y: number;
  }>({ x: 0, y: 0 });
  const [imageSize, setImageSize] = useState<{ width: number; height: number }>(
    { width: 0, height: 0 },
  );

  useEffect(() => {
    if (!file) return;
    const t0 = rendering.getImageTransform(
      canvasSize.width,
      canvasSize.height - 150,
      imageSize.width,
      imageSize.height,
    );
    const t = rendering.panTo({
      x: 0,
      y: 60,
      xform: t0,
    });
    if (t === transform) return;
    setTransform(t);
    setCssTransform(rendering.getCssTransform(t));
    imageScaleChangeHandler(t.scale);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    canvasSize.width,
    canvasSize.height,
    file,
    imageSize.width,
    imageSize.height,
  ]);

  useEffect(() => {
    if (!file) return;
    const t0 = rendering.getImageTransform(
      canvasSize.width,
      canvasSize.height - 150,
      imageSize.width,
      imageSize.height,
    );
    const t = rendering.panTo({
      x: 0,
      y: 60,
      xform: t0,
    });
    const newXform = rendering.rotateBy({
      degree: t.rotation,
      xform: t,
      anchorx: canvasSize.width / 2,
      anchory: canvasSize.height / 2,
    });
    setTransform(newXform);
    setCssTransform(rendering.getCssTransform(newXform));
    imageScaleChangeHandler(t.scale);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reset]);

  useEffect(() => {
    const t = rendering.zoomTo({
      newScale: imageScale,
      anchorx: canvasSize.width / 2,
      anchory: canvasSize.height / 2,
      xform: transform,
    });
    setTransform(t);
    setCssTransform(rendering.getCssTransform(t));
    imageScaleChangeHandler(t.scale);
  }, [imageScale]);

  // 마우스로 이미지를 이동시키는 이벤트 헨들러.
  useEffect(() => {
    const t = rendering.panTo({
      x: offset.x,
      y: offset.y,
      xform: transform,
    });
    setTransform(t);
    setCssTransform(rendering.getCssTransform(t));
  }, [offset]);

  const rotateBy = useCallback(
    (degree: number) => {
      const anchorx = canvasSize.width / 2;
      const anchory = canvasSize.height / 2;

      // 기존 transform에서 맵핑한 픽셀에서 출발해서 추가 회전을 한다.
      // 따라서 anchorx, anchory 좌표는 함수 호출 시점에 화면에 보이는 좌표를 중점으로
      // 회전을 시킨다.
      const newXform = rendering.rotateBy({
        degree,
        xform: transform,
        anchorx,
        anchory,
      });
      setTransform(newXform);
      setCssTransform(rendering.getCssTransform(newXform));
    },
    [setCssTransform, transform],
  );

  const moveAndFocus = useCallback(
    (x: number, y: number, boxSize: number[], minRatio: number): number => {
      const { maxRatio } = imageViewStore;
      const canvasW = canvasSize.width;
      const canvasH = canvasSize.height;
      const imgw = imageSize.width;
      const imgh = imageSize.height;
      const boxW = boxSize[0];
      const boxH = boxSize[1];
      let r = 1;
      if (boxW === 0 || boxH === 0) return r;
      const deg2 = transform.rotation;
      // 적절한 scale 계산하기.
      const scaleW = canvasW / imgw;
      const scaleH = canvasH / imgh;
      // 박스의 크기가 화면의 N비율만큼 차지하게 한다.
      let scale = Math.min(
        (canvasW / boxW) * minRatio,
        (canvasH / boxH) * minRatio,
      );
      // 확대 비율은 maxRatio를 넘지 않는다.
      scale = Math.min(scale, Math.min(scaleH, scaleW, 1) * maxRatio);
      // 화면비율이 maxRatio를 넘기더라도 기본 값보다 작으면 확대하지 않는다.
      scale = Math.max(scale, Math.min(scaleH, scaleW, 1));

      // 박스의 크기가 화면으 minRation 보다 이미 큰경우 확대는 하지 않는다.
      if (scale < minRatio) {
        scale = Math.min(scaleH, scaleW, 1);
      }

      const t2 = rendering.rotateBy({
        degree: -deg2,
        xform: transform,
        anchorx: canvasW / 2,
        anchory: canvasH / 2,
      });

      const t3 = rendering.focus({
        newScale: scale,
        anchorx: canvasW / 2 - scale * x,
        anchory: canvasH / 2 - scale * y,
        xform: t2,
      });

      // 기존 transform에서 맵핑한 픽셀에서 출발해서 추가 회전을 한다.
      // 따라서 anchorx, anchory 좌표는 함수 호출 시점에 화면에 보이는 좌표를 중점으로
      // 회전을 시킨다.
      const t4 = rendering.rotateBy({
        degree: deg2,
        xform: t3,
        anchorx: canvasW / 2,
        anchory: canvasH / 2,
      });
      setTransform(t4);
      setCssTransform(rendering.getCssTransform(t4));
      imageScaleChangeHandler(t4.scale);
      return r;
    },
    [
      imageScaleChangeHandler,
      imageSize.height,
      imageSize.width,
      setCssTransform,
      transform,
    ],
  );

  return {
    canvasSizeChangeHandler: setCanvasSize,
    canvasObjectMoveHandler: setMouseMoveOffset,
    imageSizeChangeHandler: setImageSize,
    rotateBy,
    moveAndFocus: moveAndFocus,
  };
};
