import { observer } from "mobx-react";
import Prism from "prismjs";
import "prismjs/components/prism-json"; // Language
import "prismjs/themes/prism.css"; // Theme
import { FC, useEffect, useState } from "react";
import ErrorModal from "../components/Modal/ErrorModal";
import { TABLE_ABLE } from "../constants/Features";
import { classnames } from "../utils/classnames";
import { parsingLAStore } from "../utils/mobx/ParsingLAStore";
import { parsingTableStore } from "../utils/mobx/ParsingTableStore";
import { resultInteractionStore } from "../utils/mobx/ResultInteractionStore";

interface ParsingResultLAProps {
  disabled?: boolean;
}

const ParsingResultLA: FC<ParsingResultLAProps> = ({ disabled = false }) => {
  const {
    setAdditionalFocusIndexes,
    additionalFocusIndexes,
    hoveredBoxIds,
    setHoveredBoxIds,
    selectedBoxIds,
    setSelectedBoxIds,
    focusIndex: curIndex,
    setFocusIndex: setIndex,
    setFocusEnable,
  } = resultInteractionStore;
  const { confidenceThreshold, lineModalOn, setLineModalOn } =
    parsingTableStore;
  const { laElements, values, orgLargestId } = parsingLAStore;
  const [isRowClicked, setIsRowClicked] = useState(false);
  const [errorOn, setErrorOn] = useState<boolean>(false);
  const [reloadFunction, setReloadFunction] = useState<() => void>(() => {});
  const [errorType, setErrorType] = useState<number>(1);
  const [blockType, setBlockType] = useState<string>("center");
  const [hoverIndex, setHoverIndex] = useState<number>(-1);
  // const [hoverTableIndex, setHoverTableIndex] = useState<number>(-1);

  // html 파싱하기

  useEffect(() => {
    Prism.highlightAll();
  }, [values]);

  useEffect(() => {
    // 이미지뷰에서 박스가 클릭되었을때만 자동 스크롤
    if (isRowClicked) {
      setIsRowClicked(false);
      return;
    }
    if (selectedBoxIds.length) {
      const boxId = selectedBoxIds[0];
      document.getElementById(`row-${boxId}`)?.scrollIntoView({
        behavior: "smooth" as ScrollBehavior,
        block: "center" as ScrollLogicalPosition,
      });
      laElements.forEach((laElement, idx) => {
        if (
          selectedBoxIds.includes(
            `page-${laElement.page}-element-${laElement.id}`,
          )
        ) {
          setIndex(idx);
          return;
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedBoxIds]);

  return (
    <>
      <ErrorModal
        open={errorOn}
        setOpen={setErrorOn}
        errorType={errorType}
        closeFunction={() => {}}
        reloadFunction={reloadFunction}
      />
      <div>
        {laElements.length > 0 && (
          <div className="flex flex-col my-4 p-4 w-[calc(100%-1.5rem)] bg-white rounded-lg border-balck min-w-full overflow-auto --body-sm items-end">
            <table className="flex flex-col w-full order-collapse ">
              <tbody className="flex flex-col items-start justify-start w-full rounded">
                {laElements.map((laElement, idx) => {
                  const linecontent = values[idx];
                  return laElement ? (
                    <tr
                      className={classnames({
                        "bg-blue-50  border-grey-400 border-opacity-10":
                          !disabled &&
                          (hoverIndex === idx ||
                            curIndex === idx ||
                            (hoverIndex === -1 &&
                              hoveredBoxIds.includes(
                                `page-${laElement.page}-element-${laElement.id}`,
                              )) ||
                            (curIndex === -1 &&
                              selectedBoxIds.includes(
                                `page-${laElement.page}-element-${laElement.id}`,
                              ))),

                        "bg-white border-transparent":
                          disabled ||
                          !(
                            hoverIndex === idx ||
                            curIndex === idx ||
                            (hoverIndex === -1 &&
                              hoveredBoxIds.includes(
                                `page-${laElement.page}-element-${laElement.id}`,
                              )) ||
                            (curIndex === -1 &&
                              selectedBoxIds.includes(
                                `page-${laElement.page}-element-${laElement.id}`,
                              ))
                          ),
                        "w-full text-left text-black text-opacity-90 break-words rounded my-1":
                          true,
                      })}
                      id={`row-page-${laElement.page}-element-${laElement.id}`}
                      key={`idx:${idx}`}
                      onMouseEnter={e => {
                        if (curIndex === -1) {
                          setHoveredBoxIds([
                            `page-${laElement.page}-element-${laElement.id}`,
                          ]);
                          setHoverIndex(idx);
                        }
                      }}
                      onMouseLeave={e => {
                        if (curIndex === -1) {
                          setHoveredBoxIds([]);
                          setHoverIndex(-1);
                        }
                      }}
                      onClick={e => {
                        e.stopPropagation();
                        setIsRowClicked(true);
                        if (laElement.id < 0 || laElement.id > orgLargestId) {
                          setFocusEnable(false);
                        } else {
                          setFocusEnable(true);
                        }
                        if (curIndex !== -1 && (e.ctrlKey || e.metaKey)) {
                          if (additionalFocusIndexes.length === 0) {
                            (
                              document.activeElement as
                                | HTMLInputElement
                                | HTMLTextAreaElement
                            ).blur();
                          }
                        } else {
                          // 박스가 있을때만
                          if (laElement.bounding_box) {
                            setSelectedBoxIds([
                              `page-${laElement.page}-element-${laElement.id}`,
                            ]);
                          } else {
                            setSelectedBoxIds([]);
                          }
                          setIndex(idx);
                          setHoveredBoxIds([]);
                          setHoverIndex(-1);
                          setAdditionalFocusIndexes([]);
                        }
                      }}
                    >
                      <td
                        className={classnames({
                          "opacity-90 w-full flex": true,
                          "whitespace-normal": true,
                          "shadow-inline":
                            idx === curIndex ||
                            additionalFocusIndexes.includes(idx),
                        })}
                        id={`td-${idx}`}
                      >
                        <div
                          className={classnames({
                            "whitespace-pre-line focus:outline-none px-1 flex items-center":
                              true,
                            "resize-none": true,
                            "bg-transparent": true,
                            "whitespace-normal": true,
                            "pointer-events-none": !TABLE_ABLE,
                            "px-4 py-2": true,
                          })}
                          id={"div-" + idx}
                        >
                          {" "}
                          <code
                            className="language-html"
                            style={{ backgroundColor: "transparent" }}
                          >
                            {linecontent}
                          </code>
                        </div>
                      </td>
                    </tr>
                  ) : null;
                })}
              </tbody>
            </table>
          </div>
        )}
      </div>
    </>
  );
};

export default observer(ParsingResultLA);
