import type { SVGProps } from "react";

export function NoHistory(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="212"
      height="144"
      viewBox="0 0 212 144"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M119.984 68.6038C133.145 59.352 133.171 50.9783 132.747 39.0228H78.2495C77.8252 50.9783 77.8511 59.352 91.0116 68.6038C91.0116 68.6038 92.4989 70.138 92.633 71.8617C92.7671 73.5853 92.9235 75.0135 91.0124 76.5489C77.8519 85.8008 76.0123 95.9882 76.4365 107.944H134.56C134.984 95.9882 133.144 85.8008 119.984 76.5489C118.073 75.0135 118.229 73.5853 118.363 71.8617C118.497 70.138 119.984 68.6038 119.984 68.6038Z"
        fill="#F6F7F9"
      />
      <path
        d="M76.7344 31.9069C76.7344 31.406 77.1404 31 77.6412 31H133.356C133.857 31 134.263 31.406 134.263 31.9069V40.0685H76.7344V31.9069Z"
        fill="#E0E1E7"
      />
      <path
        d="M75 103.409H136V111.571C136 112.072 135.594 112.478 135.093 112.478H75.9069C75.406 112.478 75 112.072 75 111.571V103.409Z"
        fill="#E0E1E7"
      />
      <path
        d="M95.8599 64.9719C93.1447 63.3954 87.0306 58.7009 86.413 52.0982C86.3233 51.1391 87.134 50.3811 88.0974 50.3811H105.5V67.6461C102.497 67.5218 98.8049 66.682 95.8599 64.9719Z"
        fill="url(#paint0_linear_2086_34845)"
      />
      <path
        d="M115.144 64.9719C117.86 63.3954 123.974 58.7009 124.591 52.0982C124.681 51.1391 123.87 50.3811 122.907 50.3811H105.505V67.6461C108.508 67.5218 112.199 66.682 115.144 64.9719Z"
        fill="url(#paint1_linear_2086_34845)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_2086_34845"
          x1="106"
          y1="73.5"
          x2="106"
          y2="43.5"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#C6C8D2" />
          <stop offset="1" stopColor="#E2E3E9" stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_2086_34845"
          x1="106"
          y1="73.5"
          x2="106"
          y2="43.5"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#C6C8D2" />
          <stop offset="1" stopColor="#E2E3E9" stopOpacity="0" />
        </linearGradient>
      </defs>
    </svg>
  );
}
