import { Point } from "./demo";

export interface Property {
  type: string;
  value: string;
  id: number;
  key: string;
  confidence: number | null;
  refinedValue: string;
  boundingBoxes?: BoundingBox[];
}
export interface BoxDrawing {
  id: string;
  points: [Point, Point, Point, Point];
  theme: "default" | "warning" | "large" | "dimmed";
  groupIds: string[];
  page: number;
  // entity: string | null;
}

export interface BoundingBox {
  page: number;
  vertices: {
    x: number;
    y: number;
  }[];
}

export interface Theme {
  default: {
    fill: string;
    opacity: number;
    strokeOpacity: number;
  };
  hovered: {
    fill: string;
    opacity: number;
    strokeOpacity: number;
  };
  selected: {
    fill: string;
    opacity: number;
    strokeOpacity: number;
  };
  corrected: {
    fill: string;
    opacity: number;
    strokeOpacity: number;
  };
}
export const BoxTheme: Theme = {
  default: {
    fill: "relative",
    opacity: 0.0,
    strokeOpacity: 0.0,
  },
  hovered: {
    fill: "relative stroke-white fill-blue-400",
    opacity: 0.3,
    strokeOpacity: 0.4,
  },
  selected: {
    fill: "relative stroke-white fill-blue-400",
    opacity: 0.3,
    strokeOpacity: 0.4,
  },
  corrected: {
    fill: "relative",
    opacity: 0.0,
    strokeOpacity: 0.0,
  },
};
export const LargeBoxTheme: Theme = {
  default: {
    fill: "relative",
    opacity: 0.0,
    strokeOpacity: 0.0,
  },
  hovered: {
    fill: "relative stroke-white fill-blue-400",
    opacity: 0.3,
    strokeOpacity: 0.4,
  },
  selected: {
    fill: "relative stroke-white fill-blue-400",
    opacity: 0.3,
    strokeOpacity: 0.4,
  },
  corrected: {
    fill: "relative",
    opacity: 0.0,
    strokeOpacity: 0.0,
  },
};

export const DimmedBoxTheme: Theme = {
  default: {
    fill: "relative",
    opacity: 0.0,
    strokeOpacity: 0.0,
  },
  hovered: {
    fill: "relative fill-grey-300",
    opacity: 0.0,
    strokeOpacity: 0.0,
  },
  selected: {
    fill: "relative fill-grey-300",
    opacity: 0.0,
    strokeOpacity: 0.0,
  },
  corrected: {
    fill: "relative",
    opacity: 0.0,
    strokeOpacity: 0.0,
  },
};
export const WarningBoxTheme: Theme = {
  default: {
    fill: "relative",
    opacity: 0.0,
    strokeOpacity: 0.0,
  },
  hovered: {
    fill: "relative stroke-white fill-blue-400",
    opacity: 0.3,
    strokeOpacity: 0.4,
  },
  selected: {
    fill: "relative stroke-white fill-blue-400",
    opacity: 0.3,
    strokeOpacity: 0.4,
  },
  corrected: {
    fill: "relative",
    opacity: 0.0,
    strokeOpacity: 0.0,
  },
};
