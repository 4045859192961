import { z } from "zod";

export const ZodVertex = z.object({ x: z.number(), y: z.number() });
export type Vertex = z.infer<typeof ZodVertex>;

export const ZodBox = z.object({
  vertices: z.array(ZodVertex),
});
export type Box = z.infer<typeof ZodBox>;

export const ZodWordInResponse = z.object({
  boundingBox: ZodBox,
  confidence: z.number(),
  id: z.number(),
  text: z.string(),
});
export type WordInResponse = z.infer<typeof ZodWordInResponse>;

export const ZodPageInResponse = z.object({
  confidence: z.number(),
  height: z.number(),
  id: z.number(),
  text: z.string(),
  width: z.number(),
  words: z.array(ZodWordInResponse),
});
export type PageInResponse = z.infer<typeof ZodPageInResponse>;

export const ZodPaperMetaData = z.object({
  height: z.number(),
  page: z.number(),
  width: z.number(),
});
export type PaperMetaData = z.infer<typeof ZodPaperMetaData>;
export const ZodPaperMetaDatum = z.object({
  pages: z.array(ZodPaperMetaData),
});
export type PaperMetadatum = z.infer<typeof ZodPaperMetaDatum>;

export const ZodWord = ZodWordInResponse;
export type Word = z.infer<typeof ZodWord>;

export const ZodLine = z.object({
  id: z.number(),
  words: z.array(ZodWord),
  text: z.string(),
  boundingBox: z.array(ZodBox),
  confidence: z.number(),
});
export type Line = z.infer<typeof ZodLine>;

export const ZodLineGroup = z.object({
  id: z.number(),
  page: z.number(),
  words: z.array(ZodWord),
  text: z.string(),
  boundingBox: z.array(ZodBox),
  confidence: z.number(),
});
export type LineGroup = z.infer<typeof ZodLineGroup>;

export const ZodPage = z.object({
  id: z.number(),
  width: z.number(),
  height: z.number(),
  words: z.array(ZodWord),
  lines: z.array(ZodLine),
  confidence: z.number(),
  text: z.string(),
});
export type OCRPage = z.infer<typeof ZodPage>;
export type OCRInferenceResult = OCRPage[];

export const ZodImageOCROacJson = z.object({
  code: z.number().default(200),
  filename: z.string(),
  apiVersion: z.string(),
  confidence: z.number(),
  pages: z.array(ZodPage),
  imageBase64: z.array(z.string()),
  mimeType: z.string(),
  modelVersion: z.string(),
  stored: z.boolean(),
  metadata: ZodPaperMetaDatum,
  text: z.string(),
});

export type ImageOCROacJson = z.infer<typeof ZodImageOCROacJson>;
export type OCROacJsonResponse = z.infer<typeof ZodImageOCROacJson>;

export function convertResponseToImageOCROacJson(
  file: OCROacJsonResponse,
): ImageOCROacJson {
  return {
    ...file,
    pages: file.pages.map(page => assignSerializedId(page)),
  };
}

function assignSerializedId(page: PageInResponse) {
  // assumption1: All lines exhaustively and exclusively include all words
  // assumption2: The words are in the same order as the lines
  const textLines = page.text.split(" \n");
  const lines: Line[] = [];

  // line 관련 변수들을 초기화한다.
  let wordsInLine: Word[] = [];
  let boundingBoxInLine: Box[] = [];
  let lineText = "";
  let confidenceInLine = 0;

  for (const word of page.words) {
    const currentLineText = textLines[lines.length];
    // word를 line 관련 변수들에 추가한다
    wordsInLine.push(word);
    boundingBoxInLine.push(word.boundingBox);
    lineText = lineText ? lineText + " " + word.text : word.text;
    confidenceInLine += word.confidence;

    if (lineText.trim() === currentLineText.trim()) {
      // word가 line의 마지막 word이면 line을 완성해서 추가한다.
      lines.push({
        id: lines.length + 1,
        words: wordsInLine,
        text: currentLineText,
        boundingBox: boundingBoxInLine,
        confidence: confidenceInLine / wordsInLine.length,
      });
      // line 관련 변수들을 초기화한다.
      wordsInLine = [];
      lineText = "";
      boundingBoxInLine = [];
      confidenceInLine = 0;
    }
  }
  return { ...page, lines };
}
