import React, { useContext } from "react";
import { Line, Group } from "../types/extractorResult";

export interface ChangedFile {
  groupChangedResult: Group[];
  lineChangedResult: Line[][];
  setGroupChangedResult: (t: Group[]) => void;
  setLineChangedResult: (t: Line[][]) => void;
}

export const emtpyChangedFile: ChangedFile = {
  groupChangedResult: [],
  lineChangedResult: [],
  setGroupChangedResult: (t: Group[]) => {},
  setLineChangedResult: (t: Line[][]) => {},
};

const ChangedFileContext = React.createContext<ChangedFile>(emtpyChangedFile);

export const ChangedFileProvider = ChangedFileContext.Provider;

export function useChangedResult(): ChangedFile {
  return useContext(ChangedFileContext);
}
