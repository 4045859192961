import { useEffect, useRef, useState } from "react";
import ReactModal from "react-modal";
import { EXCEED_NON_GROUPING_ADD } from "../../constants/ErrorMessage";
import { Line } from "../../types/extractorResult";
import { classnames } from "../../utils/classnames";
import { parsingTableStore } from "../../utils/mobx/ParsingTableStore";
import { resultInteractionStore } from "../../utils/mobx/ResultInteractionStore";
import { boxChangedFocusMover } from "../../utils/resultScrollHelper";
import { Strip } from "../../utils/stringUtil";
import { Button, ButtonStyle } from "../Button";
import { Icon, Icons } from "../Icon";
import ErrorModal from "./ErrorModal";

interface ModalComponent {
  isOpen: boolean;
  setIsOpen: (open: boolean) => void;
}

const customModalStyles: ReactModal.Styles = {
  overlay: {
    backgroundColor: " rgba(0, 0, 0, 0.8)",
    width: "100%",
    height: "100vh",
    zIndex: "10",
    position: "fixed",
    top: "0",
    left: "0",
  },
  content: {
    width: "fixed",
    minWidth: "500px",
    maxWidth: "500px",
    height: "auto",
    minHeight: "624px",
    maxHeight: "680px",
    zIndex: "150",
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    borderRadius: "8px",
    boxShadow: "2px 2px 2px rgba(0, 0, 0, 0.25)",
    backgroundColor: "white",
    justifyContent: "center",
    overflow: "auto",
  },
};

const getFieldCounts = (lines: Line[], key: string) => {
  return lines.filter(line => line.key === key).length;
};

export default function NonGroupingAddModal({
  isOpen,
  setIsOpen,
}: ModalComponent) {
  const [checked, setChecked] = useState<number>(-1);
  const [errorOn, setErrorOn] = useState<boolean>(false);
  const ref = useRef<HTMLDivElement>(null);
  const [errorType, setErrorType] = useState<number>(1);
  const [keyIdx, setKeyIndex] = useState<number>(-1);
  const [modalHeight, setModalHeight] = useState(0); // 초기 모달 높이
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [closeFunction, setCloseFunction] = useState<() => void>(() => {});
  const [reloadFunction, setReloadFunction] = useState<() => void>(() => {});
  const { ontologies, addLineByKey, lineGroups } = parsingTableStore;
  const [lineKeys, setLinekeys] = useState<string[]>([]);
  const { setFocusIndex, setSelectedBoxIds } = resultInteractionStore;

  useEffect(() => {
    setKeyIndex(-1);
    setChecked(-1);
  }, [isOpen]);

  useEffect(() => {
    const onthologySet: Set<string> = new Set();
    if (!ontologies) return;
    // nonGrouping영역만.
    ontologies
      .filter(ontology => ontology.group_type === -1)
      .forEach(ontology => {
        onthologySet.add(Strip(Strip(ontology.key, ".header"), ".content"));
      });
    setLinekeys(Array.from(onthologySet));
  }, [ontologies]);

  useEffect(() => {
    // 윈도우 창 크기가 변경될 때마다 모달 높이를 업데이트
    const handleResize = () => {
      const height = document.getElementById("React-Modal")?.clientHeight;
      const contentHeight = 265 + (height ? height : 500) - 500;
      setModalHeight(contentHeight);
    };
    handleResize(); // 처음 렌더링 시 모달 높이를 설정
    window.addEventListener("resize", handleResize);
    window.addEventListener("mouseover", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
      window.removeEventListener("mouseover", handleResize);
    };
  }, []);

  useEffect(() => {
    const listener = (e: MouseEvent) => {
      if (errorOn || !ref.current || ref.current.contains(e.target as Node))
        return;
      setIsOpen(false);
    };
    document.addEventListener("mousedown", listener);
    return () => {
      document.removeEventListener("mousedown", listener);
    };
  }, [ref, errorOn, setIsOpen]);
  return (
    <>
      <ErrorModal
        open={errorOn}
        setOpen={setErrorOn}
        errorType={errorType}
        closeFunction={closeFunction}
        reloadFunction={reloadFunction}
      />
      <ReactModal
        isOpen={isOpen}
        style={customModalStyles}
        id="React-Modal"
        ariaHideApp={false}
      >
        <div
          className="z-10 flex flex-col justify-between h-full"
          ref={ref}
          onClick={e => {
            e.stopPropagation();
          }}
        >
          <div className="flex flex-col justify-between h-full">
            <div className="p-3">
              <div className="text-gray-700 --heading-lg">Add field</div>
              <div className="mt-1 text-gray-700 --body-sm">
                Select the item to add a field.
              </div>
            </div>

            <div className="flex flex-col h-full mt-2">
              <div className="flex justify-start h-full gap-5 m-3">
                <div className="flex-col justify-start flex-1">
                  <div
                    className={`flex p-4 mt-2 border overflow-y-scroll border-gray-100 rounded-lg bg-gray-25`}
                    style={{ height: modalHeight }}
                  >
                    <fieldset className="w-full">
                      <div>
                        <div className="mx-2 mt-2 divide-y divide-gray-100">
                          {lineKeys.map((key, idx) => (
                            <div
                              onClick={() => {
                                setKeyIndex(idx);
                                setChecked(idx);
                              }}
                              className={classnames({
                                "text-gray-500 --body-sm": idx !== checked,
                                "text-gray-900 --heading-ss": idx === checked,
                              })}
                              key={idx}
                            >
                              <div
                                key={idx}
                                className="relative flex items-center h-9 min-w-[340px] max-w-[390px]"
                              >
                                <div className="flex-1 min-w-0 leading-[18px]">
                                  <label
                                    htmlFor={`key-${idx}`}
                                    className="select-none"
                                  >
                                    {key}
                                  </label>
                                </div>
                                {
                                  <div
                                    className={`flex items-center h-6 ml-3 ${checked === idx ? "" : "invisible"}`}
                                  >
                                    <Icon source={Icons.Check} />
                                  </div>
                                }
                              </div>
                            </div>
                          ))}
                        </div>
                      </div>
                    </fieldset>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="flex justify-end flex-1 mx-2 mt-5 mb-1">
            <Button
              onClick={() => {
                setIsOpen(false);
              }}
              text="Cancel"
              style={ButtonStyle.Tertiary}
              className="mr-2"
            />

            <button
              className={classnames({
                "select-none flex justify-center items-center space-x-2 --body-base":
                  true,
                "rounded-r-lg rounded-l-lg py-2": true,
                "h-9 w-[42px]": true,
                [`
                    text-white bg-black cursor-pointer
                    hover:bg-gray-700 hover:text-white`]: keyIdx !== -1,
                [`
                    text-gray-300 bg-grey-50 cursor-default`]: keyIdx === -1,
              })}
              onClick={e => {
                if (keyIdx !== -1) {
                  const fieldCnt = getFieldCounts(
                    lineGroups,
                    ontologies[keyIdx].key,
                  );
                  if (fieldCnt < 100) {
                    const [idx, boxId] = addLineByKey(
                      lineKeys[keyIdx] as string,
                    );
                    setFocusIndex(idx);
                    setSelectedBoxIds([String(boxId)]);
                    boxChangedFocusMover([], "center", "instant", idx);
                    setIsOpen(false);
                  } else {
                    setErrorOn(true);
                    setErrorType(EXCEED_NON_GROUPING_ADD);
                    setReloadFunction(() => () => {
                      setErrorOn(false);
                    });
                  }
                }
              }}
            >
              {<span>{"Add"}</span>}
            </button>
          </div>
        </div>
      </ReactModal>
    </>
  );
}
