import { useRef } from "react";
import {
  mergeProps,
  useFocusRing,
  useSlider,
  useSliderThumb,
  VisuallyHidden,
} from "react-aria";
import {
  type SliderState,
  type SliderStateOptions,
  useSliderState,
} from "react-stately";
import clsx from "clsx";
import { classnames } from "../utils/classnames";

function Thumb(props: {
  index: number;
  state: SliderState;
  trackRef: React.RefObject<React.ElementRef<"div">>;
  isFocusVisible: boolean;
  focusProps: ReturnType<typeof useFocusRing>["focusProps"];
  label: string;
}) {
  let { state, trackRef, focusProps, isFocusVisible, index } = props;
  let inputRef = useRef<React.ElementRef<"input">>(null);
  let { thumbProps, inputProps } = useSliderThumb(
    { index, trackRef, inputRef },
    state,
  );

  return (
    <div
      className="absolute -translate-x-1/2 cursor-pointer top-1/2"
      style={{
        left: `${state.getThumbPercent(index) * 100}%`,
      }}
    >
      <div
        {...thumbProps}
        onMouseDown={(...args) => {
          thumbProps.onMouseDown?.(...args);
        }}
        onPointerDown={(...args) => {
          thumbProps.onPointerDown?.(...args);
        }}
        className={clsx(
          "h-3 rounded-full outline outline-white outline-offset-0",
          props.label !== "confidence" ? "border border-gray-900" : "border-none",
          isFocusVisible || state.isThumbDragging(index)
            ? props.label !== "confidence" ? "w-1.5 bg-slate-900" : "w-1.5 bg-gray-900"
            : props.label !== "confidence" ? "w-1 bg-slate-900" : "w-1 bg-gray-900",
        )}
      >
        <VisuallyHidden>
          <input ref={inputRef} {...mergeProps(inputProps, focusProps)} />
        </VisuallyHidden>
      </div>
    </div>
  );
}

export function Slider(
  props: SliderStateOptions<Array<number>> & { onChangeStart?: () => void },
) {
  let trackRef = useRef<React.ElementRef<"div">>(null);
  let state = useSliderState(props);
  let { groupProps, trackProps } = useSlider(
    props,
    state,
    trackRef,
  );
  let { focusProps, isFocusVisible } = useFocusRing();

  return (
    <div
      {...groupProps}
      className="absolute inset-x-0 flex items-center flex-auto w-full gap-6 bottom-full touch-none md:relative"
    >
      <div
        {...trackProps}
        onMouseDown={(...args) => {
          trackProps.onMouseDown?.(...args);
          props.onChangeStart?.();
        }}
        onPointerDown={(...args) => {
          trackProps.onPointerDown?.(...args);
          props.onChangeStart?.();
        }}
        ref={trackRef}
        className={classnames({
          "relative w-full md:rounded-full": true,
          "bg-gray-100 ": props.label !== "confidence",
          "bg-gradient-to-r from-[#F78D92] to-[#BE232A] ": props.label === "confidence",
        })}
      >
        <div className="flex flex-row">

          <div
            className={clsx(
              "h-2 md:rounded-l-xl md:rounded-r-md",
              props.label !== "confidence" ? "bg-slate-900" : "bg-transparent",
            )}
            style={{
              width: `calc(${state.getThumbPercent(0) * 100}% )`,
            }}
          />
          <Thumb
            index={0}
            state={state}
            trackRef={trackRef}
            focusProps={focusProps}
            isFocusVisible={isFocusVisible}
            label={props.label!.valueOf() as string}
          />
          <div
            className={clsx(
              "h-2 md:rounded-r-xl md:rounded-r-md",
              props.label !== "confidence" ? "bg-transparent" : "bg-slate-200",
            )}
            style={{
              width:
                state.getThumbValue(0) === 100
                  ? 0
                  : `calc(${100 - state.getThumbPercent(0) * 100}% + ${isFocusVisible || state.isThumbDragging(0)
                    ? "0.03125rem"
                    : "0.025rem"
                  })`,
            }}
          />

        </div>
      </div>
    </div>
  );
}
