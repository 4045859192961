import { useState } from "react";
import { Icon, Icons } from "../components/Icon";
import { MAX_ZOOM_RATIO, MIN_ZOOM_RATIO } from "../constants/ImageScale";
import { classnames } from "../utils/classnames";
import { ValidatorToolZoom } from "./ValidatorToolZoom";
export interface ValidatorImageViewToolbarProps {
  fileVisible: boolean;
  imageScale: number;
  onImageScaleChange: (scale: number) => void;
  numberOfImages: number;
  page: number;
  onPageSelect: (index: number) => void;
  onRotate?(): void;
  onReset?(): void;
}

export function ValidatorImageViewToolbar({
  fileVisible,
  imageScale,
  onImageScaleChange,
  numberOfImages,
  page,
  onPageSelect,
  onRotate,
  onReset,
}: ValidatorImageViewToolbarProps): JSX.Element {
  const [isRotateHover, setIsRotateHover] = useState(false);
  const [isResetHover, setIsResetHover] = useState(false);
  return (
    <div
      className={classnames({
        "absolute top-0 flex flex-row items-center justify-center before:flex-none w-full z-10":
          true,
        invisible: !fileVisible,
      })}
    >
      <div className="relative flex items-center w-full px-6 py-2.5 bg-gray-25 bg-opacity-90">
        <div className="relative flex border border-gray-100 p-0.5 rounded-lg">
          <span
            onClick={onRotate}
            onMouseEnter={() => setIsRotateHover(true)}
            onMouseLeave={() => setIsRotateHover(false)}
            className="cursor-pointer hover:bg-[#E5E7EB] hover:rounded-lg p-2 relative"
          >
            <Icon source={Icons.Rotate} />
            <div
              className={classnames({
                "absolute bottom-[-40px] left-1/2 transform -translate-x-1/2 py-1.5 px-2 bg-gray-800 text-white text-[14px] rounded-[6px] tracking-wide opacity-90 z-40":
                  true,
                invisible: !isRotateHover,
              })}
            >
              <span>Rotate</span>
            </div>
          </span>
          <span
            onClick={onReset}
            onMouseEnter={() => setIsResetHover(true)}
            onMouseLeave={() => setIsResetHover(false)}
            className="cursor-pointer hover:bg-[#E5E7EB] hover:rounded-lg relative"
          >
            <Icon source={Icons.Reset} />
            <div
              className={classnames({
                "absolute bottom-[-40px] left-1/2 transform -translate-x-1/2 py-1.5 px-2 bg-gray-800 text-white text-[14px] rounded-[6px] opacity-90 z-40":
                  true,
                invisible: !isResetHover,
              })}
            >
              <span className="whitespace-nowrap">Fit to screen</span>
            </div>
          </span>
        </div>

        <ValidatorToolZoom
          imageScale={imageScale}
          onClickZoomIn={() => {
            imageScale * 1.1 < MAX_ZOOM_RATIO &&
              onImageScaleChange(imageScale * 1.1);
          }}
          onClickZoomOut={() => {
            imageScale * 0.9 > MIN_ZOOM_RATIO &&
              onImageScaleChange(imageScale * 0.9);
          }}
          onImageHandler={onImageScaleChange}
        ></ValidatorToolZoom>

        <span className="flex items-center justify-center gap-2">
          <span
            className={classnames({
              "flex items-center justify-center text-[14px]": true,
              "cursor-pointer hover:opacity-70": numberOfImages > 1,
              "opacity-30": numberOfImages <= 1,
            })}
            onClick={() => {
              if (numberOfImages <= 1) return;
              const nextPage = Math.max(page - 1, 0);
              onPageSelect(nextPage);
            }}
          >
            <Icon source={Icons.UpArrow} />
          </span>

          <span className="flex items-center justify-center text-[14px]">
            <span className="flex items-center justify-end w-4 text-gray-900">
              {page + 1}
            </span>

            <span className="flex items-center justify-center mx-2 text-text-gray-700">
              /
            </span>

            <span className="flex items-center justify-start w-4 text-text-gray-900">
              {numberOfImages}
            </span>
          </span>

          <span
            className={classnames({
              "flex items-center justify-center text-[14px]": true,
              "cursor-pointer hover:opacity-70": numberOfImages > 1,
              "opacity-30": numberOfImages <= 1,
            })}
            onClick={() => {
              if (numberOfImages <= 1) return;
              const nextPage = Math.min(page + 2, numberOfImages) - 1;
              onPageSelect(nextPage);
            }}
          >
            <Icon source={Icons.DownArrow} />
          </span>
        </span>
      </div>
    </div>
  );
}
