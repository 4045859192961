import { Dialog, Transition } from "@headlessui/react";
import { Fragment, useRef } from "react";
import {
  ERROR_TYPE_TO_CONTENT,
  ErrorModalContents,
} from "../../constants/ErrorMessage";
import { classnames } from "../../utils/classnames";
import { Icon, Icons } from "../Icon";

interface ErrorModalProps {
  open: boolean;
  setOpen: (open: boolean) => void;
  errorType: number;
  closeFunction: () => void;
  reloadFunction: () => void;
}
export default function ErrorModal({
  open,
  setOpen,
  errorType,
  closeFunction,
  reloadFunction,
}: ErrorModalProps) {
  const { message, texts, buttonLeft, buttonRight, buttonRightIcon } =
    ERROR_TYPE_TO_CONTENT.get(errorType) as ErrorModalContents;

  const cancelButtonRef = useRef(null);

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-20"
        initialFocus={cancelButtonRef}
        onClose={setOpen}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 transition-opacity bg-gray-500 bg-opacity-75" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
          <div className="flex items-end justify-center min-h-full p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative px-4 pt-5 pb-4 overflow-hidden text-left transition-all transform bg-white rounded-lg shadow-xl sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
                <div>
                  <div className="flex items-center justify-center w-12 h-12 mx-auto rounded-full">
                    <Icon source={Icons.Alert} />
                  </div>
                  <div className="mt-3 text-center sm:mt-5">
                    <Dialog.Title
                      as="h3"
                      className="leading-6 text-gray-900 --heading-sm"
                      key="error-message"
                    >
                      {message}
                    </Dialog.Title>
                    <div className="mt-3">
                      {texts.map((text, idx) => {
                        return (
                          <p
                            className="pb-1 text-center text-gray-500 --body-sm"
                            key={`error-text${idx}`}
                          >
                            {text}
                          </p>
                        );
                      })}
                    </div>
                  </div>
                </div>
                <div
                  className={classnames({
                    "justify-center mt-5 sm:mt-6 sm:grid sm:grid-flow-row-dense sm:grid-cols-2 sm:gap-3":
                      buttonLeft.length > 0 && buttonRight.length > 0,
                    "justify-center mt-5 sm:mt-6":
                      buttonLeft.length > 0 || buttonRight.length > 0,
                  })}
                >
                  <button
                    type="button"
                    className="inline-flex justify-center w-full gap-2 px-3 py-2 text-white bg-black rounded-md shadow-sm --body-sm hover:bg-gray-700 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 sm:col-start-2"
                    onClick={e => {
                      reloadFunction();
                    }}
                  >
                    {buttonRightIcon && <Icon source={Icons.Reload}></Icon>}
                    {buttonRight}
                  </button>
                  {buttonLeft && (
                    <button
                      type="button"
                      className="inline-flex justify-center w-full px-3 py-2 mt-3 text-gray-900 bg-white rounded-md shadow-sm --body-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:col-start-1 sm:mt-0"
                      onClick={e => {
                        closeFunction();
                      }}
                      ref={cancelButtonRef}
                    >
                      {buttonLeft}
                    </button>
                  )}
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
