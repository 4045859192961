import { Add } from "./Add";
import { Alert } from "./Alert";
import { BlankLogo } from "./BlankLogo";
import { Check } from "./Check";
import { ChevronDown } from "./ChevronDown";
import { ChevronLeft } from "./ChevronLeft";
import { ChevronRight } from "./ChevronRight";
import { ChevronUp } from "./ChevronUp";
import { CurrentBar } from "./CurrentBar";
import { CursorBar } from "./CursorBar";
import { DivisionBar } from "./DivisionBar";
import { Document } from "./Document";
import { DownArrow } from "./DownArrow";
import { DownArrowColumn } from "./DownArrowColumn";
import { Download } from "./Download";
import { ErrorTriangle } from "./ErrorTriangle";
import { ExtractorValidatorLogo } from "./ExtractorValidatorLogo";
import { EyeClosed } from "./EyeClose";
import { EyeOpen, EyeOpenGrey } from "./EyeOpen";
import { FileOpener, FileOpener3 } from "./FileOpener";
import { FileOpenerClosed } from "./FileOpenerClosed";
import { InformationCircle } from "./InformationCircle";
import { MicroAdjustments, MicroAdjustmentsGrey } from "./MicroAdjustments";
import { Minus } from "./Minus";
import { NoHistory } from "./NoHistory";
import { NoResult } from "./NoResult";
import { Plus } from "./Plus";
import { PlusCircle } from "./PlusCircle";
import { Power } from "./Power";
import { QuestionMarkCircle } from "./QuestionMarkCircle";
import { Reload } from "./Reload";
import { Reset } from "./Reset";
import { RightBar } from "./RightBar";
import { Rotate } from "./Rotate";
import { ToggleOff } from "./ToggleOff";
import { ToggleOn } from "./ToggleOn";
import { Transpose } from "./Transpose";
import { UpArrow } from "./UpArrow";
import { Upload } from "./Upload";
import { UploadImage } from "./UploadImage";
import { WhitePlus } from "./WhitePlus";
import { XMark } from "./XMark";
import { ZoomIn } from "./ZoomIn";
import { ZoomOut } from "./ZoomOut";

export const Icons = {
  Add,
  Alert,
  BlankLogo,
  Check,
  ChevronDown,
  ChevronLeft,
  ChevronRight,
  ChevronUp,
  CurrentBar,
  CursorBar,
  DivisionBar,
  Document,
  DownArrow,
  DownArrowColumn,
  Download,
  ErrorTriangle,
  ExtractorValidatorLogo,
  EyeClosed,
  EyeOpen,
  EyeOpenGrey,
  FileOpener,
  FileOpener3,
  FileOpenerClosed,
  InformationCircle,
  MicroAdjustments,
  MicroAdjustmentsGrey,
  Minus,
  NoHistory,
  NoResult,
  Plus,
  PlusCircle,
  Power,
  QuestionMarkCircle,
  Reload,
  Reset,
  RightBar,
  Rotate,
  ToggleOff,
  ToggleOn,
  Transpose,
  UpArrow,
  Upload,
  UploadImage,
  WhitePlus,
  XMark,
  ZoomIn,
  ZoomOut,
};

export type IconName = keyof typeof Icons;
