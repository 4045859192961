import {
  FILE_ERROR,
  FILE_PAGE_OVERFLOW,
  INFERENCE_FAIL,
} from "../constants/ErrorMessage";
import { DEMO_MODE_FEATURE, FEATURE } from "../constants/FeatureFlag";
import {
  ALLOW_FILE_EXTENSION,
  ALLOW_FILE_TYPE,
  MAX_FILE_SIZE_MB,
} from "../constants/FileValitaditon";
import EmptyFileException from "../errors/EmptyFileException";
import HttpException from "../errors/HttpException";
import { ExtractorOacJsonResponse } from "../types/extractorOacResponse";
import { LAOacJsonResponse } from "../types/laOacResponse";
import { OCROacJsonResponse } from "../types/ocrOacResponse";
import { Ontology } from "../types/ontology";
import { endpointStore } from "../utils/mobx/EndpointStore";
import { parsingTableStore } from "../utils/mobx/ParsingTableStore";
import {
  resultExtractorPreProcessor,
  resultLAPreProcessor,
  resultOCRPreProcessor,
} from "../utils/resultPreProcessor";
import { ResultFile as IFile, ImageFile, Model } from "./useFile";

// TODO: 멀티파일 업로드 지원할것. 우선은 단일 이미지만 처리하도록
interface ImageUploaderResponseSingle {
  requestId: string;
}

export const getResultFromServer = async (
  tenantId: string,
  requestId: string,
) => {
  const { endpointType } = endpointStore;
  const url = `${process.env.REACT_APP_BACKEND_URL ? process.env.REACT_APP_BACKEND_URL : ""}/api/result/${requestId}`;
  try {
    const response = await fetch(url);
    if (endpointType.toLowerCase() === "extractor") {
      return (await response.json()) as ExtractorOacJsonResponse;
    } else if (endpointType.toLowerCase() === "ocr") {
      return (await response.json()) as OCROacJsonResponse;
    } else if (endpointType.toLowerCase() === "layout-analysis") {
      return (await response.json()) as LAOacJsonResponse;
    }
  } catch (err) {
    console.error("err:", err);
    throw err;
  }
};
export const imageUploader = async (
  selectedFiles: File[],
  tenantId: string,
  url: string,
  type: string, // kvs | document
  serviceName: string,
  setErrorOn: (on: boolean) => void,
  setErrorType: (msg: number) => void,
  setInfering: (infering: boolean) => void,
  setCloseFunction: (func: () => void) => void,
  setReloadFunction: (func: () => void) => void,
) => {
  let requestUrl = "";
  const { endpointType, token } = endpointStore;
  if (endpointType.toLowerCase() === "extractor") {
    requestUrl = `${process.env.REACT_APP_BACKEND_URL ? process.env.REACT_APP_BACKEND_URL : ""}/api/extractor/inference`;
  } else if (endpointType.toLowerCase() === "ocr") {
    requestUrl = `${process.env.REACT_APP_BACKEND_URL ? process.env.REACT_APP_BACKEND_URL : ""}/api/ocr/inference`;
  } else if (endpointType.toLowerCase() === "layout-analysis") {
    requestUrl = `${process.env.REACT_APP_BACKEND_URL ? process.env.REACT_APP_BACKEND_URL : ""}/api/layout-analysis/inference`;
  }
  if (!selectedFiles || selectedFiles.length === 0) {
    throw new EmptyFileException("No file selected");
  }
  const fd = new FormData();

  // TODO: 멀티파일 업로드 지원할것. 우선은 단일 이미지만 처리하도록
  // 리퀘스트를 받지 못했을경우 빈 list를 반한하다. 이경우 사용하는 쪽에서 오류를 처리하도록 한다.
  let userFile = selectedFiles[0];
  fd.append("document", userFile);
  fd.append("url", url);
  fd.append("type", type);
  fd.append("serviceName", serviceName);
  if (endpointType.toLowerCase() === "extractor") {
    fd.append("modelPath", "extraction");
  }
  if (FEATURE === DEMO_MODE_FEATURE) {
    if (token) {
      fd.append("token", token);
    } else {
      throw new Error("Token not found");
    }
  }

  const response = await fetch(requestUrl, {
    method: "POST",
    body: fd,
  });
  if (response.status < 300 && response.status >= 200) {
    const imageUploaderReponse =
      (await response.json()) as ImageUploaderResponseSingle;
    return [imageUploaderReponse.requestId];
  } else {
    throw HttpException;
  }
};

export const validateFile = (
  file: globalThis.File,
  setErrorType: (msg: number) => void,
): boolean => {
  if (file) {
    const sizeInBytes: number = file.size;
    const sizeInMB: number = sizeInBytes / (1024 * 1024);
    if (MAX_FILE_SIZE_MB < sizeInMB) {
      setErrorType(FILE_ERROR);
      return false;
    }

    const fileType = file.type;
    const fileName: string = file.name;
    const fileExtension: string =
      fileName.split(".").pop()?.toLowerCase() || "";

    if (
      !ALLOW_FILE_TYPE.includes(fileType) &&
      (!fileExtension || !ALLOW_FILE_EXTENSION.includes(fileExtension))
    ) {
      setErrorType(FILE_ERROR);
      return false;
    }
    return true;
  }
  return false;
};

export const fileUpload = async (
  input_files: File[],
  setErrorType: (msg: number) => void,
  setWorkPercentageVisible: (vis: boolean) => void,
  tenantId: string,
  setIsOpen: (on: boolean) => void,
  setErrorOn: (on: boolean) => void,
  setInfering: (on: boolean) => void,
  setImageFiles: (files: ImageFile[]) => void,
  models: Model[],
  selected: number,
  files: IFile[],
  setFileSelected: (idx: number) => void,
  setFiles: (files: IFile[]) => void,
  setCloseFunction: (func: () => void) => void,
  setReloadFunction: (func: () => void) => void,
  errorType: number,
  event: React.ChangeEvent<HTMLInputElement>,
  skipValidation: boolean = false,
) => {
  const { endpointType } = endpointStore;
  if (!skipValidation && !validateFile(input_files[0], setErrorType)) {
    event.target.value = "";
    setErrorOn(true);

    setCloseFunction(() => () => {
      document.getElementById("file-upload")?.click();
      setErrorOn(false);
    });

    if (errorType === FILE_PAGE_OVERFLOW) {
      setReloadFunction(() => () => {
        setErrorOn(false);
        fileUpload(
          input_files,
          setErrorType,
          setWorkPercentageVisible,
          tenantId,
          setIsOpen,
          setErrorOn,
          setInfering,
          setImageFiles,
          models,
          selected,
          files,
          setFileSelected,
          setFiles,
          setCloseFunction,
          setReloadFunction,
          errorType,
          event,
        );
      });
    } else {
      setReloadFunction(() => () => {
        setErrorOn(false);
      });
    }
    return;
  }

  setWorkPercentageVisible(true);
  setInfering(true);
  const { setontologies } = parsingTableStore;
  if (input_files) {
    setImageFiles(Array.from(input_files) as unknown as ImageFile[]);
    await imageUploader(
      Array.from(input_files),
      tenantId,
      models[selected].url,
      models[selected].type,
      models[selected].serviceName || "document-ai",
      setErrorOn,
      setErrorType,
      setInfering,
      setCloseFunction,
      setReloadFunction,
    )
      .then(requestIds => {
        requestIds!.forEach(requestId => {
          getResultFromServer(tenantId, requestId)
            .then(result => {
              if (result && result.code !== 500) {
                if (files && result) {
                  if (endpointType.toLowerCase() === "extractor") {
                    resultExtractorPreProcessor(
                      result as ExtractorOacJsonResponse,
                      result.metadata.pages &&
                        result.metadata.pages.length > 0 &&
                        result.metadata.pages[0].height > 0,
                    ).then(() => {
                      setFileSelected(0);
                      setFiles([
                        {
                          title: input_files[0].name,
                          result: result,
                          modelIndex: selected,
                        },
                      ])!;
                      if ("ontologies" in result) {
                        setontologies(
                          result.ontologies as unknown as Ontology[],
                        );
                      }
                      setInfering(false);
                      setIsOpen(false);
                      if (event) {
                        event.target.value = "";
                      }
                    });
                  } else if (endpointType.toLowerCase() === "ocr") {
                    resultOCRPreProcessor(
                      result as OCROacJsonResponse,
                      result.metadata.pages &&
                        result.metadata.pages[0].height > 0,
                    ).then(() => {
                      setFileSelected(0);
                      setFiles([
                        {
                          title: input_files[0].name,
                          result: result,
                          modelIndex: selected,
                        },
                      ])!;
                      setInfering(false);
                      setIsOpen(false);
                      if (event) {
                        event.target.value = "";
                      }
                    });
                  } else if (endpointType.toLowerCase() === "layout-analysis") {
                    resultLAPreProcessor(
                      result as LAOacJsonResponse,
                      result.metadata.pages &&
                        result.metadata.pages[0].height > 0,
                    ).then(() => {
                      setFileSelected(0);
                      setFiles([
                        {
                          title: input_files[0].name,
                          result: result,
                          modelIndex: selected,
                        },
                      ])!;
                      setInfering(false);
                      setIsOpen(false);
                      if (event) {
                        event.target.value = "";
                      }
                    });
                  }
                  window.history.pushState({ page: 1 }, `Init Modal close`, "");
                }
              } else {
                setErrorOn(true);
                setInfering(false);

                setErrorType(INFERENCE_FAIL);
                setCloseFunction(() => () => {
                  event.target.value = "";
                  setErrorOn(false);
                });
                setReloadFunction(() => () => {
                  setErrorOn(false);
                  fileUpload(
                    input_files,
                    setErrorType,
                    setWorkPercentageVisible,
                    tenantId,
                    setIsOpen,
                    setErrorOn,
                    setInfering,
                    setImageFiles,
                    models,
                    selected,
                    files,
                    setFileSelected,
                    setFiles,
                    setCloseFunction,
                    setReloadFunction,
                    errorType,
                    event,
                    true,
                  );
                });
              }
            })
            .catch(() => {
              setErrorOn(true);
              setInfering(false);

              setErrorType(INFERENCE_FAIL);
              setCloseFunction(() => () => {
                setErrorOn(false);
                event.target.value = "";
              });
              setReloadFunction(() => () => {
                setErrorOn(false);
                fileUpload(
                  input_files,
                  setErrorType,
                  setWorkPercentageVisible,
                  tenantId,
                  setIsOpen,
                  setErrorOn,
                  setInfering,
                  setImageFiles,
                  models,
                  selected,
                  files,
                  setFileSelected,
                  setFiles,
                  setCloseFunction,
                  setReloadFunction,
                  errorType,
                  event,
                );
              });
            });
        });
        setWorkPercentageVisible(false);
      })
      .catch(() => {
        setErrorOn(true);
        setInfering(false);

        setErrorType(INFERENCE_FAIL);
        event.target.value = "";
        setCloseFunction(() => () => {
          setErrorOn(false);
        });
        setReloadFunction(() => () => {
          setErrorOn(false);
          fileUpload(
            input_files,
            setErrorType,
            setWorkPercentageVisible,
            tenantId,
            setIsOpen,
            setErrorOn,
            setInfering,
            setImageFiles,
            models,
            selected,
            files,
            setFileSelected,
            setFiles,
            setCloseFunction,
            setReloadFunction,
            errorType,
            event,
          );
        });
      });
  }
};
