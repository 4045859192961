import type { SVGProps } from "react";

export function Reload(props: SVGProps<SVGSVGElement>) {
  return (
    <svg width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d="M10.2668 14.2143C10.038 13.9746 10.0468 13.5948 10.2865 13.366L13.6032 10.2L5.60078 10.2C3.22596 10.2 1.30078 8.27483 1.30078 5.9C1.30078 3.52518 3.22596 1.6 5.60078 1.6H7.90078C8.23215 1.6 8.50078 1.86863 8.50078 2.2C8.50078 2.53137 8.23215 2.8 7.90078 2.8H5.60078C3.8887 2.8 2.50078 4.18792 2.50078 5.9C2.50078 7.61208 3.8887 9 5.60078 9L13.6032 9L10.2865 5.83401C10.0468 5.60521 10.038 5.22542 10.2668 4.98572C10.4956 4.74602 10.8754 4.73718 11.1151 4.96599L15.5151 9.16599C15.6337 9.2792 15.7008 9.43603 15.7008 9.6C15.7008 9.76397 15.6337 9.9208 15.5151 10.034L11.1151 14.234C10.8754 14.4628 10.4956 14.454 10.2668 14.2143Z" fill="white" />
    </svg>

  );
}
