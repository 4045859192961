import type { SVGProps } from "react";

export function Rotate(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.3935 1.18941C11.1592 0.955093 10.7793 0.955093 10.545 1.18941C10.3107 1.42372 10.3107 1.80362 10.545 2.03794L11.8812 3.37419H9.00194C5.41102 3.37419 2.5 6.28521 2.5 9.87613C2.5 13.467 5.41102 16.3781 9.00194 16.3781C12.5929 16.3781 15.5039 13.467 15.5039 9.87613C15.5039 9.54476 15.2352 9.27613 14.9039 9.27613C14.5725 9.27613 14.3039 9.54476 14.3039 9.87613C14.3039 12.8043 11.9301 15.1781 9.00194 15.1781C6.07376 15.1781 3.7 12.8043 3.7 9.87613C3.7 6.94795 6.07376 4.57419 9.00194 4.57419H11.8818L10.545 5.91096C10.3107 6.14527 10.3107 6.52517 10.545 6.75949C10.7793 6.9938 11.1592 6.9938 11.3935 6.75948L13.6129 4.54013C13.6388 4.5142 13.6626 4.48694 13.6842 4.45856C13.8332 4.34939 13.93 4.17309 13.93 3.97419C13.93 3.77489 13.8329 3.5983 13.6833 3.48918C13.6619 3.46121 13.6384 3.43434 13.6129 3.40876L11.3935 1.18941Z"
        fill="#111218"
      />
    </svg>
  );
}
