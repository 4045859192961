import { Icon } from "../components/Icon";
import { Images } from "../components/Images";

export function NothingSelected() {
  return (
    <>
      <Icon source={Images.NoImage} className="mt-[5.5rem] mb-[1.5rem]" />

    </>
  );
}
