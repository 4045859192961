import type { SVGProps } from "react";

export function CurrentBar(props: SVGProps<SVGSVGElement>) {
  return (

    <svg width="2" height="37" viewBox="0 0 2 37" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect x="0.5" y="0.5" width="1" height="36" fill="#EDEDED" />
    </svg>

  );
}