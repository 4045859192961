import { Provider } from "mobx-react";
import React from "react";
import ReactDOM from "react-dom/client";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import App from "./App";
import { Layout } from "./components/Layout/Layout";
import Home from "./pages/home";
import reportWebVitals from "./reportWebVitals";
import { parsingTableStore } from "./utils/mobx/ParsingTableStore";
import { resultInteractionStore } from "./utils/mobx/ResultInteractionStore";

const router = createBrowserRouter(
  [
    {
      path: "/",
      element: <App />,
      children: [
        {
          path: "/",
          element: <Layout />,
          children: [
            {
              path: "/",
              element: <Home isLogin={true} newEndpointType="extractor" />,
            },
            {
              path: "/ocr",
              element: <Home isLogin={true} newEndpointType="ocr" />,
            },
            {
              path: "/la",
              element: (
                <Home isLogin={true} newEndpointType="layout-analysis" />
              ),
            },
            {
              path: "/before-login/kie",
              element: <Home isLogin={false} newEndpointType="extractor" />,
            },
            {
              path: "/before-login/ocr",
              element: <Home isLogin={false} newEndpointType="ocr" />,
            },
            {
              path: "/before-login/la",
              element: (
                <Home isLogin={false} newEndpointType="layout-analysis" />
              ),
            },
          ],
        },
      ],
    },
  ],
  { basename: process.env.PUBLIC_URL ? process.env.PUBLIC_URL : "/" },
);

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement,
);
root.render(
  <React.StrictMode>
    <Provider
      parsingTableStore={parsingTableStore}
      resultInteractionStore={resultInteractionStore}
    >
      <RouterProvider router={router} />
    </Provider>
  </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
