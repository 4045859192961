export interface DownloadFileParams {
  blob: Blob;
  fileName: string;
}

export const downloadFile = ({ blob, fileName }: DownloadFileParams): void => {
  const url = URL.createObjectURL(blob);

  const downloadAnchor = document.createElement("a");
  downloadAnchor.href = url;
  downloadAnchor.download = fileName;
  downloadAnchor.click();

  URL.revokeObjectURL(url);
};
