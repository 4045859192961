import { WARNING_CONFIDENCE_THRESHOLD } from "../../constants/threshold";
import { Point } from "../../types/demo";
import {
  ContentField,
  ExtractorInferenceResult,
  GroupField,
} from "../../types/extractorOacResponse";
import { LAInferenceResult } from "../../types/laOacResponse";
import { OCRInferenceResult } from "../../types/ocrOacResponse";
import { BoundingBox, BoxDrawing, Property } from "../../types/parsingImage";
import { imageViewStore } from "../../utils/mobx/ImageViewStore";

/*
 * 주어진 여러 bounding box들을 모두 포함하는 가장 작은 bounding boxd의 좌표값을 계산하는 함수입니다.
 *
 * @param {BoundingBox[]} boundingBoxes - 포함해야 하는 bounding box들의 배열입니다.
 * 각 bounding box는 vertices 속성으로 4개의 꼭지점을 가지고 있습니다.
 * @returns {[number, number, number, number]} - 포함된 가장 작은 bounding box를 정의하는 [minX, minY, maxX, maxY] 배열입니다.
 */
const getEnclosingBoundingBox = (
  boundingBoxes: BoundingBox[],
): [number, number, number, number] => {
  let minX: number, minY: number, maxX: number, maxY: number;
  boundingBoxes.forEach(boundingBox => {
    boundingBox.vertices.forEach((vertice, idx) => {
      idx ? (minX = Math.min(vertice.x, minX)) : (minX = vertice.x);
      idx ? (maxX = Math.max(vertice.x, maxX)) : (maxX = vertice.x);
      idx ? (minY = Math.min(vertice.y, minY)) : (minY = vertice.y);
      idx ? (maxY = Math.max(vertice.y, maxY)) : (maxY = vertice.y);
    });
  });
  return [minX!, minY!, maxX!, maxY!];
};

/*
 * 주어진 여러 bounding box들을 모두 포함하는 가장 작은 bounding box를 50%확대한 것을 반환하는 함수입니다.
 *
 * @param {BoundingBox[]} boundingBoxes - 포함해야 하는 bounding box들의 배열입니다.
 * 각 bounding box는 vertices 속성으로 4개의 꼭지점을 가지고 있습니다.
 * @returns {BoundingBox} - 포함된 가장 작은 bounding box [[
    [minX, minY],
    [minX, maxY],
    [maxX, maxY],
    [maxX, minY],
  ]
 */

export const getExpandedEnclosingBoundingBox = (
  boundingBoxes: BoundingBox[],
): [Point, Point, Point, Point] => {
  const { imgHeight, imgWidth } = imageViewStore;
  const [_minX, _minY, _maxX, _maxY] = getEnclosingBoundingBox(boundingBoxes);
  const [boxW, boxH] = [_maxX! - _minX!, _maxY! - _minY!];
  const [minX, minY, maxX, maxY] = [
    Math.max(_minX - boxW * 0.25, 0),
    Math.max(_minY! - boxH * 0.25, 0),
    Math.min(_maxX! + boxW * 0.25, imgWidth),
    Math.min(_maxY! + boxH * 0.25, imgHeight),
  ];

  return [
    [minX!, minY!],
    [minX!, maxY!],
    [maxX!, maxY!],
    [maxX!, minY!],
  ];
};

/*
 * 주어진 여러 Property에 속하는 BBox를 모두 포함하는 가장 작은 bounding box를 계산하는 함수입니다.
 *
 * @param {Property[]} properties - 포함해야 하는 bounding box를 가지고 있는 Property의 배열입니다.
 * 각 bounding box는 vertices 속성으로 4개의 꼭지점을 가지고 있습니다.
 * @returns {BoundingBox} - 포함된 가장 작은 bounding box [[
    [minX, minY],
    [minX, maxY],
    [maxX, maxY],
    [maxX, minY],
 */
export const getEnclosingBoundingBoxFromProperties = (
  properties: Property[],
): [Point, Point, Point, Point] => {
  let minX: number, minY: number, maxX: number, maxY: number;
  properties.forEach((property, idx) => {
    if (property.boundingBoxes) {
      const [_minX, _minY, _maxX, _maxY] = getEnclosingBoundingBox(
        property.boundingBoxes,
      );
      idx ? (minX = Math.min(_minX, minX)) : (minX = _minX);
      idx ? (maxX = Math.max(_maxX, maxX)) : (maxX = _maxX);
      idx ? (minY = Math.min(_minY, minY)) : (minY = _minY);
      idx ? (maxY = Math.max(_maxY, maxY)) : (maxY = _maxY);
    }
  });
  return [
    [minX!, minY!],
    [minX!, maxY!],
    [maxX!, maxY!],
    [maxX!, minY!],
  ];
};
const isBlanckResult = (field: GroupField | ContentField) => {
  if (field.type === "group") {
    const f = field as GroupField;
    if (
      f.properties.filter(p => !p.boundingBoxes || p.boundingBoxes.length === 0)
        .length > 0
    )
      return true;
  } else {
    const f = field as ContentField;
    if (!f.boundingBoxes || f.boundingBoxes?.length === 0) return true;
  }
  return false;
};

/* 추론 결과를 바탕을 박스를 그리는 객체인 BoxDrawing[]를 반한하는 함수입니다.
 * 박스는 그루핑 영역과 논그루핑 영역이 다르게 그려집니다.
 *
 * @param {ExtractorInferenceResult} resuilt - 추론 결과 입니다.
 * @returns {BoxDrawing[]}
 */

export const drawBox = (result: ExtractorInferenceResult) => {
  return (
    result
      // ?.filter(field => !isBlanckResult(field)) // Im not sure...
      .flatMap(field => {
        if (field.type === "group") {
          const f = field as GroupField;
          return f.properties?.flatMap(property => {
            return property.boundingBoxes
              ? property.boundingBoxes.flatMap(box => {
                  return {
                    page: box.page,
                    id: String(property.id),
                    points: box.vertices.map(v => [v.x, v.y]),
                    groupIds: f.properties.map(_property =>
                      String(_property.id),
                    ),
                    theme:
                      property.confidence &&
                      property.confidence < WARNING_CONFIDENCE_THRESHOLD
                        ? "warning"
                        : "default",
                  } as unknown as BoxDrawing;
                })
              : [];
          });
        }
        const f = field as ContentField;

        return f.boundingBoxes
          ? f.boundingBoxes.flatMap(box => {
              return {
                page: box.page,
                id: String(field.id),
                points: box.vertices.map(v => [v.x, v.y]),
                theme:
                  f.confidence && f.confidence < WARNING_CONFIDENCE_THRESHOLD
                    ? "warning"
                    : "default",
              } as unknown as BoxDrawing;
            })
          : [];
      }) ?? []
  );
};

/* 주어진 field에서 가장 큰 박스를 반환하는 함수입니다.
 *
 * @param {ContentField} field: - 가장큰 박스를 뽑아내고 싶은 Field 입니다.
 * @returns {number} largestIdx - 가장큰 박스의 인덱스 입니다.
 */
const getLargestBox = (field: ContentField) => {
  let largeIdx = 0;
  if (field.boundingBoxes) {
    let largestArea = 0;

    field.boundingBoxes.forEach((boundingBox, idx) => {
      let minX: number, minY: number, maxX: number, maxY: number;
      boundingBox.vertices.forEach((vertice, idx) => {
        idx ? (minX = Math.min(vertice.x, minX)) : (minX = vertice.x);
        idx ? (maxX = Math.max(vertice.x, maxX)) : (maxX = vertice.x);
        idx ? (minY = Math.min(vertice.y, minY)) : (minY = vertice.y);
        idx ? (maxY = Math.max(vertice.y, maxY)) : (maxY = vertice.y);
      });
      if (
        minX! &&
        minY! &&
        maxX! &&
        maxY! &&
        largestArea < (maxX - minX) * (maxY - minY)
      ) {
        largestArea = (maxX - minX) * (maxY - minY);
        largeIdx = idx;
      }
    });
  }
  return largeIdx;
};

/* 추론 결과를 바탕을 박스를 그리는 객체인 BoxDrawing[]를 반한하는 함수입니다.
 * 가장큰 박스를 반환하게 됩니다.
 * 박스는 그루핑 영역과 논그루핑 영역이 다르게 그려집니다.
 *
 * 그루핑 영역의 경우 한 그룹을 포함하는 박스
 *
 * 논그루핑 영역은 한개의 결과의 박스 대해 가장큰 박스
 *
 * @param {ExtractorInferenceResult} resuilt - 추론 결과 입니다.
 * @returns {BoxDrawing[]}
 */
export const drawLargestBox = (result: ExtractorInferenceResult) => {
  return (
    result
      ?.filter(field => !isBlanckResult(field))
      .flatMap(field => {
        if (field.type === "group") {
          const f = field as GroupField;
          return {
            page: f.properties[0].boundingBoxes
              ? f.properties[0].boundingBoxes[0].page
              : -1,
            id: String(f.properties[0].id),
            points: getEnclosingBoundingBoxFromProperties(f.properties),
            groupIds: f.properties.map(_property => String(_property.id)),
            theme: "default",
          } as unknown as BoxDrawing;
        }
        const f = field as ContentField;
        const largestIdx = getLargestBox(f);
        return f.boundingBoxes
          ? ({
              page: f.boundingBoxes[largestIdx].page,
              id: String(field.id),
              points: getExpandedEnclosingBoundingBox([
                f.boundingBoxes[largestIdx],
              ]),
              theme:
                f.confidence && f.confidence < WARNING_CONFIDENCE_THRESHOLD
                  ? "warning"
                  : "default",
            } as unknown as BoxDrawing)
          : ({
              page: -1,
              id: String(field.id),
              points: [],
              theme:
                f.confidence && f.confidence < WARNING_CONFIDENCE_THRESHOLD
                  ? "warning"
                  : "default",
            } as unknown as BoxDrawing);
      }) ?? []
  );
};

/* 추론 결과를 바탕을 박스를 그리는 객체인 BoxDrawing[]를 반한하는 함수입니다.
 * 가장큰 박스를 반환하게 됩니다.
 * 박스는 그루핑 영역과 논그루핑 영역이 다르게 그려집니다.
 *
 * 그루핑 영역의 경우 한 그룹을 포함하는 박스
 *
 * 논그루핑 영역은 한개의 결과의 박스들을 모두 포함하는 박스
 *
 * @param {ExtractorInferenceResult} resuilt - 추론 결과 입니다.
 * @returns {BoxDrawing[]}
 */
export const drawLargeBox = (result: ExtractorInferenceResult) => {
  return (
    result
      ?.filter(field => !isBlanckResult(field))
      .flatMap(field => {
        if (field.type === "group") {
          const f = field as GroupField;
          return {
            page: f.properties[0].boundingBoxes
              ? f.properties[0].boundingBoxes[0].page
              : -1,
            id: String(f.properties[0].id),
            points: getEnclosingBoundingBoxFromProperties(f.properties),
            groupIds: f.properties.map(_property => String(_property.id)),
            theme: "default",
          } as unknown as BoxDrawing;
        }
        const f = field as ContentField;
        return {
          page: f.boundingBoxes ? f.boundingBoxes[0].page : -1,
          id: String(f.id),
          points: getExpandedEnclosingBoundingBox(
            f.boundingBoxes as BoundingBox[],
          ),
          groupIds: [String(f.id)],
          theme: "default",
        } as unknown as BoxDrawing;
      }) ?? []
  );
};

/* 추론 결과를 바탕을 박스를 그리는 객체인 BoxDrawing[]를 반한하는 함수입니다.
 * Focus & Dimmed 기능을 위해 집중할 영역을 반환하는 함수입니다. (투명한 부분)
 *
 * @param {ExtractorInferenceResult} resuilt - 추론 결과 입니다.
 * @returns {BoxDrawing[]}
 */
export const drawDimmedBox = (result: ExtractorInferenceResult) => {
  return (
    result
      ?.filter(field => !isBlanckResult(field))
      .flatMap(field => {
        if (field.type === "group") {
          const f = field as GroupField;
          return {
            page: f.properties[0].boundingBoxes
              ? f.properties[0].boundingBoxes[0].page
              : -1,
            id: String(f.properties[0].id),
            points: getEnclosingBoundingBoxFromProperties(f.properties),
            groupIds: f.properties.map(_property => String(_property.id)),
            theme: "dimmed",
          } as unknown as BoxDrawing;
        }
        const f = field as ContentField;
        return {
          page: f.boundingBoxes ? f.boundingBoxes[0].page : -1,
          id: String(f.id),
          points: getExpandedEnclosingBoundingBox(
            f.boundingBoxes as BoundingBox[],
          ),
          groupIds: [String(f.id)],
          theme: "dimmed",
        } as unknown as BoxDrawing;
      }) ?? []
  );
};

/* 추론 결과를 바탕을 박스를 그리는 객체인 BoxDrawing[]를 반한하는 함수입니다.
 * Focus & Dimmed 기능을 위해 집중할 영역을 제외한 다른 공간에 대해 반투명한 회색 박스를 반한합니다.
 *
 * @param {ExtractorInferenceResult} resuilt - 추론 결과 입니다.
 * @returns {BoxDrawing[]}
 */
export const drawDimmedBoxOut = (result: ExtractorInferenceResult) => {
  return (
    result
      ?.filter(field => !isBlanckResult(field))
      .flatMap(field => {
        if (field.type === "group") {
          const f = field as GroupField;
          const [tl, bl, br, tr] = getEnclosingBoundingBoxFromProperties(
            f.properties,
          );
          const [w, h] = [br[0] - tl[0], br[1] - tl[1]];
          const [minX, minY, maxX, maxY] = [
            tl[0] - w * 0.15,
            tl[1] - h * 0.15,
            br[0] + w * 0.15,
            br[1] + h * 0.15,
          ];
          const page = f.properties[0].boundingBoxes
            ? f.properties[0].boundingBoxes[0].page
            : -1;
          const gids = f.properties.map(_property => String(_property.id));
          const id = String(f.properties[0].id);
          const INF = 100000;
          return [
            {
              page: page,
              id: id,
              points: [
                [-INF, -INF],
                [-INF, INF],
                [minX, INF],
                [minX, -INF],
              ],
              groupIds: gids,
              theme: "dimmed",
            } as unknown as BoxDrawing,
            {
              page: page,
              id: id,
              points: [
                [minX, -INF],
                [minX, minY],
                [maxX, minY],
                [maxX, -INF],
              ],
              groupIds: gids,
              theme: "dimmed",
            } as unknown as BoxDrawing,
            {
              page: page,
              id: id,
              points: [
                [maxX, -INF],
                [maxX, INF],
                [INF, INF],
                [INF, -INF],
              ],
              groupIds: gids,
              theme: "dimmed",
            } as unknown as BoxDrawing,
            {
              page: page,
              id: id,
              points: [
                [minX, maxY],
                [minX, INF],
                [maxX, INF],
                [maxX, maxY],
              ],
              groupIds: gids,
              theme: "dimmed",
            } as unknown as BoxDrawing,
          ];
        }
        const f = field as ContentField;
        const [tl, bl, br, tr] = getExpandedEnclosingBoundingBox(
          f.boundingBoxes as BoundingBox[],
        );
        const [w, h] = [br[0] - tl[0], br[1] - tl[1]];
        const [minX, minY, maxX, maxY] = [
          tl[0] - w * 0.1,
          tl[1] - h * 0.1,
          br[0] + w * 0.1,
          br[1] + h * 0.1,
        ];
        const page = f.boundingBoxes ? f.boundingBoxes[0].page : -1;
        const gids = [String(f.id)];
        const id = String(f.id);
        const INF = 100000;
        return [
          {
            page: page,
            id: id,
            points: [
              [-INF, -INF],
              [-INF, INF],
              [minX, INF],
              [minX, -INF],
            ],
            groupIds: gids,
            theme: "dimmed",
          } as unknown as BoxDrawing,
          {
            page: page,
            id: id,
            points: [
              [minX, -INF],
              [minX, minY],
              [maxX, minY],
              [maxX, -INF],
            ],
            groupIds: gids,
            theme: "dimmed",
          } as unknown as BoxDrawing,
          {
            page: page,
            id: id,
            points: [
              [maxX, -INF],
              [maxX, INF],
              [INF, INF],
              [INF, -INF],
            ],
            groupIds: gids,
            theme: "dimmed",
          } as unknown as BoxDrawing,
          {
            page: page,
            id: id,
            points: [
              [minX, maxY],
              [minX, INF],
              [maxX, INF],
              [maxX, maxY],
            ],
            groupIds: gids,
            theme: "dimmed",
          } as unknown as BoxDrawing,
        ];
      }) ?? []
  );
};

export const drawOCRBox = (result: OCRInferenceResult) => {
  return result.flatMap((page, idx) => {
    return page.lines.flatMap(line => {
      return line.boundingBox.flatMap(box => {
        const padding = Math.min(page.width, page.height) * 0.005;
        return {
          page: idx + 1,
          id: `page-${idx + 1}-line-${line.id}`,
          points: box.vertices.map((v, idx) => [v.x, v.y]),
          theme: "default",
        } as unknown as BoxDrawing;
      });
    });
  });
};

export const drawLABox = (result: LAInferenceResult) => {
  return result.flatMap((element, idx) => {
    return {
      page: element.page,
      id: `page-${element.page}-element-${element.id}`,
      points: element.bounding_box.map((v, idx) => [v.x, v.y]),
      theme: "warning",
    } as unknown as BoxDrawing;
  });
};
