/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { observer } from "mobx-react";
import { useEffect, useState } from "react";
import { useImageRendering } from "../hooks/useImageRendering";
import ParsingImageView from "../molecules/ParsingImageView";
import { ValidatorImageViewToolbar } from "../molecules/ValidatorImageViewToolbar";
import { ImageOacJson } from "../types/demo";
import { classnames } from "../utils/classnames";
import { imageViewStore } from "../utils/mobx/ImageViewStore";
import { resultInteractionStore } from "../utils/mobx/ResultInteractionStore";

export interface DemoImageViewProps {
  file: ImageOacJson;
  page: number;
  isDRSP: boolean;
  pageSelectHandler: (page: number) => void;
  image: string;
  imageScale: number;
  imageScaleChangeHandler: (scale: number) => void;
  numberOfImages: number;
  allowBoxClick?: boolean;
  infering?: boolean;
}

const ValidatorImageView = ({
  file,
  page,
  isDRSP,
  pageSelectHandler,
  image,
  imageScale,
  imageScaleChangeHandler,
  numberOfImages,
  allowBoxClick = true,
  infering = false,
}: DemoImageViewProps): JSX.Element => {
  const [cssTransform, setCssTanform] = useState<string>("");
  const { reset, setReset, degree, setDegree } = imageViewStore;
  const {
    canvasSizeChangeHandler,
    canvasObjectMoveHandler,
    imageSizeChangeHandler,
    rotateBy,
    moveAndFocus,
  } = useImageRendering(
    file,
    imageScale,
    reset,
    imageScaleChangeHandler,
    setCssTanform,
    degree,
  );

  const { setHoveredBoxIds, setSelectedBoxIds, setFocusIndex } =
    resultInteractionStore;
  const [imageSelectedBoxIds, setImageSelectedBoxIds] = useState<string[]>([]);
  const [imageHoveredBoxIds, setImageHoveredBoxIds] = useState<string[]>([]);
  useEffect(() => {
    setDegree(0);
  }, [file, setDegree]);

  return (
    <>
      <div
        className={classnames({
          "flex-1": true,
        })}
      >
        <div
          className="relative flex flex-col justify-between h-full"
          onClick={() => {
            setSelectedBoxIds([]);
            setHoveredBoxIds([]);
            setImageSelectedBoxIds([]);
            setImageHoveredBoxIds([]);
            setFocusIndex(-1);
          }}
        >
          {/* 아래 슬라이드바 부분 */}
          <ValidatorImageViewToolbar
            fileVisible={!!image}
            imageScale={imageScale}
            onImageScaleChange={imageScaleChangeHandler}
            numberOfImages={numberOfImages}
            page={page}
            onPageSelect={pageSelectHandler}
            onRotate={() => {
              rotateBy(90);
              setDegree((degree + 90) % 360);
            }}
            // userImageRendering에서 초기화시키키는 useEffect에 reset을 추가하여 작동시킨다.
            onReset={() => {
              setReset(!reset);
            }}
          />
          {/* 이미지 부분 */}
          <ParsingImageView
            image={image}
            allowBoxClick={true}
            cssTransform={cssTransform}
            onResizeCanvas={canvasSizeChangeHandler}
            onMoveImage={canvasObjectMoveHandler}
            onImageSizeChanged={imageSizeChangeHandler}
            infering={infering}
            page={page}
            isDRSP={isDRSP}
            pageSelectHandler={pageSelectHandler}
            setImageHoveredBoxIds={setImageHoveredBoxIds}
            imageSelectedBoxIds={imageSelectedBoxIds}
            imageHoveredBoxIds={imageHoveredBoxIds}
            moveAndFocus={moveAndFocus}
          />
          {/* 아랫부분 패딩 */}
        </div>
      </div>
    </>
  );
};

export default observer(ValidatorImageView);
