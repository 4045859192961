import { BOXSCALE } from "../constants/Box";
import {
  Box,
  ContentField,
  ExtractorOacJsonResponse,
  GroupField,
  Property,
  Vertex,
} from "../types/extractorOacResponse";
import { LAOacJsonResponse } from "../types/laOacResponse";
import {
  OCROacJsonResponse,
  convertResponseToImageOCROacJson,
} from "../types/ocrOacResponse";

export const resultExtractorPreProcessor = async (
  result: ExtractorOacJsonResponse,
  resize: boolean,
) => {
  const images: string[] = result.imageBase64;
  const ratio: number[] = Array(images.length).fill(1);
  const orgHeight: number[] = result.metadata.pages.map(page => page.height);
  resultExtractorBlankFiller(result);
  if (true || resize) {
    // Promise.all()을 사용하여 모든 이미지 로딩 완료 여부 추적
    // Promise 배열 생성
    const imageLoadPromises: Promise<void>[] = images.map((image, idx) => {
      return new Promise<void>((resolve, reject) => {
        const newImageSource = "data:image/jpeg;base64," + image;
        const img: HTMLImageElement = new Image();

        img.onload = function () {
          const baseHeight = img.height;
          if (
            result.metadata &&
            result.metadata.pages.length > idx &&
            result.metadata.pages[idx].height > 0
          ) {
            ratio[idx] = baseHeight / orgHeight[idx];
          }
          resolve(); // Promise를 해결하여 이미지 로딩이 완료되었음을 알림
        };

        img.onerror = function () {
          reject(new Error("Failed to load image")); // 이미지 로딩 실패 시 reject 호출
        };

        img.src = newImageSource;
      });
    });
    await Promise.all(imageLoadPromises)
      .then(() => {
        // 모든 이미지 로딩이 완료된 후 실행될 코드
        for (let i = 0; i < result.fields.length; i++) {
          const field = result.fields[i];
          if (field.type === "group") {
            const f = field as GroupField;
            f.properties = _GroupBoxResizerHelper(f.properties, ratio);
          } else {
            const f = field as ContentField;
            f.boundingBoxes = f.boundingBoxes
              ? resizeBoxes(f.boundingBoxes, ratio)
              : [];
          }
        }
      })
      .catch(error => {
        console.error("Error loading images:", error);
      });
  }
  // else {
  //   for (let i = 0; i < result.fields.length; i++) {
  //     const field = result.fields[i];
  //     if (field.type === "group") {
  //       const f = field as GroupField;
  //       f.properties = _GroupresultExtractorPreProcessorHelper(f.properties, ratio);
  //     } else {
  //       const f = field as ContentField;
  //       f.boundingBoxes = f.boundingBoxes ? resizeBoxes(f.boundingBoxes, ratio) : [];
  //     }
  //   }
  // }
};

export const resultOCRPreProcessor = async (
  result: OCROacJsonResponse,
  resize: boolean,
) => {
  const images: string[] = result.imageBase64;
  const ratio: number[] = Array(images.length).fill(1);
  const orgHeight: number[] = result.metadata.pages.map(page => page.height);
  resultOCRBlankFiller(result);
  if (resize) {
    // Promise.all()을 사용하여 모든 이미지 로딩 완료 여부 추적
    // Promise 배열 생성
    const imageLoadPromises: Promise<void>[] = images.map((image, idx) => {
      return new Promise<void>((resolve, reject) => {
        const newImageSource = "data:image/jpeg;base64," + image;
        const img: HTMLImageElement = new Image();

        img.onload = function () {
          const baseHeight = img.height;
          if (result.metadata) {
            ratio[idx] = baseHeight / orgHeight[idx];
          }
          resolve(); // Promise를 해결하여 이미지 로딩이 완료되었음을 알림
        };

        img.onerror = function () {
          reject(new Error("Failed to load image")); // 이미지 로딩 실패 시 reject 호출
        };

        img.src = newImageSource;
      });
    });
    await Promise.all(imageLoadPromises)
      .then(() => {
        const pages = convertResponseToImageOCROacJson(result).pages;
        result.pages = pages;
      })
      .then(() => {
        // 모든 이미지 로딩이 완료된 후 실행될 코드
        for (let i = 0; i < result.pages.length; i++) {
          const page = result.pages[i];
          page.lines.forEach(line => {
            line.boundingBox.forEach(box => {
              box.vertices = getChangedBoxes(box.vertices, ratio[i]);
            });
          });
        }
      })
      .catch(error => {
        console.error("Error loading images:", error);
      });
  }
  // else {
  //   for (let i = 0; i < result.fields.length; i++) {
  //     const field = result.fields[i];
  //     if (field.type === "group") {
  //       const f = field as GroupField;
  //       f.properties = _GroupresultExtractorPreProcessorHelper(f.properties, ratio);
  //     } else {
  //       const f = field as ContentField;
  //       f.boundingBoxes = f.boundingBoxes ? resizeBoxes(f.boundingBoxes, ratio) : [];
  //     }
  //   }
  // }
};

export const resultLAPreProcessor = async (
  result: LAOacJsonResponse,
  resize: boolean,
) => {
  const images: string[] = result.imageBase64;
  const ratio: number[] = Array(images.length).fill(1);
  const orgHeight: number[] = result.metadata.pages.map(page => page.height);
  if (resize) {
    // Promise.all()을 사용하여 모든 이미지 로딩 완료 여부 추적
    // Promise 배열 생성
    const imageLoadPromises: Promise<void>[] = images.map((image, idx) => {
      return new Promise<void>((resolve, reject) => {
        const newImageSource = "data:image/jpeg;base64," + image;
        const img: HTMLImageElement = new Image();

        img.onload = function () {
          const baseHeight = img.height;
          if (result.metadata) {
            ratio[idx] = baseHeight / orgHeight[idx];
          }
          resolve(); // Promise를 해결하여 이미지 로딩이 완료되었음을 알림
        };

        img.onerror = function () {
          reject(new Error("Failed to load image")); // 이미지 로딩 실패 시 reject 호출
        };

        img.src = newImageSource;
      });
    });
    await Promise.all(imageLoadPromises).catch(error => {
      console.error("Error loading images:", error);
    });
  }
  // else {
  //   for (let i = 0; i < result.fields.length; i++) {
  //     const field = result.fields[i];
  //     if (field.type === "group") {
  //       const f = field as GroupField;
  //       f.properties = _GroupresultExtractorPreProcessorHelper(f.properties, ratio);
  //     } else {
  //       const f = field as ContentField;
  //       f.boundingBoxes = f.boundingBoxes ? resizeBoxes(f.boundingBoxes, ratio) : [];
  //     }
  //   }
  // }
};

let dummyId = -1;
const dummyProperty = {
  key: "",
  value: "",
  refinedValue: "",
  type: "content",
  confidence: 0,
  boundingBox: [],
};

const resultExtractorBlankFiller = (result: ExtractorOacJsonResponse) => {
  for (let i = 0; i < result.fields.length; i++) {
    const field = result.fields[i];
    if (field.type === "group") {
      const f = field as GroupField;
      for (let j = 0; j < f.properties.length; j++) {
        if (!f.properties[j].boundingBoxes) f.properties[j].boundingBoxes = [];
      }
    } else {
      const f = field as ContentField;
      if (!f.boundingBoxes) f.boundingBoxes = [];
    }
  }
};

const resultOCRBlankFiller = (result: OCROacJsonResponse) => {
  for (let i = 0; i < result.pages.length; i++) {
    const page = result.pages[i];
    if (!page.words) page.words = [];
  }
};

const _GroupBoxResizerHelper = (
  properties: Property[],
  ratios: number[],
): Property[] => {
  const retProperties: Property[] = [];
  properties.forEach(p => {
    retProperties.push({
      value: p.value,
      id: p.id,
      type: p.type,
      key: p.key,
      confidence: p.confidence,
      refinedValue: p.refinedValue,
      boundingBoxes: p.boundingBoxes
        ? resizeBoxes(p.boundingBoxes, ratios)
        : [],
    });
  });
  return retProperties;
};

const _ContentBoxResizerHelper = (properties: Property[]): Property[] => {
  const retProperties: Property[] = [];
  properties.forEach(p => {
    retProperties.push({
      value: p.value,
      id: p.id,
      type: p.type,
      key: p.key,
      confidence: p.confidence,
      refinedValue: p.refinedValue,
      boundingBoxes: p.boundingBoxes ? resizeBoxes(p.boundingBoxes, []) : [],
    });
  });
  return retProperties;
};

const getChangedBoxes = (vertices: Vertex[], ratio: number): Vertex[] => {
  let minX: number, minY: number, maxX: number, maxY: number;
  vertices.forEach(vertice => {
    if (minX !== undefined) {
      minX = Math.min(vertice.x, minX);
    } else {
      minX = vertice.x;
    }
    if (maxX !== undefined) {
      maxX = Math.max(vertice.x, maxX);
    } else {
      maxX = vertice.x;
    }
    if (minY !== undefined) {
      minY = Math.min(vertice.y, minY);
    } else {
      minY = vertice.y;
    }
    if (maxY !== undefined) {
      maxY = Math.max(vertice.y, maxY);
    } else {
      maxY = vertice.y;
    }
  });
  const dx = (maxX! - minX!) * BOXSCALE;
  const dy = (maxY! - minY!) * BOXSCALE;
  const midX = (minX! + maxX!) / 2;
  const midY = (minY! + maxY!) / 2;
  // minX = Math.max(0, midX - dx / 2);
  // //todo: Max값이 사진을 초과할 수 있음.
  // maxX = midX + dx / 2;

  // minY = Math.max(0, midY - dy / 2);
  // //todo: Max값이 사진을 초과할 수 있음.
  // maxY = midY + dy / 2;
  minX = minX! * ratio;
  minY = minY! * ratio;
  maxX = maxX! * ratio;
  maxY = maxY! * ratio;
  return [
    { x: minX!, y: minY! },
    { x: minX!, y: maxY! },
    { x: maxX!, y: maxY! },
    { x: maxX!, y: minY! },
  ];
};
const getArea = (box: Box) => {
  const [minX, minY, maxX, maxY] = [
    box.vertices[0].x,
    box.vertices[0].y,
    box.vertices[2].x,
    box.vertices[2].y,
  ];
  return (maxX - minX) * (maxY - minY);
};
const getMaxAreaBox = (boxes: Box[]) => {
  let maxBox: Box = boxes[0];
  let maxArea: number = getArea(boxes[0]);
  boxes.forEach(box => {
    const area = getArea(box);
    if (area > maxArea) {
      maxArea = area;
      maxBox = box;
    }
  });
  return maxBox;
};

const resizeBoxes = (boxes: Box[], ratios: number[]): Box[] => {
  if (!boxes || boxes.length == 0) return [];
  const retBoxes: Box[] = [];
  boxes.forEach(box => {
    retBoxes.push({
      page: box.page,
      vertices: getChangedBoxes(box.vertices, ratios[box.page - 1] ?? 1),
    });
  });
  // return [getMaxAreaBox(retBoxes)];
  return retBoxes;
};
