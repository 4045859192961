import type { SVGProps } from "react";

export function Transpose(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M4.25 5C4.25 4.58579 4.58579 4.25 5 4.25H13C13.4142 4.25 13.75 4.58579 13.75 5C13.75 5.41421 13.4142 5.75 13 5.75H5.75V13C5.75 13.4142 5.41421 13.75 5 13.75C4.58579 13.75 4.25 13.4142 4.25 13V5Z"
        fill="#696C77"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M10.4697 2.46967C10.7626 2.17678 11.2374 2.17678 11.5303 2.46967L13.5303 4.46967C13.8232 4.76256 13.8232 5.23744 13.5303 5.53033L11.5303 7.53033C11.2374 7.82322 10.7626 7.82322 10.4697 7.53033C10.1768 7.23744 10.1768 6.76256 10.4697 6.46967L11.9393 5L10.4697 3.53033C10.1768 3.23744 10.1768 2.76256 10.4697 2.46967Z"
        fill="#696C77"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M7.53033 10.4697C7.82322 10.7626 7.82322 11.2374 7.53033 11.5303L5.53033 13.5303C5.23744 13.8232 4.76256 13.8232 4.46967 13.5303L2.46967 11.5303C2.17678 11.2374 2.17678 10.7626 2.46967 10.4697C2.76256 10.1768 3.23744 10.1768 3.53033 10.4697L5 11.9393L6.46967 10.4697C6.76256 10.1768 7.23744 10.1768 7.53033 10.4697Z"
        fill="#696C77"
      />
    </svg>
  );
}
