import { action, makeObservable, observable } from "mobx";

class EndpointStore {
  endpointType = "extractor";
  token = "";
  setEndpointType(type: string) {
    this.endpointType = type;
  }
  setToken(token: string) {
    this.token = token;
  }
  constructor() {
    makeObservable(this, {
      endpointType: observable,
      setEndpointType: action,
      token: observable,
      setToken: action,
    });
    this.setEndpointType = this.setEndpointType.bind(this);
    this.setToken = this.setToken.bind(this);
  }
}

export const endpointStore = new EndpointStore();
