import { action, computed, makeObservable, observable } from "mobx";
import { InferenceResult } from "../../types/demo";
import * as extractorResult from "../../types/extractorResult";

class ResultInteractionStore {
  result: InferenceResult | null = null;
  hoveredBoxIds: string[] = [];
  selectedBoxIds: string[] = [];
  focusIndex: number = -1;
  additionalFocusIndexes: number[] = [];
  focusEnable: boolean = false;

  constructor() {
    makeObservable(this, {
      result: observable,
      hoveredBoxIds: observable,
      selectedBoxIds: observable,
      focusIndex: observable,
      focusEnable: observable,
      additionalFocusIndexes: observable,
      setResult: action,
      setHoveredBoxIds: action,
      setSelectedBoxIds: action,
      setFocusIndex: action,
      getHovedIds: computed,
      setAdditionalFocusIndexes: action,
      setFocusEnable: action,
    });
    this.setResult = this.setResult.bind(this); // <- Add this
    this.setHoveredBoxIds = this.setHoveredBoxIds.bind(this); // <- Add this
    this.setSelectedBoxIds = this.setSelectedBoxIds.bind(this); // <- Add this
    this.setFocusIndex = this.setFocusIndex.bind(this); // <- Add this
    this.isGrouptHovered = this.isGrouptHovered.bind(this);
    this.isLineGroupHovered = this.isLineGroupHovered.bind(this);
    this.setFocusEnable = this.setFocusEnable.bind(this);
    this.setAdditionalFocusIndexes = this.setAdditionalFocusIndexes.bind(this);
    this.isGrouptSelected = this.isGrouptSelected.bind(this);
    this.isLineGroupSelected = this.isLineGroupSelected.bind(this);
  }

  setFocusEnable(focusEnable: boolean) {
    this.focusEnable = focusEnable;
  }
  setResult(newResult: InferenceResult) {
    this.result = newResult;
  }

  setHoveredBoxIds(boxIds: string[]) {
    while (this.hoveredBoxIds.pop());
    boxIds.forEach(id => this.hoveredBoxIds.push(id));
  }

  setSelectedBoxIds(boxIds: string[]) {
    this.selectedBoxIds = boxIds;
  }

  setFocusIndex(idx: number) {
    this.focusIndex = idx;
  }

  get getHovedIds() {
    return this.hoveredBoxIds;
  }

  setAdditionalFocusIndexes(indexes: number[]) {
    this.additionalFocusIndexes = indexes;
  }
  isLineGroupSelected(lineGroup: extractorResult.Line) {
    if (this.selectedBoxIds?.includes(String(lineGroup.id))) {
      return true;
    }

    return false;
  }

  isGrouptSelected(group: extractorResult.Group) {
    if (this.selectedBoxIds.includes(String(group.id))) {
      return true;
    }
    const relatedBoxIds = group.properties.map(property => String(property.id));
    for (let boxId of relatedBoxIds) {
      if (this.selectedBoxIds.includes(String(boxId))) {
        return true;
      }
    }
    return false;
  }

  isLineGroupHovered(lineGroup: extractorResult.Line) {
    if (this.hoveredBoxIds?.includes(String(lineGroup.id))) {
      return true;
    }

    return false;
  }

  isGrouptHovered(group: extractorResult.Group) {
    if (this.hoveredBoxIds.includes(String(group.id))) {
      return true;
    }
    const relatedBoxIds = group.properties.map(property => String(property.id));
    for (let boxId of relatedBoxIds) {
      if (this.hoveredBoxIds.includes(String(boxId))) {
        return true;
      }
    }
    return false;
  }
}

export const resultInteractionStore = new ResultInteractionStore();
export default ResultInteractionStore;
