import { useContext, useEffect, useState } from "react";
import {
  ValidatorFileContext,
  ValidatorModelsContext,
} from "../../hooks/useFile";
import { classnames } from "../../utils/classnames";
import { Capitalize, ModifyModelName } from "../../utils/stringUtil";

interface fileSelectDropDownProps {
  modalOn: boolean;
}

export default function FileSelectDropDown({
  modalOn,
}: fileSelectDropDownProps) {
  const { files } = useContext(ValidatorFileContext);
  const { models, selected: selectedModelIndex } = useContext(
    ValidatorModelsContext,
  );
  const [selectedModel, setSelectedModel] = useState<string>("");
  useEffect(() => {
    if (models.length > 0) {
      setSelectedModel(models[selectedModelIndex].name);
    }
  }, [selectedModelIndex, models, files, modalOn]);

  return (
    <>
      <div className="relative w-full my-1 h-[30px] w-min truncate">
        {files.length > 0 ? (
          <div className="flex items-center justify-center px-2 align-middle h-[30px] text-gray-900 --body-sm whitespace-nowrap">
            <p>
              {ModifyModelName(
                selectedModel,
                models[selectedModelIndex].status,
              )}
            </p>
            {models[selectedModelIndex].status?.toLowerCase() != "public" ? (
              <span
                className={classnames({
                  "inline-flex ml-3 items-center text-[12px] font-normal": true,
                  "text-[#1D4ED8] ":
                    models[selectedModelIndex].status?.toLowerCase() === "beta",
                  "text-[#22C55E] ":
                    models[selectedModelIndex].status?.toLowerCase() != "beta",
                })}
              >
                {Capitalize(models[selectedModelIndex].status)}
              </span>
            ) : (
              <></>
            )}
          </div>
        ) : (
          <></>
        )}
      </div>
    </>
  );
}
