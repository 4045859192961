import * as matrix from "./matrix";
import type { Degree, Matrix33, Radian, Transform } from "./types";

export function matrixFromTranslationRotationScale(params: {
  tx: number;
  ty: number;
  rotation: Radian;
  scale: number;
}): Matrix33 {
  const { tx, ty, scale, rotation } = params;
  const S = matrix.scale(scale);
  const R = matrix.rotation(rotation);
  const T = matrix.translation(tx, ty);
  return matrix.multiply(T, R, S);
}

export function getImageTransform(
  screenw: number,
  screenh: number,
  imgw: number,
  imgh: number,
): Transform {
  const scaleH = screenh < imgh ? screenh / imgh : 1;
  const scaleW = screenw < imgw ? screenw / imgw : 1;
  const scale = Math.min(scaleH, scaleW, 1);

  const mappedw = imgw * scale;
  const mappedh = imgh * scale;
  // 스크린의 가운데 위치할 것이기 때문에 스크린의 중점을 기준으로 떨어진 거리만큼 이동시킨다.
  // 계산한 x,y가 이미지의 0,0이 화면에 떨어지는 점이다.
  const x = (screenw - mappedw) / 2;
  const y = (screenh - mappedh) / 2;

  const transform: Transform = {
    scale,
    rotation: 0,
    tx: x,
    ty: y,
    matrix: matrix.identity(),
  };

  const m = matrixFromTranslationRotationScale(transform);
  transform.matrix = m;

  return transform;
}

export function getCssTransform(transform: Transform): string {
  const m = transform.matrix;
  return `matrix(${m[0][0]}, ${m[1][0]}, ${m[0][1]}, ${m[1][1]}, ${m[0][2]}, ${m[1][2]})`;
}

/**
 * `anchorx`와 `anchory`를 기준으로해서 주어진 `ratio`만큼 추가로 확대/축소한다. `ratio=0.2`이면 20%더 확대하자는 의미이다.
 * 반대로 `ratio=-0.2`이면 20% 축소하는 의미이다. 좌표변환시 확대/축소가 제약에 걸리게되면 값에 따라서 조정된다.
 */
export function zoomBy(params: {
  ratio: number;
  anchorx: number;
  anchory: number;
  xform: Transform;
}): Transform {
  const { ratio, xform } = params;

  const curScale = xform.scale;
  const newScale = curScale * (1 + ratio);

  return zoomTo({
    ...params,
    newScale,
  });
}

export function zoomTo(params: {
  newScale: number;
  anchorx: number;
  anchory: number;
  xform: Transform;
}): Transform {
  const { newScale, anchorx, anchory, xform } = params;

  const inv = matrix.inverse(xform.matrix);
  const { x: basex, y: basey } = matrix.mulxy(inv, anchorx, anchory);

  // 특정점을 기준으로 확대를 만들어내는 매트릭스계산이다.
  // 먼저 기준점을 원점으로 이동하고 확대/축소 한후에 다시 기준점만큼 이동시킨다.
  const S = matrix.scale(newScale);
  const R = matrix.rotation(toRadian(xform.rotation));
  const M = matrix.multiply(R, S);

  const { x: newx, y: newy } = matrix.mulxy(M, basex, basey);
  const dx = anchorx - newx;
  const dy = anchory - newy;

  const OUT = matrix.multiply(matrix.translation(dx, dy), M);

  const newTransform: Transform = {
    ...xform,
    scale: newScale,
    matrix: OUT,
  };
  return newTransform;
}

export function focus(params: {
  newScale: number;
  anchorx: number;
  anchory: number;
  xform: Transform;
}): Transform {
  const { newScale, anchorx, anchory, xform } = params;
  let dx = anchorx;
  let dy = anchory;
  // 특정점을 기준으로 확대후 기준점으로 이동하는 매트릭스계산.
  // 먼저 원래대로 확대/축소 한후에 원점으로 이동후, 원하는 기준점으로 이동시킨다.

  //확대하기
  const M = matrix.scale(newScale);

  //원점으로 이동
  M[0][2] = 0;
  M[1][2] = 0;

  //원하는 만큼 이동시키기.
  const OUT = matrix.multiply(matrix.translation(dx, dy), M);

  const newTransform: Transform = {
    ...xform,
    scale: newScale,
    matrix: OUT,
  };
  return newTransform;
}

export function panTo(params: {
  x: number;
  y: number;
  xform: Transform;
}): Transform {
  const { x, y, xform } = params;
  const T = matrix.translation(x, y);
  const m = matrix.multiply(T, xform.matrix);
  const tx = m[0][2];
  const ty = m[1][2];
  const newTransform = {
    tx: tx,
    ty: ty,
    scale: xform.scale,
    rotation: xform.rotation,
    matrix: m,
  };
  return newTransform;
}

function toRadian(degree: Degree): Radian {
  return (Math.PI * absDegree(degree)) / 180;
}

function absDegree(degree: Degree): Degree {
  while (degree < 0) degree += 360;
  return degree % 360;
}

/**
 * 화면을 주어진 각도 `degree`만큼 추가로 회전시킨다.
 *
 * NOTE:
 * 매트릭스 변환이 렌더링된 매트릭스 `xform`에 적용된다. 의미는 anchorx, anchory 좌표계는
 * `xform` 좌표계에 맞춰졌다는 뜻이다. `xform`이 화면에 해당 엘리먼트를 렌더링하는데 사용하고 있다면
 * 좌표계는 화면에서 보고있는 좌표계가 된다. 예를들면, 왼쪽, 위에가 0,0이 되고, 오른쪽 아래가 캔바스
 * 너비, 높이가 되는 좌표계이다.
 */
export function rotateBy(params: {
  anchorx: number;
  anchory: number;
  degree: Degree;
  xform: Transform;
}): Transform {
  const { degree, anchorx, anchory, xform } = params;

  const T1 = matrix.translation(-anchorx, -anchory);
  const R = matrix.rotation(toRadian(degree));
  const T2 = matrix.translation(anchorx, anchory);
  const M = matrix.multiply(T2, R, T1, xform.matrix);

  const newTransform: Transform = {
    ...xform,
    rotation: absDegree(xform.rotation + degree),
    matrix: M,
  };
  return newTransform;
}
