import React, { useContext } from "react";
import { ImageOacJson } from "../types/demo";

export interface ImageFile {
  new (
    fileBits: BlobPart[],
    fileName: string,
    options?: FilePropertyBag | undefined,
  ): globalThis.File;
  prototype: globalThis.File;
}
export interface ResultFile {
  title: string;
  result: ImageOacJson;
  modelIndex: number;
}
export interface Model {
  name: string;
  serviceName: string | undefined;
  type: string;
  url: string;
  status: string | undefined;
}
export interface ValidatorFiles {
  imageFiles: ImageFile[];
  files: ResultFile[];
  selected: number;
  infering: boolean;
  setSelected: (selected: number) => void;
  setFiles: (selected: ResultFile[]) => void;
  setImageFiles: (selected: ImageFile[]) => void;
  setInfering: (inferring: boolean) => void;
}

export interface ValidatorModels {
  models: Model[];
  selected: number;
  setSelected: (selected: number) => void;
}

export const emtpyFiles: ResultFile[] = [];
export const emtpyModels: Model[] = [];

export const ValidatorFileContext = React.createContext<ValidatorFiles>({
  imageFiles: [],
  files: emtpyFiles,
  selected: -1,
  infering: false,
  setSelected: () => {},
  setFiles: () => {},
  setImageFiles: () => {},
  setInfering: () => {},
});

export const ValidatorFileProvider = ValidatorFileContext.Provider;

export function useValidatorResult(): ValidatorFiles {
  return useContext(ValidatorFileContext);
}

export const ValidatorModelsContext = React.createContext<ValidatorModels>({
  models: emtpyModels,
  selected: 0,
  setSelected: () => {},
});

export const ValidatorModelsProvider = ValidatorModelsContext.Provider;

export function useValidatorModelsResult(): ValidatorModels {
  return useContext(ValidatorModelsContext);
}
