import { ReactElement, memo, useEffect, useState } from "react";
import { Icon, Icons } from "./components/Icon";

const ErrorBoundary = ({ children }: { children: ReactElement | null }) => {
  const [hasError, setHasError] = useState(false);
  const [error, setError] = useState(null);

  // 오류 발생 시 처리되는 콜백 함수
  const componentDidCatch = (error: Error | null) => {
    console.error("Error Boundary caught an error:", error);
  };

  useEffect(() => {
    const setErrorReporter = () => {
      componentDidCatch(error);
      setHasError(true);
      setError(error);
    };

    window.addEventListener("error", setErrorReporter);

    return () => {
      window.removeEventListener("error", setErrorReporter);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const refresh = () => window.location.reload();

  return hasError ? (
    <div className="inline-flex flex-col items-center justify-center w-full h-full gap-4 bg-white border border-black rounded border-opacity-20">
      <div className="w-[13.25rem] h-36 py-8 justify-center items-center inline-flex">
        <Icon source={Icons.NoHistory} />
      </div>
      <div className="flex flex-col items-center justify-center gap-1">
        <div className="text-neutral-900 text-xl font-normal font-['Ups sans'] leading-[30px]">
          There seems to be an error in the system.
        </div>
        <div className="text-gray-500 text-md font-normal font-['Ups sans'] leading-snug">
          Please try again in a few minutes.
        </div>
      </div>
      <div className="inline-flex items-center justify-center h-8 px-2 bg-black rounded bg-opacity-90">
        <button
          onClick={refresh}
          className="text-white text-sm font-normal font-['SF Pro'] leading-tight"
        >
          Refresh
        </button>
      </div>
    </div>
  ) : (
    children
  );
};

export default memo(ErrorBoundary);
