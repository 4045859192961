import type { SVGProps } from "react";

export function UploadImage(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="heroicons-mini/arrow-up-tray">
        <g id="Union">
          <path
            d="M7.4001 10.5996C7.4001 10.931 7.66873 11.1996 8.0001 11.1996C8.33147 11.1996 8.6001 10.931 8.6001 10.5996L8.6001 3.70874L10.9639 6.21158C11.1914 6.45249 11.5712 6.46334 11.8121 6.23582C12.053 6.00829 12.0638 5.62855 11.8363 5.38764L8.43631 1.78763C8.32296 1.66762 8.16517 1.59961 8.0001 1.59961C7.83502 1.59961 7.67723 1.66762 7.56389 1.78763L4.16389 5.38763C3.93636 5.62855 3.94721 6.00829 4.18812 6.23582C4.42903 6.46334 4.80878 6.45249 5.0363 6.21158L7.4001 3.70874L7.4001 10.5996Z"
            fill="#374151"
          />
          <path
            d="M2.8001 10.1996C2.8001 9.86824 2.53147 9.59961 2.2001 9.59961C1.86873 9.59961 1.6001 9.86824 1.6001 10.1996V12.1996C1.6001 13.4146 2.58507 14.3996 3.8001 14.3996H12.2001C13.4151 14.3996 14.4001 13.4146 14.4001 12.1996V10.1996C14.4001 9.86824 14.1315 9.59961 13.8001 9.59961C13.4687 9.59961 13.2001 9.86824 13.2001 10.1996V12.1996C13.2001 12.7519 12.7524 13.1996 12.2001 13.1996H3.8001C3.24781 13.1996 2.8001 12.7519 2.8001 12.1996V10.1996Z"
            fill="#374151"
          />
        </g>
      </g>
    </svg>
  );
}
