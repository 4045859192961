import { Icon, Icons } from "../components/Icon";
import { Slider } from "./Slider";
import { MAX_ZOOM_RATIO, MIN_ZOOM_RATIO } from "../constants/ImageScale";
import { useEffect, useState } from "react";

export interface ValidatorToolZoomProps {
  imageScale: number;
  onClickZoomIn: () => void;
  onClickZoomOut: () => void;
  onImageHandler: (imageScale: number) => void;
}

// todo: Tailwind UI를 통해서 가지고 오면서 있는 친구들. 추후 상황에 맞게 수정예정
function parseTime(seconds: number) {
  let hours = Math.floor(seconds / 3600);
  let minutes = Math.floor((seconds - hours * 3600) / 60);
  seconds = seconds - hours * 3600 - minutes * 60;
  return [hours, minutes, seconds];
}
function formatHumanTime(seconds: number) {
  let [h, m, s] = parseTime(seconds);
  return `${h} hour${h === 1 ? "" : "s"}, ${m} minute${m === 1 ? "" : "s"
    }, ${s} second${s === 1 ? "" : "s"}`;
}

export function ValidatorToolZoom({
  imageScale,
  onClickZoomIn,
  onClickZoomOut,
  onImageHandler,
}: ValidatorToolZoomProps): JSX.Element {
  const [toolbarValue, setToolbarValue] = useState<number[]>([Math.log10(imageScale * 100) * 1000 ?? 0]);
  useEffect(() => {
    setToolbarValue([Math.log10(imageScale * 100) * 1000])
  }, [imageScale])
  return (
    <div className="flex items-center justify-center w-full h-full gap-3 ">
      <div className="flex items-center justify-center w-[300px] h-full gap-3 ">
        <span
          onClick={e => onClickZoomOut()}
          className="flex items-center justify-center w-8 h-8 ml-2 cursor-pointer hover:opacity-70"
        >
          <Icon source={Icons.ZoomOut} />
        </span>
        <span className="flex items-center justify-center w-full">
          <Slider
            label="Current time"
            maxValue={Math.log10(MAX_ZOOM_RATIO * 100) * 1000}
            step={1}
            value={toolbarValue}
            onChange={([value]) => {
              let nextValue = Math.pow(10, value / 1000) / 100;
              if (nextValue < MIN_ZOOM_RATIO) {
                nextValue = MIN_ZOOM_RATIO;
              }
              if (nextValue > MAX_ZOOM_RATIO) {
                nextValue = MAX_ZOOM_RATIO;
              }
              onImageHandler(nextValue);
            }}
            onChangeEnd={() => { }}
            numberFormatter={{ format: formatHumanTime } as Intl.NumberFormat}
            onChangeStart={() => { }}
          />
        </span>

        <span
          onClick={e => onClickZoomIn()}
          className="flex items-center justify-center cursor-pointer hover:opacity-70"
        >
          <Icon source={Icons.ZoomIn} />
        </span>
      </div>
    </div>
  );
}
