import { action, makeObservable, observable } from "mobx";

import { WARNING_CONFIDENCE_THRESHOLD } from "../../constants/threshold";
import { OCRInferenceResult } from "../../types/ocrOacResponse";
import { Line, parsingResultOCRVariable } from "../../types/ocrResult";
import { Ontology } from "../../types/ontology";

let dummyId = -1;
const dummyProperty = {
  key: "",
  value: "",
  refinedValue: "",
  type: "content",
  confidence: 0,
  boundingBox: [],
};

enum HistoryOrder {
  //na toggle
  nt,
  //add Line
  al,
  //delete Line
  dl,
  //add Group
  ag,
  //delete Group
  dg,
  //change Input Value
  cv,
}

// 토글 전환시 저장 되어야아할 NA가 On되어 있는 상태에서 na인 친구들을 저장하는 자료구조입니다.
interface ToggleHistoryData {
  lineGroupsIndexes: number[];
  groupIndexes: number[];
  visiblerKeys: string[];
}

/*
 history 스택에 저장되는 인터페이스이다. 
*/
interface History {
  // 수행한 명령이다. Na 토글링시 "nt", 라인 추가시 "al", 라인 삭제시 "dl", 그룹 추가시 "ag", 그룹 삭제시 "dg", 값 변경시 "Cv"
  order: HistoryOrder;
  // 데이터 넣을 위치
  idx: number;
  // 삭제시 기존에 있던 데이터를 넣는곳 .
  data: null | string | ToggleHistoryData;
  // 오류 수정을 위한 오리지널 밸류를 넣는 곳.
  orgValue: string | string[];
}

class PasringOCRStore {
  parsingOCRVariableList: parsingResultOCRVariable[] = [];
  ocrLines: Line[] = [];
  tdRef: React.MutableRefObject<HTMLTableCellElement[]> | null = null;
  inputRefs: React.MutableRefObject<
    (HTMLInputElement | HTMLTextAreaElement)[]
  > | null = null;

  values: string[] = [];
  orgValues: string[] = [];
  filteredIndex2orgIndex: number[] = [];
  // 새롭게 추가되는 친구들의 ID값 을 부여해주는 기준이 되는 변수. 우측 비박스와 마음 편하게 안겹치게 음수로 점점 작아지게 구현. (겹치면 클릭시 이동되는 연산 및 화면에 표시되는 정보 이상해짐)
  lastId: number = -2;

  largestId: number = 0;
  orgLargestId: number = 0;

  // undo에 필요한 History가 저장되는 변수이다.
  historyStack: History[] = [];
  // redo에 필요한 History가 저장되는 변수이다.
  redoStack: History[] = [];
  toggleOn: boolean = false;
  confidenceThreshold: number = WARNING_CONFIDENCE_THRESHOLD;
  confidenceOn: boolean = false;
  lineModalOn: boolean = false;
  toastOpen: boolean = false;
  visibleGroupKeys: string[] = [];
  ontologies: Ontology[] = [];
  constructor() {
    makeObservable(this, {
      parsingOCRVariableList: observable,
      tdRef: observable,
      inputRefs: observable,
      ocrLines: observable,
      values: observable,
      orgValues: observable,
      filteredIndex2orgIndex: observable,
      confidenceThreshold: observable,
      confidenceOn: observable,
      toastOpen: observable,
      lineModalOn: observable,
      visibleGroupKeys: observable,
      toggleOn: observable,
      ontologies: observable,
      orgLargestId: observable,
      setontologies: action,
      setToastOpen: action,
      setLineModalOn: action,
      setTdRef: action,
      setInputRef: action,
      setConfidenceThreshold: action,
    });
    this.setTdRef = this.setTdRef.bind(this);
    this.setInputRef = this.setInputRef.bind(this);
    this.tdClick = this.tdClick.bind(this);
    this.tdFocus = this.tdFocus.bind(this);
    this.inputClick = this.inputClick.bind(this);
    this.inputFocus = this.inputFocus.bind(this);
    this.setConfidenceThreshold = this.setConfidenceThreshold.bind(this);
    this.setConfidenceOn = this.setConfidenceOn.bind(this);
    this.setToastOpen = this.setToastOpen.bind(this);
    this.setLineModalOn = this.setLineModalOn.bind(this);
    this.setontologies = this.setontologies.bind(this);
    this.changeOCRFile = this.changeOCRFile.bind(this);
  }
  setontologies(ontologies: Ontology[]) {
    this.ontologies = ontologies;
  }

  setLineModalOn(on: boolean) {
    this.lineModalOn = on;
  }
  setToastOpen(open: boolean) {
    this.toastOpen = open;
  }

  setConfidenceOn(on: boolean) {
    this.confidenceOn = on;
  }

  setConfidenceThreshold(confidenceThreshold: number) {
    this.confidenceThreshold = confidenceThreshold;
  }

  tdClick(idx: number) {
    this.tdRef?.current[idx]?.click();
  }
  tdFocus(idx: number) {
    this.tdRef?.current[idx]?.focus();
  }
  inputClick(idx: number) {
    this.inputRefs?.current[idx]?.click();
  }
  inputFocus(idx: number) {
    this.inputRefs?.current[idx]?.focus();
  }

  setTdRef(t: React.MutableRefObject<HTMLTableCellElement[]>) {
    this.tdRef = t;
  }
  setInputRef(
    t: React.MutableRefObject<(HTMLInputElement | HTMLTextAreaElement)[]>,
  ) {
    this.inputRefs = t;
  }

  changeOCRFile(result: OCRInferenceResult, selected: number) {
    this.parsingOCRVariableList = [];
    if (!result) return;
    if (this.parsingOCRVariableList.length === selected) {
      const _lineGroups = result.flatMap((page, idx) =>
        page.lines.map(line => ({ ...line, page: idx + 1 })),
      );
      this.parsingOCRVariableList.push({
        lineGroups: _lineGroups,
      });
    }
    const ptv = this.parsingOCRVariableList[selected];
    this.ocrLines = ptv.lineGroups;

    this.values = [];
    this.ocrLines.forEach(lineGroup => {
      this.values.push(lineGroup.text);
    });

    this.orgValues = [];

    this.orgValues = [];
    this.ocrLines.forEach(lineGroup => {
      this.orgValues.push(lineGroup.text);
    });
  }
}

export const parsingOCRStore = new PasringOCRStore();
