export const MODEL_FETCH_FAIL = 1;
export const INFERENCE_FAIL = 2;
export const FILE_PAGE_OVERFLOW = 3;
export const FILE_ERROR = 4;
export const EXCEED_NON_GROUPING_ADD = 5;
export const EXCEED_GROUPING_ADD = 6;

export const ERROR_400 = 400;
export const ERROR_401 = 401;
export const ERROR_404 = 404;
export const ERROR_422 = 422;
export const ERROR_500 = 500;
export const ERROR_503 = 503;

export interface ErrorModalContents {
  message: string;
  texts: string[];
  buttonLeft: string;
  buttonRight: string;
  buttonRightIcon: boolean;
}

export const ERROR_TYPE_TO_CONTENT: Map<number, ErrorModalContents> = new Map();
ERROR_TYPE_TO_CONTENT.set(MODEL_FETCH_FAIL, {
  message: "Cannot connect to the server.",
  texts: ["Please check your network connection."],
  buttonLeft: "Close",
  buttonRight: "Retry",
  buttonRightIcon: true,
});
ERROR_TYPE_TO_CONTENT.set(INFERENCE_FAIL, {
  message: "There was a problem fetching the results.",
  texts: ["Please try again later."],
  buttonLeft: "Close",
  buttonRight: "Retry",
  buttonRightIcon: true,
});

ERROR_TYPE_TO_CONTENT.set(FILE_PAGE_OVERFLOW, {
  message: "The file exceeds the maximum number of pages",
  texts: ["Loading extraction results up to 30 pages."],
  buttonLeft: "Try another file",
  buttonRight: "Retry",
  buttonRightIcon: false,
});

ERROR_TYPE_TO_CONTENT.set(FILE_ERROR, {
  message: "Unsupported file format.",
  texts: [
    "Please check the uploaded file again.",
    "JPEG, PNG, BMP, PDF, TIFF, HEIC files up to 50MB are supported.",
  ],
  buttonLeft: "",
  buttonRight: "Confirm",
  buttonRightIcon: false,
});

ERROR_TYPE_TO_CONTENT.set(EXCEED_NON_GROUPING_ADD, {
  message: "Cannot add field.",
  texts: ["The selected item exceeds the maximum number of fields."],
  buttonLeft: "",
  buttonRight: "Confirm",
  buttonRightIcon: false,
});

ERROR_TYPE_TO_CONTENT.set(EXCEED_GROUPING_ADD, {
  message: "Cannot add row.",
  texts: ["Row limit exceeded."],
  buttonLeft: "",
  buttonRight: "Confirm",
  buttonRightIcon: false,
});

ERROR_TYPE_TO_CONTENT.set(ERROR_400, {
  message: "ERROR - 400",
  texts: ["400 Bad Request"],
  buttonLeft: "",
  buttonRight: "확인",
  buttonRightIcon: false,
});

ERROR_TYPE_TO_CONTENT.set(ERROR_401, {
  message: "ERROR - 401",
  texts: ["401 Unauthorized"],
  buttonLeft: "",
  buttonRight: "확인",
  buttonRightIcon: false,
});

ERROR_TYPE_TO_CONTENT.set(ERROR_404, {
  message: "ERROR - 404",
  texts: ["404 Not Found"],
  buttonLeft: "",
  buttonRight: "확인",
  buttonRightIcon: false,
});

ERROR_TYPE_TO_CONTENT.set(ERROR_422, {
  message: "ERROR - 422",
  texts: ["422 Unprocessable Content"],
  buttonLeft: "",
  buttonRight: "확인",
  buttonRightIcon: false,
});

ERROR_TYPE_TO_CONTENT.set(ERROR_500, {
  message: "ERROR - 500",
  texts: ["500 Internal Server Error"],
  buttonLeft: "",
  buttonRight: "확인",
  buttonRightIcon: false,
});

ERROR_TYPE_TO_CONTENT.set(ERROR_503, {
  message: "ERROR - 503",
  texts: ["503 Service Unavailable"],
  buttonLeft: "",
  buttonRight: "확인",
  buttonRightIcon: false,
});
