/* eslint-disable react/jsx-props-no-spreading */
import type { SVGProps } from "react";

export function ChevronUp(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="heroicons-micro/chevron-up">
        <path
          id="Vector 1 (Stroke)"
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M11.7803 9.78033C11.4874 10.0732 11.0126 10.0732 10.7197 9.78033L8 7.06066L5.28033 9.78033C4.98744 10.0732 4.51256 10.0732 4.21967 9.78033C3.92678 9.48744 3.92678 9.01256 4.21967 8.71967L7.46967 5.46967C7.76256 5.17678 8.23744 5.17678 8.53033 5.46967L11.7803 8.71967C12.0732 9.01256 12.0732 9.48744 11.7803 9.78033Z"
          fill="#111827"
        />
      </g>
    </svg>
  );
}
