import { observer } from "mobx-react-lite";
import { useContext, useRef, useState } from "react";
import { MODEL_FETCH_FAIL } from "../../constants/ErrorMessage";
import { FILE_UPLOAD_ON } from "../../constants/Features";
import {
  ValidatorFileContext,
  ValidatorModelsContext,
} from "../../hooks/useFile";
import { fileUpload } from "../../hooks/useImageUploader";
import { classnames } from "../../utils/classnames";
import { endpointStore } from "../../utils/mobx/EndpointStore";
import { parsingTableStore } from "../../utils/mobx/ParsingTableStore";
import { UploadImage } from "../Icon/generated/UploadImage";
import { Icon } from "../Icon/Icon";
import DemoSelectModal from "../Modal/DemoSelectModal";
import ErrorModal from "../Modal/ErrorModal";
import FileSelectDropDown from "./FileSelectDropDown";

export interface MenuItem {
  title: string;
  description: string;
}

export interface HeaderProps {
  workToolHandler: (workToolOnoff: boolean) => void;
  workToolOn: boolean;
  tenantId: string;
  setInfering: (infering: boolean) => void;
  infering: boolean;
  modalOn: boolean;
  isLogin: boolean;
}

const DetailHeader = ({
  workToolHandler,
  workToolOn,
  tenantId,
  modalOn,
  isLogin,
}: HeaderProps) => {
  const {
    naController,
    toggleOn,
    setConfidenceThreshold,
    confidenceThreshold,
    confidenceOn,
    setConfidenceOn,
  } = parsingTableStore;
  const { endpointType } = endpointStore;

  const [errorOn, setErronOn] = useState<boolean>(false);
  const { models, selected } = useContext(ValidatorModelsContext);
  const [errorType, setErrorType] = useState<number>(1);
  const {
    files,
    selected: fileSelected,
    setSelected: setFileSelected,
    setFiles,
    setImageFiles,
    setInfering,
  } = useContext(ValidatorFileContext);
  const [isHover, setIsHover] = useState<boolean>(false);
  const [isFileHover, setIsFileHover] = useState<boolean>(false);
  const [naHover, setNaHover] = useState<boolean>(false);
  const [closeFunction, setCloseFunction] = useState<() => void>(() => {});
  const [reloadFunction, setReloadFunction] = useState<() => void>(() => {});
  const [questionfHover, setQuestionHover] = useState<boolean>(false);
  const ref = useRef<HTMLDivElement>(null);

  const [modalOpen, setModalOpen] = useState<boolean>(false);

  const handleDemoModal = () => {
    if (endpointType.toLowerCase() === "ocr") {
      document.getElementById("file-upload")?.click();
    } else if (endpointType.toLowerCase() === "layout-analysis") {
      document.getElementById("file-upload")?.click();
    } else if (endpointType.toLowerCase() === "extractor") {
      if (models.length === 0 && !modalOpen) {
        setErrorType(MODEL_FETCH_FAIL);
        setErronOn(true);
        setCloseFunction(() => () => {
          setErronOn(false);
        });
        setReloadFunction(() => () => {
          setErronOn(false);
          window.location.reload();
        });
      } else {
        setModalOpen(!modalOpen);
      }
    } else {
      console.log("endpointType is not supported");
    }
  };

  return (
    <>
      <ErrorModal
        open={errorOn}
        setOpen={setErronOn}
        errorType={errorType}
        closeFunction={closeFunction}
        reloadFunction={reloadFunction}
      />
      <DemoSelectModal
        isOpen={modalOpen}
        setIsOpen={setModalOpen}
        tenantId={tenantId}
      />
      <div className="h-[4rem] bg-none rounded-t flex flex-row flex-1 justify-start border-b border-gray-100 items-center px-4 align-middle ">
        {/*
        모델 선택 및 파일 이름 표시
      */}

        <div
          className={classnames({
            "flex flex-row flex-1 truncate rounded-lg shadow-sm mr-[1px] h-9 px-2":
              true,
            "cursor-pointer": isLogin && FILE_UPLOAD_ON,
            "bg-[#E5E7EB]": isHover && FILE_UPLOAD_ON,
            "bg-gray-50": !isHover || !FILE_UPLOAD_ON,
          })}
          onClick={() => {
            FILE_UPLOAD_ON && isLogin && handleDemoModal();
          }}
          onMouseEnter={() => isLogin && setIsHover(true)}
          onMouseLeave={() => isLogin && setIsHover(false)}
        >
          {endpointType.toLowerCase() === "extractor" && isLogin ? (
            <div className="flex">
              <FileSelectDropDown modalOn={modalOn} />
              <div className="text-gray-200 my-1">|</div>
            </div>
          ) : (
            <></>
          )}
          <div
            className={classnames({
              "flex-1 m-auto pl-2 truncate basis-0 --body-sm text-gray-700 z-10":
                true,
            })}
            id="fileName"
            onMouseEnter={() => setIsFileHover(true)}
            onMouseLeave={() => setIsFileHover(false)}
          >
            <span>{files[fileSelected]?.title}</span>
          </div>
          {isLogin && FILE_UPLOAD_ON && (
            <div className="flex items-center justify-center px-2 my-2">
              <Icon source={UploadImage} />
            </div>
          )}
        </div>
      </div>

      {files.length > 0 && isLogin && FILE_UPLOAD_ON ? (
        <input
          id="file-upload"
          name="image"
          type="file"
          accept=".jpg,.jpeg,.png,.bmp,.pdf,.tif,.tiff,.heic,.heif"
          onChange={async e => {
            const t = async () =>
              await fileUpload(
                [e.target.files![0]],
                setErrorType,
                () => {},
                tenantId,
                () => {},
                setErronOn,
                setInfering,
                setImageFiles,
                models,
                selected,
                files,
                setFileSelected,
                setFiles,
                setCloseFunction,
                setReloadFunction,
                errorType,
                e,
              );
            t().then(() => {});
          }}
          style={{ display: "none" }}
        />
      ) : (
        <></>
      )}
    </>
  );
};

export default observer(DetailHeader);
